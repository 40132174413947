import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./App.css";

import Login from "./sessions/Login";
import Register from "./sessions/Register";
import Profile from "./sessions/Profile";
import ForgotPassword from "./sessions/ResetPassword";
import UsersProfile from "./Users/UsersProfile";
import ViewLevels from "./Levels/ViewLevels";
import ShowLevel from "./Levels/ShowLevel";
import Home from "./Home";
import ViewPoints from "./Points/ViewPoints";
import ViewUsers from "./Users/ViewUsers";
import EditUser from "./Users/EditUser";
import AssignLevel from "./Users/ProfileComponents/AssignLevel";
import NewLevel from "./Levels/NewLevel";
import CloneLevel from "./Levels/CloneLevels";
import AddPoints2 from "./Points/AddPointsV2";
import EditPoints from "./Points/EditPoints";
import ResponsiveAppBar from "./Navbar";
import Categories from "./Categories/ViewCategories";
import NewCategories from "./Categories/NewCategories";
import ShowCategories from "./Categories/ShowCategories";
import EditCategories from "./Categories/EditCategories";
import EditCategoriesName from "./Categories/EditCategoriesName";
import CloneCategories from "./Categories/CloneCategories";
import ShowCategory from "./Categories/ShowCategory";
import Footer from "./Footer";
import NewUser from "./Users/NewUser";
import Certificates from "./Certificates/ViewCertificates";
import TemplateOptions from "./BuilderPDF/TemplateOptions";
import TemplateOptionOne from "./BuilderPDF/OptionOne";
import TemplateOptionTwo from "./BuilderPDF/OptionTwo";
import TemplateOptionThree from "./BuilderPDF/OptionThree";
import NewTemplate from "./BuilderPDF/NewTemplate";
import ViewTemplates from "./BuilderPDF/ViewTemplates";
import Deepl from "./Deepl/Deepl";
import NotFound from "./NotFound";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { login } from "./store/user";
import BasicDocument from "./Certificates/Taiji A/Taiji_PDF";
import EditTemplate from "./BuilderPDF/EditTemplate";
import CloneTemplate from "./BuilderPDF/CloneTemplate";

function App() {
  // Configuration User State with Redux
  let user;

  const dispatch = useDispatch();
  if (window.localStorage.getItem("token")) {
    user = jwt_decode(window.localStorage.getItem("token"));
    user.avatar = window.localStorage.getItem("avatar");
  }

  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      dispatch(login({ ...user }));
    }
  });

  /* FOR EXPIRED TOKEN...in progress... */
  /*   const get = (keyName) => {
    const data = localStorage.getItem(keyName);
    if (!data) {     // if no value exists associated with the key, return null
        return null;
    }
 
    const item = JSON.parse(data);
 
    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) {
        localStorage.removeItem(key);
        return null;
    }
 
    // return data if not expired
    return item.value;
  }; */
  var validToken = window.localStorage.getItem("token");

  const deleteToken = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  if (!validToken) {
    switch (window.location.pathname) {
      case "/register":
        return <Register />;
      case "/forgot-password":
        return <ForgotPassword />;
      default:
        return <Login />;
    }
  }

  return (
    <div className="app">
      <ResponsiveAppBar deleteToken={deleteToken} />
      <Routes>
        <Route
          path="/"
          exact
          element={!validToken ? <Login /> : <Navigate to="/home" />}
        />
        <Route
          path="/home"
          exact
          element={
            !user?.member ? (
              <Home />
            ) : (
              <Navigate to={`/users/profile/${user?.user_id}`} replace />
            )
          }
        />
        <Route path="/register" exact element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/levels" element={<ViewLevels />} />
        <Route path="/levels/new" element={<NewLevel />} />
        <Route path="/levels/clone/:id" element={<CloneLevel />} />
        <Route path="/levels/show/:id" element={<ShowLevel />} />
        <Route path="/points" element={<ViewPoints />} />
        <Route path="/points/add-points" element={<AddPoints2 />} />
        <Route path="/points/edit/:id" element={<EditPoints />} />
        <Route
          path="/users"
          element={user?.admin ? <ViewUsers /> : <Navigate to="/" replace />}
        />
        <Route
          path="/users/new"
          element={user?.admin ? <NewUser /> : <Navigate to="/" replace />}
        />
        <Route
          path="/users/edit/:id"
          element={user?.admin ? <EditUser /> : <Navigate to="/" replace />}
        />
        <Route path="/users/profile/:id" element={<UsersProfile />} />
        <Route
          path="/users/assign/:id"
          element={user?.admin ? <AssignLevel /> : <Navigate to="/" replace />}
        />
        <Route
          path="/categories"
          element={user?.admin ? <Categories /> : <Navigate to="/" replace />}
        />
        <Route
          path="/categories/new"
          element={
            user?.admin ? <NewCategories /> : <Navigate to="/" replace />
          }
        />
        <Route
          path="/categories/edit/:id"
          element={
            user?.admin ? <EditCategories /> : <Navigate to="/" replace />
          }
        />
        <Route
          path="/categories/clone/:id"
          element={
            user?.admin ? <CloneCategories /> : <Navigate to="/" replace />
          }
        />
        <Route
          path="/categories/edit-name/:id"
          element={
            user?.admin ? <EditCategoriesName /> : <Navigate to="/" replace />
          }
        />
        <Route
          path="/categories/show/:id"
          element={user?.admin ? <ShowCategory /> : <Navigate to="/" replace />}
        />
        <Route path="/certificates" element={<Certificates />} />
        <Route path="/templates" element={<ViewTemplates />} />
        <Route path="/templates/options" element={<TemplateOptions />} />
        <Route path="/templates/options/1" element={<TemplateOptionOne />} />
        <Route path="/templates/options/2" element={<TemplateOptionTwo />} />
        <Route path="/templates/options/3" element={<TemplateOptionThree />} />
        <Route path="/templates/new" element={<NewTemplate />} />
        <Route path="/templates/edit/:id" element={<EditTemplate />} />
        <Route path="/templates/clone/:id" element={<CloneTemplate />} />
        <Route path="/deepl" element={<Deepl />} />
        <Route path="/createpdftest" element={<BasicDocument />} />
        <Route path="*" exact={true} element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

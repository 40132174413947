import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CalendarIcon from "../../assets/icons/zertiassist_iconos_calendar_24px.svg";
import Sidebar from "../../Sidebar";
import MainBtn from "../../UI-kit/Buttons/MainBtn";
import MainBtnCancel from "../../UI-kit/Buttons/MainBtnCancel";
import Avatar from "../../assets/images/users_image.jpg";
import fetchApi from "../../hooks/fetchApi";
import AlertV2 from "../../UI-kit/Alert/AlertV2";

const AssignLevel = () => {
  const { t } = useTranslation();

  const params = useParams();
  let navigate = useNavigate();

  const location = useLocation();
  const userID = location.state !== null && location.state.userID;

  const [user, setUser] = useState([]);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userLevel, setUserLevel] = useState([]);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    fetchUserInfo();
    fetchLevels();
  }, []);

  const fetchUserInfo = async () => {
    const { ok, data } = await fetchApi("/users/show/" + params.id);

    if (ok) {
      setUser(data);
      let levels = JSON.parse(data.current);
      if (levels !== null) {
        setUserLevel(levels);
      }
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const fetchLevels = async () => {
    const { ok, data } = await fetchApi("/levels/index");

    if (ok) {
      setLevels(data);
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const body = {
        current: JSON.stringify(userLevel),
      };

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(body),
      };

      let response = await fetch(
        process.env.REACT_APP_API_URL + "/users/update/" + params.id,
        requestOptions
      );
      if (response.ok) {
        let details = await response.json();
        console.log(details);
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.Level assigned"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      }
    } catch (error) {
      console.log(error);
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        if (userID) {
          navigate("/users/profile/" + userID);
        } else {
          navigate("/users");
        }
      }, 1000);
    }
  };

  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className="frame">
        <br />

        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title">Level for user</h2>
          <div className="text-end">
            <Link
              style={{ textDecoration: "none" }}
              to={userID ? `/users/profile/${userID}` : "/users"}
            >
              <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
            </Link>
            <MainBtn disabled={userLevel.length === 0} type="submit">
              {t("Buttons.2")}
            </MainBtn>
          </div>
        </div>

        <hr />

        <div className="row h-100 px-2">
          <div
            className="col-lg-4 white-box"
            style={{
              backgroundImage: "url(" + Avatar + ")",
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              height: "fit-content",
              backgroundSize: "contain",
            }}
          >
            <div className="px-3" style={{ marginTop: "60%" }}>
              <p className="subtitle">
                <img className="sidebar-icons" src={CalendarIcon} />{" "}
                {t("UserData.1")}:
              </p>

              <h5 className="info-text-small" style={{ marginLeft: "15px" }}>
                {user.length != 0 ? (
                  <>
                    {user.updated_at.split("T")[0]} at{" "}
                    {user.updated_at.split("T")[1]}
                  </>
                ) : (
                  <>Getting data...</>
                )}
              </h5>

              {user.ev_id == 0 ? (
                <></>
              ) : (
                <h5
                  className="info-text-small"
                  style={{ marginBottom: "15px" }}
                >
                  easyVerein ID: {user.ev_id}
                </h5>
              )}

              {user.url_profile_easyverein == "" ? (
                <></>
              ) : (
                <a
                  className="simple-link"
                  href={`${user.url_profile_easyverein}`.replace(
                    "https//",
                    "wwww"
                  )}
                  target="_blank"
                >
                  Link to easyVerein profile
                </a>
              )}
              <br />
              {user.url_profile_wordpress == "" ? (
                <></>
              ) : (
                <a
                  className="simple-link"
                  href={`${user.url_profile_wordpress}`.replace(
                    "https//",
                    "wwww"
                  )}
                  target="_blank"
                >
                  Link to WordPress profile
                </a>
              )}
            </div>
          </div>

          <div className="col-lg-8 ps-lg-4 my-2 my-lg-0">
            <div className="row white-box">
              <div className="col px-2">
                <h2 className="subtitle">
                  {" "}
                  <StarBorderIcon
                    className="table-icons"
                    style={{ verticalAlign: "bottom" }}
                  />{" "}
                  {t("UserData.2")}:
                </h2>

                <br />

                <Autocomplete
                  style={{ width: "38%" }}
                  isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                  }
                  required
                  multiple
                  disablePortal
                  id="multiple-limit-tags"
                  value={userLevel}
                  options={levels}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, value) => setUserLevel(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose level" />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      {isAlert.open && (
        <AlertV2
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AssignLevel;

import logo from '../../assets/Logo_BVTQ_1200.png';

const LoginHeader = ({}) => {



  return (
    <> 
      <h3 className="text-center fw-bold mb-3 mt-5" style={{cursor: 'pointer'}} onClick={() => window.location.href = '/'}>
        <img src={logo} style={{width: '30px', height: '35px'}}/> 
        &nbsp;BVTQ
      </h3>
    </>
    );
};


export default LoginHeader;
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";

const LoadUserData = ({
  userID,
  user,
  setUser,
  users,
  setLevel,
  setLevels,
  setData,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-2">
        <p className="subtitle fw-bold">{t("LoadUser.0")}*</p>
        <Autocomplete
          disabled={userID}
          id="combo-box-demo"
          value={user}
          options={users}
          getOptionLabel={(option) =>
            option.id + " - " + option.last_name + ", " + option.first_name
          }
          onChange={(e, value) => {
            let fieldValue = value;
            if (!fieldValue) {
              fieldValue = null;
              setUser(fieldValue);
              setLevel(fieldValue);
              setData([]);
              setLevels([]);
            } else {
              setUser(value);
              setLevel(null);
              setData([]);
              if (value.current != null) {
                setLevels(JSON.parse(value.current));
              } else {
                setLevels([]);
              }
            }
          }}
          renderInput={(params) => (
            <TextField required {...params} label="User" />
          )}
        />
      </div>
    </>
  );
};

export default LoadUserData;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MapperLevel from "./MapperLevel";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Sidebar from "../Sidebar";
import Avatar from "../assets/images/users_image.jpg";
import fetchApi from "../hooks/fetchApi";
import ZertiHelpComponent from "../UI-kit/ZertiHelpComponent/ZertiHelpComponent";
import MainBtn from "../UI-kit/Buttons/MainBtn";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import DropFileZone from "../Points/components/DropFileZone";
import UploadIcon from "../assets/icons/zertiassist_iconos_upload_24px.svg";
import Alert from "../../src/UI-kit/Alert/Alert";

const NewLevel = () => {
  const { t } = useTranslation();

  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const [category, setCategory] = useState([]);
  const [category_id, setCategoryId] = useState("");
  const [levelName, setLevelName] = useState("");
  const [expiry_day, setDay] = useState();
  const [expiry_month, setMonth] = useState();
  const [expiry_year, setYear] = useState();
  const [documents, setDocuments] = useState("");

  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { ok, data } = await fetchApi("/categories/index");
    if (ok) {
      // let categoriesWithoutLevel = data.filter(
      //   (category) => !category.has_level
      // );
      setCategoriesData(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handlePointsChange = (e, i) => {
    let currentData = [...category];
    currentData[i].points = parseInt(e.target.value);
    setData(currentData);
  };

  const handleFileChange = (e) => {
    setDocuments(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      archive: false,
      category_id: category_id,
      title: levelName,
      expiry_day,
      expiry_month,
      expiry_year,
      subcategories: JSON.stringify(category),
      documents,
    };

    const formData = new FormData();

    for (const property in body) {
      formData.append(property, body[property]);
    }

    try {
      const resp = await axios.post(
        process.env.REACT_APP_API_URL + "/levels/create",
        formData,
        {
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      //console.log(resp.data);
    } catch (err) {
      // Handle Error Here
      console.log(err);
    }

    const body2 = {
      has_level: true,
    };

    try {
      const resp2 = await axios.post(
        process.env.REACT_APP_API_URL + "/categories/update/" + category_id,
        body2,
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      console.log(resp2.status);
      if (resp2.status === 200) {
        setSuccessful(true);
        setTimeout(() => {
          navigate("/levels");
        }, 1000);
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className="frame">
        <br />
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title" style={{ marginBottom: "0" }}>
            {t("NewLevel.0")}
          </h2>
          <div className="text-end">
            <Link style={{ textDecoration: "none" }} to="/levels">
              <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
            </Link>
            <MainBtn
              disabled={category.length === 0 || documents === ""}
              type="submit"
            >
              {t("Buttons.2")}
            </MainBtn>
          </div>
        </div>

        <hr />

        <div className="row px-2">
          <div
            className="col-6 col-lg-4 white-box"
            style={{
              backgroundImage: "url(" + Avatar + ")",
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            <div className="px-3 py-2" style={{ marginTop: "60%" }}>
              <p className="subtitle fw-bold" style={{ marginBottom: "5px" }}>
                Categories *
              </p>
              <Autocomplete
                required
                disablePortal
                id="combo-box-demo"
                options={categoriesData}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                  let fieldValue = value;
                  if (!fieldValue) {
                    fieldValue = null;
                    setCategory([]);
                  } else {
                    setCategory(JSON.parse(value.subcategories));
                    setCategoryId(value.id);
                  }
                }}
                renderInput={(params) => (
                  <TextField required {...params} label="Categories" />
                )}
              />

              {category.length ? (
                <>
                  <p
                    className="subtitle fw-bold pt-2"
                    style={{ marginTop: "10px", marginBottom: "5px" }}
                  >
                    {t("NewLevel.2")} *
                  </p>
                  <TextField
                    required
                    value={levelName}
                    onChange={(e) => setLevelName(e.target.value)}
                    label="Level Name"
                    style={{ width: "100%" }}
                  />
                  <p
                    className="subtitle fw-bold pt-2"
                    style={{ marginTop: "10px", marginBottom: "5px" }}
                  >
                    {t("NewLevel.3")} *
                  </p>
                  <TextField
                    type="number"
                    value={expiry_day}
                    onChange={(e) => setDay(e.target.value)}
                    label="Day"
                    InputProps={{ inputProps: { min: 0, max: 31 } }}
                  />
                  &nbsp;
                  <TextField
                    type="number"
                    value={expiry_month}
                    onChange={(e) => setMonth(e.target.value)}
                    label="Month"
                    InputProps={{ inputProps: { min: 0, max: 12 } }}
                  />
                  &nbsp;
                  <TextField
                    type="number"
                    value={expiry_year}
                    onChange={(e) => setYear(e.target.value)}
                    label="Year"
                    InputProps={{ inputProps: { min: 0, max: 10 } }}
                  />
                  <div
                    className="pt-2"
                    style={{ marginTop: "10px", marginBottom: "5px" }}
                  >
                    <p className="subtitle">
                      {" "}
                      <img src={UploadIcon} className="sidebar-icons" />{" "}
                      {t("NewLevel.1")}
                    </p>
                    <div className="wrapper" style={{ height: "auto" }}>
                      <DropFileZone setDocuments={setDocuments} />
                      <p className="standard-text mt-3">{t("AddPoints.2")} </p>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {category.length > 0 ? (
            <div className="col-6 col-lg-8">
              <MapperLevel
                data={category}
                handlePointsChange={handlePointsChange}
              />
            </div>
          ) : (
            <ZertiHelpComponent
              infoMessage="ZERTI HELP"
              title={t(
                "ZertiHelp.Select an existent category to create a new level."
              )}
              description={
                <>
                  {t("ZertiHelp.If you don't have any category")}{" "}
                  <a
                    href="/categories/new"
                    className="simple-link"
                    style={{ textDecoration: "none" }}
                  >
                    {t("ZertiHelp.click here")}
                  </a>{" "}
                  {t("ZertiHelp.to create a new one.")}
                </>
              }
              arrowWidth="250"
              arrowHeight="175"
            />
          )}
        </div>

        {successful && (
          <Alert
            message={t("Messages.The operation was successful")}
            severity={"success"}
          />
        )}
        {error && (
          <Alert
            message={"Messages.Something went wrong, please try again"}
            severity={"error"}
          />
        )}
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default NewLevel;

import React, { useState, useEffect } from 'react'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import UserFiles from './ShowUserFiles';
import { useTranslation } from 'react-i18next';
import fetchApi from '../../hooks/fetchApi';

const ShowUserPoints = ({ current }) => {

    const { t, i18n } = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');


    useEffect(() => {
        fetchData();
    }, [current])

    const fetchData = async () => {

        const { ok, data } = await fetchApi('/points/show/' + current);

        if(ok){
            //console.log(json);
            let subCategoriesArray = JSON.parse(data.subcategories)
            setData(subCategoriesArray);
            setTitle(data.title)
            setLoading(false);
        }else{
            console.log('response was not ok');

        }    

     
    };

    return (
        <div>
            {loading ? <>Loading...</> : <>
                <span className='subtitle'>
                    {t('ShowUserPoints.0')}&nbsp;&gt;&nbsp;
                </span>
                <span className='subtitle-light'>
                    {title} - {current} 
                </span>

                <hr />


                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Points">

                        <div className='row'>
                            <div className='col'>
                                <h2 className='subtitle text-left'>{t('ShowUserPoints.1')}:</h2>
                                <br />   
                                {data.map((item, i) => 
                                    
                                    <>
                                        {item.belongs != 0 ? 
                                        <div>
                                            <p style={{ marginLeft: (String(item.nesting * 25) + 'px') }}>
                                                    
                                                <b>  {item.name}  </b>
                                                &nbsp;-&gt;  {t('ShowUserPoints.2')}: {item.user_points}&nbsp;

                            
                                            </p>
                                            </div> : <div >
                                            <p>
                                                
                                                <b> {item.name}</b>
                                                &nbsp;-&gt;  {t('ShowUserPoints.2')}: {item.user_points} &nbsp; 

                            
                                            </p>
                                        </div>}
                                    </>
                                    
                                )} 

                            </div>

                        </div>

                    </Tab>

                    <Tab eventKey="other" title="Files">

                        <UserFiles current={current} />
                 
                    </Tab>
                </Tabs>    


  

            </>}
        </div>
    )
}

export default ShowUserPoints

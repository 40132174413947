import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import fetchApi from "../hooks/fetchApi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ProfileData from "./ProfileComponents/ProfileData";
import PointsData from "./ProfileComponents/PointsData";
import CertificateData from "./ProfileComponents/CertificateData";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import FloatingActionButtons from "../UI-kit/Buttons/FloatingActionButtons";

const MainProfile = () => {
  const { user: userRedux } = useSelector((state) => state.user);

  const { t } = useTranslation();

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);

  useEffect(() => {
    fetchData();
  }, [params.id]);

  const fetchData = async () => {
    setLoading(true);
    const { ok, data } = await fetchApi("/users/show/" + params.id);

    if (ok) {
      // console.log(data);
      setUser(data);
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />

      <br />

      <div className="frame">
        {!loading && (
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col md={12} lg={4}>
                <h2 className="title">
                  {user.first_name} {user.last_name}
                </h2>
              </Col>

              <Col className="d-flex">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first" className="button-gray">
                      {t("UserProfile.Profile")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="button-gray">
                      {t("UserProfile.Points")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third" className="button-gray">
                      {t("UserProfile.Certificates")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {userRedux?.admin && (
                  <FloatingActionButtons
                    route="/points/add-points"
                    state={{ userID: params.id }}
                  />
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <ProfileData id={params.id} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <PointsData
                      id={params.id}
                      firstname={user.first_name}
                      lastname={user.last_name}
                      mode={user.mode}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <CertificateData
                      id={params.id}
                      firstname={user.first_name}
                      lastname={user.last_name}
                      mode={user.mode}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        )}
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default MainProfile;

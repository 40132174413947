import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Table from "react-bootstrap/Table";
import NewCertificate from "../../Certificates/NewCertificate";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MainBtn from "../../UI-kit/Buttons/MainBtn";
import fetchApi from "../../hooks/fetchApi";
import Avatar from "../../assets/images/users_image.jpg";
import PeopleAltIcon from "../../assets/icons/zertiassist_iconos_user_24px.svg";
import PointsTable from "../../Points/PointsTable";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PointsData = ({ id, firstname, lastname, mode }) => {
  const { user } = useSelector((state) => state.user);

  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("");
  const [lock, setLock] = useState(true);
  const [show, setShow] = useState(false);
  const [levels, setLevels] = useState([]);
  const [level, setLevel] = useState([]);
  const [awards, setAwards] = useState([]);
  const [archivedAwards, setArchivedAwards] = useState([]);
  const [archive, setArchive] = useState(true);

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
            Cell: ({ row }) => <>{row.original.id}</>,
          },
          {
            Header: <>{t("PointsTable.1")}</>,
            accessor: "title",
          },
          {
            Header: <>{t("PointsTable.4")}</>,
            accessor: "created_at",
            Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>,
          },
          {
            Header: <>{t("PointsTable.5")}</>,
            accessor: "archived",
            Cell: (e) =>
              e.value == true ? <>{t("Tables.No")}</> : <>{t("Tables.Yes")}</>,
          },
          {
            Header: <> </>,
            accessor: "attr_2",
            Cell: ({ row }) => (
              <Link
                title="edit"
                className="simple-link"
                to={`/points/edit/${row.original.id}`}
                state={{ user: id }}
                style={{ cursor: "pointer" }}
              >
                <BorderColorOutlinedIcon className="table-icons" />
              </Link>
            ),
          },
          {
            Header: <> </>,
            accessor: "attr_1",
            Cell: ({ row }) => (
              <span
                className="simple-link"
                style={{ cursor: "pointer" }}
                onClick={() => handleArchive(row.original)}
                title={archive ? "archive" : "unarchive"}
              >
                {archive ? (
                  <FolderOpenIcon className="table-icons" />
                ) : (
                  <DriveFolderUploadIcon className="table-icons" />
                )}
              </span>
            ),
          },
        ],
      },
    ],
    [archive, currentLanguage]
  );

  useEffect(() => {
    fetchData();
    fetchDataUser();
  }, [category]);

  useEffect(() => {
    getAwards();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const { ok, data } = await fetchApi(
      "/points/get_user_points_v2?user=" + id + "&" + "category=" + category
    );

    if (ok) {
      setData(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchDataUser = async () => {
    const { ok, data } = await fetchApi("/users/show/" + id);
    if (ok) {
      if (data.current !== null) {
        const levels = JSON.parse(data.current);
        setLevels(levels);
      } else {
        setLevels([]);
      }
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const res = Array.from(
    data
      .reduce((acc, { user_points, ...r }) => {
        const key = JSON.stringify(r);
        const current = acc.get(key) || { ...r, user_points: 0 };
        return acc.set(key, {
          ...current,
          user_points: current.user_points + user_points,
        });
      }, new Map())
      .values()
  );

  const userPoints = res.reduce((total, obj) => obj.user_points + total, 0);

  const levelPoints = res.reduce((total, obj) => obj.points + total, 0);

  const getAwards = async () => {
    const { ok, data } = await fetchApi("/points/index");
    if (ok) {
      let activeAwards = data.filter(
        (element) => element.archived === 0 && element.user_id === Number(id)
      );
      let archivedAwards = data.filter(
        (element) => element.archived === 1 && element.user_id === Number(id)
      );
      setAwards(activeAwards);
      setArchivedAwards(archivedAwards);
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const handleArchive = (item) => {
    setLoading(true);
    const body = { archived: !item.archived };

    fetch(process.env.REACT_APP_API_URL + "/points/update/" + item.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.ok) {
        getAwards();
      } else {
        console.log("response was not ok");
      }
    });
  };

  const setUnlock = () => {
    setLock(false);
    setShow(true);
  };

  const setLocking = () => {
    setLock(true);
    setShow(false);
  };

  return (
    <>
      <div className="row h-100 px-2">
        <div
          className="col-4 white-box"
          style={{
            backgroundImage: "url(" + Avatar + ")",
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div className="px-3" style={{ marginTop: "230px" }}>
            <div className="col">
              <p className="subtitle fw-bold">
                <img
                  className="sidebar-icons"
                  src={PeopleAltIcon}
                  alt="PeopleAltIcon"
                />{" "}
                {firstname} {lastname}
              </p>
              <p className="subtitle fw-bold">
                <img
                  className="sidebar-icons"
                  src={PeopleAltIcon}
                  alt="PeopleAltIcon"
                />{" "}
                {mode}
              </p>
            </div>
          </div>
        </div>

        <div className="col-8">
          <div className="mb-2">
            <Autocomplete
              style={{ width: "38%" }}
              disablePortal
              id="combo-box-demo"
              options={levels}
              getOptionLabel={(option) => option.title}
              onChange={(e, value) => {
                var fieldValue = value;
                if (!fieldValue) {
                  fieldValue = null;
                } else setCategory(value.category_id);
                setLevel(value);
                setLock(true);
              }}
              renderInput={(params) => (
                <TextField required {...params} label="Pick a level" />
              )}
            />
          </div>

          {loading ? (
            <></>
          ) : (
            <>
              <Table responsive borderless className="main-table">
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>{t("UserPoints.1")}</td>
                    <td>{t("UserPoints.2")}</td>
                    <td>{t("UserPoints.3")}</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {res.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {item.belongs != 0 ? (
                          <span
                            style={{
                              paddingLeft: String(item.nesting * 10) + "px",
                            }}
                          >
                            {item.name}
                          </span>
                        ) : (
                          <span>{item.name}</span>
                        )}
                      </td>
                      <td>{item.points}</td>
                      <td>{item.user_points}</td>
                      <td>
                        {item.points - item.user_points > 0 ? (
                          <span style={{ color: "red" }}>
                            {item.points - item.user_points}
                          </span>
                        ) : (
                          <span style={{ color: "green" }}>
                            {item.points - item.user_points}
                          </span>
                        )}
                      </td>
                      <td>
                        {item.points - item.user_points > 0 ? (
                          <span style={{ color: "red" }}>
                            {t("UserPoints.4")}
                          </span>
                        ) : (
                          <span style={{ color: "green" }}>
                            {t("UserPoints.5")}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {res.length > 1 ? (
                <div className="text-end">
                  {user.admin == true &&
                    (levelPoints - userPoints > 0 ? (
                      <span className="fw-bold">{t("UserPoints.6")}</span>
                    ) : (
                      <MainBtn
                        onClick={setUnlock}
                        style={{ cursor: "pointer" }}
                      >
                        {t("UserPoints.7")}
                      </MainBtn>
                    ))}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>

      {!user.member && (
        <div className="mt-5">
          <div className="d-flex align-items-center">
            <h4>{t("UserPoints.8")}</h4>
            <div style={{ marginLeft: 28 }}>
              <button
                className={
                  !archive
                    ? "button-gray mx-1 px-2 py-1"
                    : "button-gray-active mx-1 px-2 py-1"
                }
                onClick={() => setArchive(true)}
              >
                {t("Buttons.3")}
              </button>
              <button
                className={
                  !archive
                    ? "button-gray-active mx-1 px-2 py-1"
                    : "button-gray mx-1 px-2 py-1"
                }
                onClick={() => setArchive(false)}
              >
                {t("Buttons.4")}
              </button>
            </div>
          </div>
          {!loading && (
            <PointsTable
              columns={columns}
              data={archive ? awards : archivedAwards}
              route="/points/add-points"
              state={{ userID: id }}
            />
          )}
        </div>
      )}

      <Modal
        open={show}
        size="lg"
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <NewCertificate current={id} level={level} res={res} />
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default PointsData;

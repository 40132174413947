import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#757373",
  border: "2px solid #000",
  boxShadow: 24,
  height: "90%",
  width: "750px",
  overflow: "auto",
};

const ShowCertificates = ({ current }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [typeTemplate, setTypeTemplate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [userName, setUserName] = useState("");
  const [alternativeText, setAlternativeText] = useState("");
  const [levelName, setLevelName] = useState("");
  const [levelSubtitle, setLevelSubtitle] = useState("");
  const [stampLevel, setStampLevel] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [management, setManagement] = useState("");
  const [levelDescription, setLevelDescription] = useState("");
  const [data, setData] = useState([]);
  const [textColor, setTextColor] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [headerImage, setHeaderImage] = useState("");

  const transformDate = (date) => {
    const formatDate = new Date(date).toLocaleString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: "UTC",
    });
    const convert = formatDate.split("/").reverse();
    return convert.join("-");
  };

  const getCertificateData = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        headers: { "api-key": process.env.REACT_APP_API_KEY },
      };
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/certificates/show/" + current,
        requestOptions
      );
      if (res.ok) {
        const json = await res.json();
        let subCategoriesArray = JSON.parse(json.content);
        setUserName(json.name);
        setLevelName(json.level);
        setCreatedDate(transformDate(json.created_at));
        setManagement(json.signed_by);
        setData(subCategoriesArray);
        getTemplateData(json.template_id);
        getHeaderImage(json.template_id);
        getAttachments(json.template_id);
        getLevelStamp(json.level_id);
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getTemplateData = async (id) => {
    try {
      setLoading(true);
      var requestOptions = {
        headers: { "api-key": process.env.REACT_APP_API_KEY },
      };
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/builders/show/" + id,
        requestOptions
      );
      if (res.ok) {
        const json = await res.json();
        setTypeTemplate(json.content);
        setTitle(json.subtitle);
        setDescription(json.image);
        setAlternativeText(json.content_two);
        setLevelSubtitle(json.content_four);
        setLevelDescription(json.content_three);
        setTextColor(json.text_color);
        setBackgroundColor(json.color.toString());
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getHeaderImage = async (id) => {
    try {
      setLoading(true);
      var requestOptions = {
        headers: { "api-key": process.env.REACT_APP_API_KEY },
      };
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/builders/get_header_image/" + id,
        requestOptions
      );
      if (res.ok) {
        const json = await res.json();
        let url = json.url.replace(
          json.url.split("rails")[0],
          process.env.REACT_APP_DOWNLOAD_URL
        );
        setHeaderImage(url);
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAttachments = async (id) => {
    try {
      setLoading(true);
      var requestOptions = {
        headers: { "api-key": process.env.REACT_APP_API_KEY },
      };
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/builders/get_attachments/" + id,
        requestOptions
      );
      if (res.ok) {
        const json = await res.json();
        let url = json.url.replace(
          json.url.split("rails")[0],
          process.env.REACT_APP_DOWNLOAD_URL
        );
        setBackgroundImage(url);
        console.log("url", url);
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getLevelStamp = async (level_id) => {
    try {
      setLoading(true);
      var requestOptions = {
        headers: { "api-key": process.env.REACT_APP_API_KEY },
      };
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/levels/get_attachments/" + level_id,
        requestOptions
      );
      if (res.ok) {
        const json = await res.json();
        let url = json.url.replace(
          json.url.split("rails")[0],
          process.env.REACT_APP_DOWNLOAD_URL
        );
        setStampLevel(url);
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCertificateData();
  }, []);

  return (
    <Box sx={style}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
            minWidth: "700px",
            backgroundColor: "background.paper",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "1200px",
              p: "16px 58px 96px 58px",
              backgroundImage: backgroundImage
                ? `url("${backgroundImage}")`
                : `-webkit-linear-gradient(top, ${backgroundColor} 0%, rgb(255, 255, 255) 100%)`,
              backgroundPosition: backgroundImage && "center",
              backgroundSize: backgroundImage && "cover",
              backgroundRepeat: backgroundImage && "no-repeat",
              backgroundColor: backgroundColor || "background.paper",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "118px",
                  fontFamily: "var(--main-font-family)",
                  color: textColor && textColor,
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="h6"
                align="center"
                sx={{ fontWeight: "600" }}
              >
                {description}
              </Typography>
              {typeTemplate !== "option 1" && (
                <img src={headerImage} alt="img" style={{ width: "650px" }} />
              )}
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "600",
                  mt: "36px",
                  mb: "16px",
                  fontFamily: "var(--main-font-family)",
                }}
              >
                {userName ? userName : "User Name"}
              </Typography>
              <Typography variant="h6" align="center">
                {alternativeText}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  mt: "16px",
                  color: textColor && textColor,
                }}
              >
                {levelName}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  mt: "16px",
                  mb: "64px",
                  color: textColor && textColor,
                }}
              >
                {levelSubtitle}
              </Typography>
              <img src={stampLevel} alt="stamp" style={{ width: "78px" }} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ fontWeight: "600", mr: "6px" }}>
                  {t("ShowTemplate.0")}:
                </Typography>
                <Typography>{createdDate}</Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ fontWeight: "600", mr: "6px" }}>
                  {t("ShowTemplate.1")}:
                </Typography>
                <Typography>{management}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "1200px",
              marginTop: "20px",
              p: "96px 58px 96px 58px",
              backgroundImage: backgroundImage
                ? `url("${backgroundImage}")`
                : `-webkit-linear-gradient(top, ${backgroundColor} 0%, rgb(255, 255, 255) 100%)`,
              backgroundPosition: backgroundImage && "center",
              backgroundSize: backgroundImage && "cover",
              backgroundRepeat: backgroundImage && "no-repeat",
              backgroundColor: backgroundColor || "background.paper",
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ mb: "28px" }}>
                {levelDescription}
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: "600", mb: "28px" }}>
                {t("ShowTemplate.2")}:
              </Typography>
              <Box sx={{ ml: "28px" }}>
                <ul>
                  {data
                    .filter(({ points }) => points > 0)
                    .map(({ name, points }, i) => (
                      <li key={i}>
                        <Typography>
                          {`${name}: ${points} creditpoints`}
                        </Typography>
                      </li>
                    ))}
                </ul>
              </Box>
              <Typography className="mt-2">
                Die Ausbildung wurde am <strong>{createdDate}</strong>{" "}
                abgeschlossen.
              </Typography>
              <Typography className="mt-1">
                Es besteht Fortbildungspflicht
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                BVTQ e.V., Winkeler Straße 52 a · 65366 Geisenheim · 06722
                4025717
              </Typography>
              <Typography>
                info@taijiquan-qigong.de www.taijiquan-qigong.de
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ShowCertificates;

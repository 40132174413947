import React from 'react'
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { ArrowForwardIos, DeleteOutline } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

const Mapper = ({ data, editNameOnly, handleRename,handleAdd,handleShow }) => {

    const { t, i18n } = useTranslation();

    const getOriginalBelong = (belongs) => {
        const arrOnlySubcategories = data.filter((item) => item.belongs === 0 && item.visible);

        if (arrOnlySubcategories.findIndex((item) => item.key === belongs) === -1) {
            const belongsCategoriaAnterior = data.filter((item) => item.key === belongs)[0].belongs;
            return arrOnlySubcategories.findIndex((item) => item.key === belongsCategoriaAnterior) + 1;
        }

        return arrOnlySubcategories.findIndex((item) => item.key === belongs) + 1;
    }

    const calculateNesting = (subcategory) => {
        const nesting = data.filter((item) => item.key === subcategory.belongs)[0].sub;
        if (nesting > 0) {
            return `${nesting}.`;
        }
        return '';
    }

    const calculateOrderSubcategories = (key) => {
        const arrOnlySubcategories = data.filter((item) => item.belongs === 0 && item.visible);
        if (arrOnlySubcategories.findIndex((item) => item.key === key) === -1) return key;
        return arrOnlySubcategories.findIndex((item) => item.key === key) + 1;
    }
    
    return (
        <div className='ps-lg-4 my-2 my-lg-0 white-box'  style={{ width: '100%', paddingBottom: '30px' }}>
            {data.map((item, i) => 
                item.visible ?
                <>
                    {item.key != 0  ? 
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: (item.nesting === 2) ? '' : 'space-between', alignItems: 'center' }}>
                                <p className={item.nesting ? 'item-nested' : ''} style={{ marginLeft: (String((item.nesting === 0) ? 1 * 26 : (item.nesting === 2 ? 52 : (item.nesting + 1) * 26)) + 'px'), marginBottom: '0px' }}>
                                    {item.nesting === 2 
                                    ? (<div className={item.nesting ? 'item-nested' : ''} style={{ marginLeft: (String((item.nesting === 0) ? 1 * 26 : (item.nesting === 2 ? 52 : (item.nesting + 1) * 26)) + 'px'), marginBottom: '0px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: (String((item.nesting === 0) ? 4 : (item.nesting === 2 ? 30 : (item.nesting + 1) * 26)) + 'px') }}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            color="inherit"
                                            style={{ backgroundColor: 'transparent' }}
                                        >
                                            <ArrowForwardIos style={{ transform: 'rotate(90deg)' }} />
                                        </IconButton>

                                        <span className='fw-bold'>
                                            {item.belongs != 0 ? <span>{getOriginalBelong(item.belongs)}.</span> : <></>}
                                            {item.nesting != 0 ? <span>{calculateNesting(item)}{item.sub}.</span> : <span>{calculateOrderSubcategories(item.key)}.</span>}
                                            {item.name} 
                                        </span>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: (String((item.nesting === 0) ? 1 * 26 : (item.nesting === 2 ? 52 : (item.nesting + 1) * 26)) + 'px'), paddingLeft: '26px', borderStyle: 'dashed', borderBottom: 0, borderRight: 0, borderTop: 0, borderColor: '#D5D7E2' }}>
                                        <TextField
                                            required 
                                            value={item.name}
                                            onChange={(e) => handleRename(e, i)}
                                        />
                                    </div>
                                </div></div>)
                                    : (<div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: (String((item.nesting === 0) ? 4 : (item.nesting === 2 ? 30 : 30)) + 'px') }}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            color="inherit"
                                            style={{ backgroundColor: 'transparent' }}
                                        >
                                            <ArrowForwardIos style={{ transform: 'rotate(90deg)' }} />
                                        </IconButton>

                                        <span className='fw-bold'>
                                            {item.belongs != 0 ? <span>{getOriginalBelong(item.belongs)}.</span> : <></>}
                                            {item.nesting != 0 ? <span>{calculateNesting(item)}{item.sub}.</span> : <span>{calculateOrderSubcategories(item.key)}.</span>}
                                            {item.name} 
                                        </span>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: (String((item.nesting === 0) ? 1 * 26 : (item.nesting + 1) * 26) + 'px'), paddingLeft: '26px', borderStyle: 'dashed', borderBottom: 0, borderRight: 0, borderTop: 0, borderColor: '#D5D7E2' }}>
                                        <TextField
                                            required 
                                            value={item.name}
                                            onChange={(e) => handleRename(e, i)}
                                        />
                                    </div>
                                </div>)}
                                    
                                </p>

                                {(item.key !== 0 && item.nesting < 2) ? (<><div style={{ marginLeft: (String(item.nesting * 10) + 'px'), display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '55px' }}>
                                    <Button 
                                        className='btn-sm'
                                        style={{ borderRadius: '20px' }}
                                        disabled={editNameOnly}
                                        onClick={() => handleAdd(item.key, item.nesting)}>
                                        + {t('Buttons.0')}
                                    </Button>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={() => handleShow(i)}
                                        color="inherit"
                                        disabled={editNameOnly}
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                    {/* <Button 
                                        className='btn-sm'
                                        variant="danger"
                                        onClick={() => handleShow(i)}>
                                            HIDE
                                    </Button> */}
                                </div>

                                <br /></>) : <div style={{ marginLeft: '50px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '55px' }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={() => handleShow(i)}
                                        color="inherit"
                                        disabled={editNameOnly}
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                </div>}
                            </div> : <div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: (item.nesting === 2) ? '' : 'space-between' }}>
                            <p>
                                {/*<span>{item.key})  {item.name} {item.belongs != 0? 
                                    <span className='fw-bold'> - {item.sub}</span>:<></>}
                                </span>*/}

                                <TextField 
                                required
                                value={item.name}
                                onChange={(e) => handleRename(e, i)} 
                                label={<>{item.key})  {item.name} {item.belongs != 0? 
                                    <span className='fw-bold'> - {item.sub}</span>:<></>}</>}
                                /> 
                            </p>

                                                
                            <Button 
                                className='btn-sm'
                                onClick={() => handleAdd(item.key, item.nesting)}>
                                + ADD BRANCH
                            </Button> 
                            &nbsp;-&nbsp;
                            <Button 
                                className='btn-sm'
                                variant="warning"
                                onClick={() => handleShow(i)}>
                                    HIDE / SHOW
                            </Button>

                            <br />
                        </div>
                        

                    </div>}
                </> : <></>
            
            )}
        </div>
    )
}

export default Mapper
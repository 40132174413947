import React from 'react'
import { useTranslation } from 'react-i18next';

const AttachFiles = ({handleFileChange}) => {

    const { t, i18n } = useTranslation();

    return (
        <div className='mb-2'>
            <h5>{t('AttachFiles.0')}:</h5>
            <input
                required
                type="file"
                accept=".doc,.docx,.pdf"
                onChange={handleFileChange}
            />
        </div>
    )
}

export default AttachFiles

import React, { useMemo, useState, useEffect } from "react";
import Table from 'react-bootstrap/Table'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PreviewCategory from './PreviewCategory';
import CategoriesTable from '../Points/PointsTable'
import { useTranslation } from 'react-i18next';
import Sidebar from '../Sidebar';
import { Visibility, FolderOpen, BorderColorOutlined, ContentCopyOutlined, DriveFolderUpload } from "@mui/icons-material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Categories = () => {

    const { t, i18n } = useTranslation();
    let currentLanguage = i18n.language;
    
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [points, setPoints] = useState([]);
    const [pointsFiltered, setPointsFiltered] = useState([]);
    const [show, setShow] = useState(false);
    const [current, setCurrent] = useState(null);
    const [archive, setArchive] = useState(true);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {

        var requestOptions = { headers: { "api-key": process.env.REACT_APP_API_KEY } }

        const res = await fetch(
            process.env.REACT_APP_API_URL + '/categories/index', requestOptions
        );
        const json = await res.json();

        if(res.ok){
            const normalData = json.filter(el => el.archive === false);
            setPoints(normalData);
            const filteredData = json.filter(el => el.archive === true);
            setPointsFiltered(filteredData);
            setLoading(false);
        }else{
            console.log('response was not ok')
            //throw new Error("response was not ok.");
            setLoading(false);
        }
      
    };

    const columns = useMemo(
        () => [
            {
                // first group 
                Header: " ",
                // First group columns
                columns: [
                    {
                        Header: "ID",
                        accessor: "id",
                        Cell: ({ row }) => <>{row.original.id}</>
                    },
                    {
                        Header: <>{t('CategoriesTable.6')}</>,
                        accessor: "name",
                    }, 
                    {
                        Header: <>{t('CategoriesTable.0')}</>,
                        accessor: "created_at",
                        Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>
                    },
                    {
                        Header: <>{t('CategoriesTable.1')}</>,
                        accessor: "archive",
                        Cell: e => e.value == true ? <>{t('CategoriesTable.7')}</> : <>{t('CategoriesTable.8')}</>
                    },
                    {
                        Header: <></>,
                        accessor: "info",
                        Cell: ({ row }) => <Link className="simple-link" style={{ cursor: 'pointer' }} to={{ pathname: '/categories/show/' + `${row.original.id}`}} title='show'><Visibility className='table-icons'/></Link>
                    },
                    {
                        Header: <></>,
                        accessor: "has_level",
                        Cell: ({ row }) => <>{row.original.has_level == false? <span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleEdit(row.original.id)}><BorderColorOutlined className='table-icons' /></span>:<span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleRename(row.original.id)}><BorderColorOutlined className='table-icons' /></span>}</>
                    },
                    {
                        Header: <></>,
                        accessor: "updated_at",
                        Cell: ({ row }) =>  <span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleClone(row.original.id)} title='clone'><ContentCopyOutlined className='table-icons' /></span>
                    },
                    {
                        Header: <></>,
                        accessor: " ",
                        Cell: ({ row }) => <span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleArchive(row.original)} title={archive ? t('CategoriesTable.9') : t('CategoriesTable.10')}>{archive ? <FolderOpen className='table-icons' /> : <DriveFolderUpload className='table-icons' />}</span>
                    }
                ]
            },
        ],
        [archive, currentLanguage]
    );

    const handleShow = (id) => {
        navigate('/categories/show/' + id)
    };

    const handleEdit = (id) => {
        navigate('/categories/edit/' + id)
    };

    const handleAddPoints = (id) => {
        navigate('/categories/AddPointsCategory/' + id)
    };

    const handlePreview = (user) => {
        setShow(true);
        setCurrent(user);
    }

    const handleClone = (id) => {
        navigate('/categories/clone/' + id)
    };

    const handleRename = (id) => {
        navigate('/categories/edit-name/' + id)
    };


    const handleDestroy = async (item) => {
        setLoading(true);
        fetch(process.env.REACT_APP_API_URL + "/categories/destroy/" + item.id, {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json", 
                "api-key": process.env.REACT_APP_API_KEY 
              },
        }).then((response) => {
            if(response.ok){
                console.log(response);
                fetchData();
            }else{
                console.log('response was not ok')
            }
        
        })
    }

    const handleArchive = (item) => {
        setLoading(true);
        console.log(item.id, item.archive)
        const body = { archive: !item.archive }

        fetch(process.env.REACT_APP_API_URL + "/categories/update/" + item.id, {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json", 
                "api-key": process.env.REACT_APP_API_KEY 
              },
            body: JSON.stringify(body)
        }).then((response) => {
            if(response.ok){
                console.log(response);
                fetchData();
            }else{
                console.log('response was not ok')
            }
        
        })
    }


    return (
        <>
            <Sidebar />

            <div className="frame">

                <br />

                <div className='d-flex align-items-baseline'>
                    <h2 className='title'>{t('CategoriesTable.5')}</h2>
                    <div>
                        <button className={archive === false ? 'button-gray mx-1 px-3 py-1' : 'button-gray-active mx-1 px-3 py-1'} onClick={() => setArchive(true)}>{t('Buttons.3')}</button>
                        <button className={archive === false ? 'button-gray-active mx-2 px-3 py-1' : 'button-gray mx-2 px-3 py-1'} onClick={() => setArchive(false)}>{t('Buttons.4')}</button>
                    </div>
                </div>

                <hr />

                {loading ? <></> : <>
                <CategoriesTable columns={columns} data={archive == true?  points : pointsFiltered} route="/categories/new" />
                </>}    
            </div>

            <Modal
                open={show}
                size="lg"
                onClose={() => setShow(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <PreviewCategory current={current} />
                </Box>
            </Modal>



            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </>
    )
}

export default Categories;
import React, { useMemo, useState, useEffect } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import PreviewLevel from './PreviewLevel';
import LevelTable from '../Points/PointsTable';
import { useTranslation } from 'react-i18next';
import Sidebar from '../Sidebar';
import fetchApi from '../hooks/fetchApi';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ViewLevels = () => {

    const [loading, setLoading] = useState(true);
    const [levels, setLevels] = useState([]);
    const [data2, setPoints2] = useState([]);
    const [archive, setArchive] = useState(true);
    const [show, setShow] = useState(false);
    const [current, setCurrent] = useState(null);
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    let currentLanguage = i18n.language;

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        const { ok, data } = await fetchApi('/levels/index')

        if(ok){
            let normalData = data.filter(el => el.archive == 0)
            setLevels(normalData);
            let filteredData = data.filter(el => el.archive != 0)
            setPoints2(filteredData)
            setLoading(false);
        } else {
            setLoading(false);
        }
   
    };

    const columns = useMemo(
        () => [
            {
                // first group 
                Header: " ",
                // First group columns
                columns: [
                    {
                        Header: "ID",
                        accessor: "id",
                        Cell: ({ row }) => <>{row.original.id}</>
                    },
                    {
                        Header: <>{t('LevelsTable.0')}</>,
                        accessor: "title", 
                    },
                    {
                        Header: <>{t('LevelsTable.1')}</>,
                        accessor: "expiry_year", 
                        Cell: ({ row }) => <>{row.original.expiry_year}-{row.original.expiry_month}-{row.original.expiry_day} </>
                    },
                    {
                        Header: <>{t('LevelsTable.2')}</>,
                        accessor: "created_at",
                        Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>
                    },
                    {
                        Header: <>{t('LevelsTable.3')}</>,
                        accessor: "archive",
                        Cell: e => e.value == true ? <>{t('Tables.No')}</> : <>{t('Tables.Yes')}</>
                    },
                    {
                        Header: <></>,
                        accessor: "expiry_month", 
                        Cell: ({ row }) => <Link className="simple-link" style={{ cursor: 'pointer' }} to={{ pathname: '/levels/show/' + `${row.original.id}`}} title='show'><VisibilityIcon className='table-icons'/></Link>
                    },
                    {
                        Header: <></>,
                        accessor: "expiry_day", 
                        Cell: ({ row }) =>  <span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleClone(row.original.id)} title='clone'><ContentCopyOutlinedIcon className='table-icons'/></span>
                    },
                    {
                        Header: <></>,
                        accessor: " ",
                        Cell: ({ row }) => <><span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleArchive(row.original)} title={archive ? 'archive' : 'unarchive'}>{archive ? <FolderOpenIcon className='table-icons' /> : <DriveFolderUploadIcon className='table-icons' />}</span></>
                    },
                ]
            },
        ],
        [archive, currentLanguage]
    );

    const handleArchive = async (item) => {
        setLoading(true);
        const body = { archive: !item.archive };

        const { ok } = await fetchApi(`/levels/update/${item.id}`, 'POST', JSON.stringify(body));

        if (ok) {
            fetchData();
        }
    }

    const handlePreview = (user) => {
        setShow(true);
        setCurrent(user);
    };

    const handleClone = (id) => {
        navigate('/levels/clone/' + id)
    };

    return (
        <>
            <Sidebar />

            <div className="frame">

                <br />

                <div className='d-flex align-items-baseline'>
                    <h2 className='title'>{t('LevelsTable.7')}</h2>
                    <div>
                        <button className={archive == false? 'button-gray mx-1 px-2 py-1' : 'button-gray-active mx-1 px-2 py-1'} onClick={() => setArchive(true)}>{t('Buttons.3')}</button>
                        <button className={archive == false? 'button-gray-active mx-1 px-2 py-1' : 'button-gray mx-1 px-2 py-1'} onClick={() => setArchive(false)}>{t('Buttons.4')}</button>
                    </div>
                </div>    

                <hr />

                {loading ? <></> : <>
                    <LevelTable columns={columns} data={archive == true?  levels : data2} route="/levels/new" />
                </>}
            </div>

            <Modal
                open={show}
                size="lg"
                onClose={() => setShow(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <PreviewLevel current={current} />
                </Box>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default ViewLevels;

import React, { useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import StampForlevel from './StampForLevel';
import { useTranslation } from 'react-i18next';

const PreviewLevel = ({ current }) => {

    const { t, i18n } = useTranslation();

    const [data, setData] = useState([]);
    const [levelName, setLevelName] = useState('');
    const [loading, setLoading] = useState(true);
    const [expiry_day, setDay] = useState();
    const [expiry_month, setMonth] = useState();
    const [expiry_year, setYear] = useState();

    useEffect(() => {
        fetchData();
    }, [current])

    const fetchData = async () => {

        var requestOptions = { headers: { "api-key": process.env.REACT_APP_API_KEY } }

        const res = await fetch(
            process.env.REACT_APP_API_URL + '/levels/show/' + current, requestOptions
        );
        if(res.ok){
            const json = await res.json();
            let subCategoriesArray = JSON.parse(json.subcategories)
            setLevelName(json.title)
            setData(subCategoriesArray);
            setDay(json.expiry_day);
            setMonth(json.expiry_month);
            setYear(json.expiry_year);
            setLoading(false);
        }else{
            console.log('response was not ok')
            setLoading(false);
        }
    
      };



    return (
        <div>
            {loading ? <>Loading...</> : <>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div>
                        <span className='subtitle'>
                            {t('PreviewLevel.0')} Nº {current}&nbsp;&gt;&nbsp;
                        </span>
                        <span className='subtitle-light'>
                            {levelName}
                        </span>
                    </div>
                    <div>
                        <span className='subtitle'>
                            {t('PreviewLevel.1')}&nbsp;&gt;&nbsp; 
                        </span>
                        <span className='subtitle-light'>
                            {expiry_year} {expiry_month} {expiry_day}
                        </span>
                    </div>
                </div>    

                <hr />
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Level">
                        <div className='row'>
                            <div className='col'>
        
                                {data.map((item, i) => 
                                    
                                    <>
                                        {item.belongs != 0 ? 
                                        <div >
                                            <p style={{ marginLeft: (String(item.nesting * 25) + 'px') }}>
                                                    
                                                <b>  {item.name}  </b>
                                                &nbsp;-&gt; {t('PreviewLevel.2')}: {item.points}&nbsp;

                            
                                            </p>
                                            </div> : <div >
                                            <p>
                                                
                                                <b> {item.name}</b>
                                                &nbsp;-&gt; {t('PreviewLevel.2')}: {item.points} &nbsp; 

                            
                                            </p>
                                        </div>}
                                    </>
                                    
                                )} 

                            </div>

                        </div>
                    </Tab>
                    
                    <Tab eventKey="other" title="Stamp">
                        <StampForlevel current={current} />
                    </Tab>

                </Tabs>    

            </>}
        </div>
    )
}

export default PreviewLevel;

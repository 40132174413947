import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadLevels from "./components/LoadLevelsV2";
import LoadUserData from "../Points/components/LoadUserDataV2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar";
import Avatar from "../assets/images/points_image.jpg";
import MainBtn from "../UI-kit/Buttons/MainBtn";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import UploadIcon from "../assets/icons/zertiassist_iconos_upload_24px.svg";
import DropFileZone from "../Points/components/DropFileZone";
import Mapper from "./components/MapperAddPoints";
import ZertiHelpComponent from "../UI-kit/ZertiHelpComponent/ZertiHelpComponent";
import fetchApi from "../hooks/fetchApi";
import Alert from "../../src/UI-kit/Alert/Alert";
import { Link, useLocation, useNavigate } from "react-router-dom";

const AddPointsCategory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const userID = location.state !== null && location.state.userID;

  const [data, setData] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);

  const [levels, setLevels] = useState([]);
  const [level, setLevel] = useState(null);

  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [load, setLoad] = useState(null);
  const [documents, setDocuments] = useState("");

  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const handlePointsChange = (e, i) => {
    let currentData = [...data];
    //console.log(currentData[i]);
    currentData[i].user_points = parseInt(e.target.value);
    setData(currentData);
  };

  const handlePickerChange = (e) => {
    //console.log(e.target.value)
    setLoad(parseInt(e.target.value));
  };

  const getUsers = async () => {
    const { ok, data } = await fetchApi("/users/index");

    if (ok) {
      //console.log(json);
      const user = userID
        ? data.users.filter((user) => user.id === Number(userID))[0]
        : null;
      setUser(user);
      setLevels(user !== null ? JSON.parse(user.current) : []);
      setUsers(data.users);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      title,
      user_id: user !== null ? user.id : null,
      subcategories: JSON.stringify(data),
      subindex: load,
      documents,
      category: categoryId,
    };

    const formData = new FormData();

    for (const property in body) {
      formData.append(property, body[property]);
    }
    console.log(formData);

    try {
      const resp = await axios.post(
        process.env.REACT_APP_API_URL + "/points/create",
        formData,
        {
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      //console.log(resp);
      setSuccessful(true);
      if (userID) {
        navigate("/users/profile/" + userID);
      } else {
        setSuccessful(true);
        navigate("/points");
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className="frame">
        {loading ? (
          <></>
        ) : (
          <>
            <br />

            <div className="d-flex justify-content-between align-items-center">
              <h2 className="title">{t("AddPoints.0")}</h2>
              <div className="text-end">
                {userID ? (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/users/profile/" + userID}
                  >
                    <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
                  </Link>
                ) : (
                  <Link style={{ textDecoration: "none" }} to="/points">
                    <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
                  </Link>
                )}

                <MainBtn
                  disabled={data.length === 0 || documents === ""}
                  type="submit"
                >
                  {t("Buttons.2")}
                </MainBtn>
              </div>
            </div>

            <hr />

            <div className="row h-100 px-2">
              {/* LEFT COLUMN */}
              <div
                className="col-6 col-lg-4 white-box"
                style={{
                  backgroundImage: "url(" + Avatar + ")",
                  objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top",
                  backgroundSize: "contain",
                }}
              >
                <div className="px-5" style={{ marginTop: "230px" }}>
                  <p className="subtitle fw-bold">Title*</p>

                  <TextField
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    label={"Title"}
                    style={{ width: "100%" }}
                  />
                  <br />

                  <LoadUserData
                    userID={userID}
                    user={user}
                    setUser={setUser}
                    users={users}
                    setLevel={setLevel}
                    setLevels={setLevels}
                    setData={setData}
                  />

                  <LoadLevels
                    levels={levels}
                    setData={setData}
                    level={level}
                    setLevel={setLevel}
                    setCategoryId={setCategoryId}
                  />
                </div>

                <div className="p-2 mt-5">
                  <p className="subtitle">
                    {" "}
                    <img src={UploadIcon} className="sidebar-icons" />{" "}
                    {t("AttachFiles.0")}*
                  </p>
                  <div className="wrapper" style={{ height: "auto" }}>
                    <DropFileZone setDocuments={setDocuments} />

                    <p className="standard-text mt-3">{t("AddPoints.2")} </p>
                  </div>
                </div>
              </div>

              {/* RIGHT COLUMN */}

              <div className="col-6 col-lg-8">
                {data.length == 0 ? (
                  <ZertiHelpComponent
                    infoMessage="ZERTI HELP"
                    title={t("AddPoints.3")}
                    description={
                      <>
                        {t("AddPoints.4")}{" "}
                        <a
                          className="simple-link"
                          href="/levels/new"
                          style={{ textDecoration: "none" }}
                        >
                          {t("AddPoints.5")}
                        </a>{" "}
                        {t("AddPoints.6")}.
                      </>
                    }
                    arrowWidth="250"
                    arrowHeight="410"
                  />
                ) : (
                  <Mapper data={data} handlePointsChange={handlePointsChange} />
                )}
              </div>
            </div>

            {successful && (
              <Alert
                message={t("Messages.The operation was successful")}
                severity={"success"}
              />
            )}
            {error && (
              <Alert
                message={"Messages.Something went wrong, please try again"}
                severity={"error"}
              />
            )}
          </>
        )}
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AddPointsCategory;

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import COLORS from '../Constants';

const MainBtnCancel = styled(Button)({
  background: `${COLORS.cancel.background}`,
  border: '1px solid',
  borderRadius: '4px',
  color: `${COLORS.cancel.text}`,
  height: '42px',
  padding: '0 30px',
  textTransform: 'uppercase',
  marginLeft: '5px',
  '&:hover': {
    background: `${COLORS.hoverBackground}`,
    color: `${COLORS.text}`
  },
  fontWeight: '500'
});


export default MainBtnCancel;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import UserFiles from "../Points/components/ShowUserFiles";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import fetchApi from "../hooks/fetchApi";
import DropFileZone from "../Points/components/DropFileZone";
import Mapper from "./components/MapperAddPoints";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "../assets/images/points_image.jpg";
import MainBtn from "../UI-kit/Buttons/MainBtn";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import UploadIcon from "../assets/icons/zertiassist_iconos_upload_24px.svg";
import Sidebar from "../Sidebar";
import AlertV2 from "../../src/UI-kit/Alert/AlertV2";
import { Link } from "react-router-dom";

const EditPoints = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const userPoints = location.state !== null && location.state.user;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [documents, setDocuments] = useState("");
  const [userID, setUserID] = useState(null);
  const [level, setLevel] = useState([]);
  const [levelID, setLevelID] = useState(null);
  const [user, setUser] = useState([]);

  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    fetchData();
    getLevels();
    getUsers();
  }, [userID, levelID]);

  const fetchData = async () => {
    const { ok, data } = await fetchApi("/points/show/" + params.id);

    if (ok) {
      console.log(data);
      setUserID(data.user_id);
      setLevelID(data.category);
      let subCategoriesArray = JSON.parse(data.subcategories);
      setData(subCategoriesArray);
      setTitle(data.title);
      setLoading(false);
    } else {
      console.log("response was not ok");
    }
  };

  const getLevels = async () => {
    const { ok, data } = await fetchApi("/levels/index");

    if (ok) {
      console.log(data);
      let level = data.find((level) => level.category_id === levelID);
      setLevel(level);
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const getUsers = async () => {
    const { ok, data } = await fetchApi("/users/index");

    if (ok) {
      //console.log(data);
      let users = data.users;
      let user = users.find((user) => user.id === userID);
      setUser(user);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const handlePointsChange = (e, i) => {
    let currentData = [...data];
    //console.log(currentData[i]);
    currentData[i].user_points = parseInt(e.target.value);
    setData(currentData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (data.length === 0 || documents === "") {
      setIsAlert({
        open: true,
        severity: "warning",
        message: t(
          "Messages.To save the changes you must attach the corresponding certification"
        ),
      });
      return;
    }

    setLoading(true);

    const body = {
      title,
      subcategories: JSON.stringify(data),
      documents,
    };

    const formData = new FormData();

    for (const property in body) {
      formData.append(property, body[property]);
    }

    try {
      const resp = await axios.post(
        process.env.REACT_APP_API_URL + "/points/update/" + params.id,
        formData,
        {
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      console.log(resp.status);
      if (resp.status === 200) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
        setTimeout(() => {
          if (userPoints) {
            navigate(`/users/profile/${userPoints}`);
          } else {
            navigate("/points");
          }
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className="frame">
        {loading ? (
          <></>
        ) : (
          <>
            <br />

            <div className="d-flex justify-content-between align-items-center">
              <h2 className="title">{t("AddPoints.0")}</h2>
              <div className="text-end">
                <Link
                  style={{ textDecoration: "none" }}
                  to={userPoints ? `/users/profile/${userPoints}` : "/points"}
                >
                  <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
                </Link>
                <MainBtn
                  type="submit"
                  title={t(
                    "Messages.To save the changes you must attach the corresponding certification"
                  )}
                >
                  {t("Buttons.2")}
                </MainBtn>
              </div>
            </div>

            <hr />

            <div className="row h-100 px-2">
              {/* LEFT COLUMN */}
              <div
                className="col-lg-6 col-xl-4 white-box"
                style={{
                  backgroundImage: "url(" + Avatar + ")",
                  objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top",
                }}
              >
                <div className="px-5" style={{ marginTop: "230px" }}>
                  <p className="subtitle fw-bold">Title*</p>

                  <TextField
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    label={"Title"}
                    style={{ width: "100%" }}
                  />
                  <br />

                  <div className="mt-2">
                    <p className="subtitle fw-bold">{t("LoadUser.0")}*</p>
                    <TextField
                      disabled
                      value={
                        user != null
                          ? user.first_name + " " + user.last_name
                          : "Loading"
                      }
                      label=" "
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="mt-2">
                    <p className="subtitle fw-bold">{t("LoadLevels.0")}*</p>
                    <TextField
                      disabled
                      value={level != null ? level.title : "Loading"}
                      label=" "
                      style={{ width: "100%" }}
                    />
                  </div>

                  <UserFiles current={params.id} />
                </div>

                <div className="p-2 mt-2">
                  <p className="subtitle">
                    {" "}
                    <img src={UploadIcon} className="sidebar-icons" />{" "}
                    {t("AttachFiles.0")}*
                  </p>
                  <div className="wrapper" style={{ height: "auto" }}>
                    <DropFileZone setDocuments={setDocuments} />

                    <p className="standard-text mt-3">{t("AddPoints.2")} </p>
                  </div>
                </div>
              </div>

              {/* RIGHT COLUMN */}

              <div className="col-lg-6 col-xl-8">
                <Mapper data={data} handlePointsChange={handlePointsChange} />
              </div>
            </div>

            {isAlert.open && (
              <AlertV2
                isAlert={isAlert}
                setIsAlert={setIsAlert}
                severity={isAlert.severity}
                message={isAlert.message}
              />
            )}
          </>
        )}
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default EditPoints;

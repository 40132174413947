import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./i18n";
import { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./store";
import "./assets/fonts/index.css";

ReactDOM.render(
  <Suspense fallback={<div>Load...</div>}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

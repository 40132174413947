import { Info } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import fetchApi from "../../hooks/fetchApi";

const ShowUserFiles = ({ current }) => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [current]);

  const fetchData = async () => {
    const { ok, data } = await fetchApi("/points/get_attachments/" + current);

    if (ok) {
      //console.log(json);
      setData(data);
      setLoading(false);
    } else {
      console.log("response was not ok");
    }
  };

  //console.log(data)
  return (
    <div className="mt-4">
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <hr />
              <h2 className="subtitle text-left">{t("ShowUserFiles.0")}:</h2>
              <div className="text-left">
                <p className="subtitle mt-3">
                  Name:{" "}
                  <a
                    className="simple-link"
                    href={`${data.url.replace(
                      "http://127.0.0.1:3001/",
                      process.env.REACT_APP_DOWNLOAD_URL
                    )}`}
                    target="_blank"
                    style={{ fontSize: "16px" }}
                  >
                    {data.title.slice(0, 15)} ...
                  </a>
                </p>

                {/*<p className='subtitle'>Url:&nbsp;<a className='simple-link'  href={`${data.url}`} target="_blank" style={{fontSize: '18px'}}>See this file</a></p>*/}

                {/* <p className='subtitle'>Url:&nbsp;<a className='simple-link'  href={`${data.url.replace('http://localhost:3001/', process.env.REACT_APP_DOWNLOAD_URL )}`} target="_blank" style={{fontSize: '16px'}}>See this file</a></p>*/}
              </div>
              <hr />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShowUserFiles;

import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewCertificate = ({ current, level, res }) => {
  console.log(current);
  console.log(res);
  console.log("level", level);

  const { user } = useSelector((state) => state.user);

  const { t, i18n } = useTranslation();

  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [templates, setTemplates] = useState("");
  const [due, setDue] = useState("");

  useEffect(() => {
    fetchData();
    fetchDataTemplates();
  }, []);

  const fetchData = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/builders/index",
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      setData(json);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const fetchDataTemplates = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
        "/levels/get_template_for_level/" +
        level.id,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      if (json.length > 1) {
        let lastElement = json[json.length - 1];
        setTemplates(lastElement);
      }

      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      user: current,
      due,
      template_id: templates.id,
      level: level.title,
      expiry_day: level.expiry_day,
      expiry_month: level.expiry_month,
      expiry_year: level.expiry_year,
      content: JSON.stringify(res),
      level_id: level.id,
      signed_by: user.user_id,
      signed: true,
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(body),
    };

    let response = await fetch(
      process.env.REACT_APP_API_URL + "/certificates/create",
      requestOptions
    );
    if (response.ok) {
      let details = await response.json();
      setLoading(true);
      navigate("/certificates");
      console.log(details);
    } else {
      console.log(response);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <br />
        <h2 className="title">
          {t("NewCertificate.0")}:{" "}
          <span className="fw-normal">{level.title}</span>
        </h2>
        <hr />
        <div className="row text-center">
          <div className="col">
            <label className="fw-bold">Choose Template:</label>

            <br />
            <br />

            <Autocomplete
              getOptionSelected={(option, value) =>
                option.title === value.title
              }
              getOptionLabel={(option) => option.title || ""}
              id="tags-outlined"
              options={data}
              value={templates}
              filterSelectedOptions
              onChange={(e, value) => setTemplates(value)}
              renderInput={(params) => (
                <TextField {...params} label="Choose template" />
              )}
            />
          </div>
        </div>

        <div className="row" style={{ padding: "20px", margin: "auto" }}>
          <div className="col">
            <h4 className="subtitle text-center mb-2">
              {t("NewCertificate.1")}:
            </h4>

            {res.map((item, i) => (
              <>
                {item.belongs != 0 ? (
                  <div>
                    <p style={{ marginLeft: String(item.nesting * 10) + "px" }}>
                      <b> {item.name} </b>
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      <b> {item.name} </b>
                    </p>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>

        <div className="text-center mb-3">
          <Button variant="success" type="submit">
            {t("Buttons.2")}
          </Button>
        </div>
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default NewCertificate;

import { RichTextEditor } from "@mantine/rte";

function RichText({ content, setContent }) {
  return (
    <RichTextEditor
      value={content}
      onChange={setContent}
      controls={[["bold", "italic", "underline"]]}
    />
  );
}

export default RichText;

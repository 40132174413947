import React, { useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import MainBtn from '../UI-kit/Buttons/MainBtn';
import Checkbox from '../UI-kit/Checkbox/Checkbox';
import LoginHeader from '../UI-kit/Text/LoginHeader';

import LoginFooter from '../UI-kit/Text/LoginFooter';
import LoginIMG from '../../src/assets/images/login_bg_desktop.jpg';
import Alert from '../UI-kit/Alert/Alert';

const ResetPassword = () => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [new_password, setPassword] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (e) => {

        e.preventDefault();

        setLoading(true)

        const user = { email };
    
        const response = await fetch(process.env.REACT_APP_API_URL + '/sessions/forgot_password', {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json", 
                "api-key": process.env.REACT_APP_API_KEY 
              },
            body: JSON.stringify(user)
            });
            const data = await response.json();
        if(data.token){

            //console.log(data)
            setToken(data.token)
            
            setLoading(false)

            
        }  else if (data.message == 'Invalid User') {
            //alert('Invalid user')
            setError(true)

            setLoading(false)

        }
                
            
    };

    const handleSubmit2 = async (e) => {

        e.preventDefault();

        setLoading(true)

        const values = { email, reset_password_token: token, new_password };

        const response = await fetch(process.env.REACT_APP_API_URL + '/sessions/reset_password', {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json", 
                "api-key": process.env.REACT_APP_API_KEY 
              },
            body: JSON.stringify(values)
            });
            const data = await response.json();
   
        if(data){

            //console.log(data)
            setSuccessful(true);   
            setLoading(false)
            window.location.href='/';

        }  else {
            //alert('Error, please try again')
            setError(true)
            setLoading(false)

        }
                
            
    };

    return (
    <>

        <section className="vh-100" style={{backgroundImage: 'url(' + LoginIMG + ')', objectFit: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6 d-flex justify-content-end mt-5">

                        <div className="d-flex align-items-center px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5 login-frame">
                            {token == ""?     
                            <form  onSubmit={handleSubmit} style={{width: '25rem'}}>

                                <LoginHeader />


                                <div className="mt-1">
                                    <br/>
                                    <TextField
                                        required 
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}    
                                        placeholder="name@domain.com" 
                                        style={{width: '100%'}}
                                        label={'Email'}
                                      

                                    />

                                </div>

               


                                <MainBtn
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="mt-3 mb-3" 
                                    style={{width: '100%', marginLeft: '0'}}
                                    disabled={email == ""}

                                >
                                    GET TOKEN TO RESET PASSWORD
                                </MainBtn>

                                  

                                <div className='d-flex justify-content-between mt-3'> 

                                    {/* <p onClick={() => window.location.href = '/'} className="simple-link" style={{cursor: 'pointer'}}>Log in</p> */}   

                                    <p className="standard-text mb-4 pb-lg-2">New to BVTQ? <span onClick={() => window.location.href = '/register'} className="simple-link" style={{cursor: 'pointer'}}>Sign up</span></p> 
     
                                </div>       
                            </form>
                            :<></>}

                            {token != ""? 
                                <form onSubmit={handleSubmit2} style={{width: '25rem'}}>

                                    <LoginHeader />

                                    <div className="mt-1">
                                        <br/>
                                        <TextField
                                            required 
                                            type="text"
                                            value={token}
                                            disabled
                                            label={'token'}
                                            style={{width: '100%'}}

                                        />

                                    </div>


                                    <div className="mt-2">
                                        <br/>
                                        <TextField
                                            required 
                                            type="text"
                                            value={new_password}
                                            onChange={(e) => setPassword(e.target.value)}    
                                            placeholder="letters" 
                                            style={{width: '100%'}}
                                            label={'New Password'}

                                        />

                                    </div>
                                    <MainBtn
                                        type="submit"
                                        onClick={handleSubmit2}
                                        className="mt-3 mb-3" 
                                        style={{width: '100%', marginLeft: '0'}}
                                        disabled={new_password.length < 6}


                                    >
                                        RESET
                                    </MainBtn>


                                </form>

                            :<></>}
                            
                            

                            {successful ?
                            <Alert message={'success'} severity={'success'} />
                            : <></>}
                            {error ?
                            <Alert message={'Invalid user'} severity={'error'} />
                            : <></>}

                        </div>

                    </div>

                </div>
                <LoginFooter />
            </div>
        </section>


    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
    );
}


export default ResetPassword;
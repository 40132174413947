import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import avatar from "./assets/profile.png";
import LanguageIcon from "@mui/icons-material/Language";
import HomeIcon from "../src/assets/icons/zertiassist_iconos_home_24px.svg";
import COLORS from "./UI-kit/Constants";

const ResponsiveAppBar = ({ deleteToken }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElUserlang, setAnchorElUserlang] = React.useState(null);
  const completePathName = location.pathname.split("/");

  var avatarURL = window.localStorage.getItem("avatar");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenUserMenulang = (event) => {
    setAnchorElUserlang(event.currentTarget);
  };

  const handleCloseUserMenulang = () => {
    setAnchorElUserlang(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function handleClick(lang) {
    i18n.changeLanguage(lang);
    handleCloseUserMenulang();
    // window.location.reload();
  }

  return (
    <AppBar position="static" style={{ backgroundColor: `${COLORS.navBar}` }}>
      <Box sx={{ width: "100%", pr: "20px", pl: "calc(86px + 2%)" }}>
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
            }}
          >
            <NavLink to={"/home"} style={{ paddingRight: "7px" }}>
              <img src={HomeIcon} />
            </NavLink>
            <div className="d-flex">
              <Typography
                style={{
                  color: "#50597B",
                  opacity: "50%",
                  fontSize: "19.5px",
                  paddingRight: "4px",
                }}
              >
                /
              </Typography>

              {completePathName.length > 2 ? (
                <>
                  <Typography
                    style={{
                      color: "#50597B",
                      opacity: "50%",
                      fontSize: "19.5px",
                      paddingRight: "4px",
                      textTransform: "capitalize",
                    }}
                  >
                    {t(`Navbar.${completePathName[1]}`)}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    style={{
                      color: "#50597B",
                      fontSize: "19.5px",
                      paddingRight: "4px",
                      textTransform: "capitalize",
                    }}
                  >
                    {t(`Navbar.${completePathName[1]}`)}
                  </Typography>
                </>
              )}
              {completePathName.length > 2 ? (
                <>
                  <Typography
                    style={{
                      color: "#50597B",
                      opacity: "50%",
                      fontSize: "19.5px",
                      paddingRight: "4px",
                    }}
                  >
                    /
                  </Typography>
                  <Typography
                    style={{
                      color: "#50597B",
                      fontSize: "19.5px",
                      textTransform: "capitalize",
                    }}
                  >
                    {t(`Navbar.${completePathName[2]}`)}
                  </Typography>
                </>
              ) : (
                ""
              )}
            </div>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open languages settings">
              <IconButton onClick={handleOpenUserMenulang} sx={{ p: 0 }}>
                <LanguageIcon style={{ color: "#50597B" }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUserlang}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUserlang)}
              onClose={handleCloseUserMenulang}
            >
              <MenuItem onClick={() => handleClick("en")}>
                English <i className="flag flag-us"></i>
              </MenuItem>

              <MenuItem onClick={() => handleClick("de")}>
                Deutsch <i className="flag flag-germany"></i>
              </MenuItem>

              <MenuItem onClick={() => handleClick("es")}>
                Español <i className="flag flag-spain"></i>
              </MenuItem>

              <MenuItem onClick={() => handleClick("fr")}>
                Français <i className="flag flag-france"></i>
              </MenuItem>

              <MenuItem onClick={() => handleClick("zh")}>
                中国人 <i className="flag flag-china"></i>
              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="Remy Sharp"
                  src={
                    avatarURL != null
                      ? avatarURL.replace(
                          "http://127.0.0.1:3001/",
                          process.env.REACT_APP_DOWNLOAD_URL
                        )
                      : avatar
                  }
                  style={{ marginLeft: "5px" }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbarlang"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <NavLink
                to="/profile"
                onClick={handleCloseUserMenu}
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem>Avatar</MenuItem>
              </NavLink>

              <MenuItem onClick={deleteToken}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default ResponsiveAppBar;

import { createSlice } from "@reduxjs/toolkit";

// Slice

const slice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = {};
    },
  },
});

export default slice.reducer;

// Actions

const { loginSuccess, logoutSuccess } = slice.actions;

export const login = (payload) => async (dispatch) => {
  try {
    dispatch(loginSuccess(payload));
  } catch (e) {
    return console.error(e.message);
  }
};

export const logout = () => async (dispatch) => {
  try {
    return dispatch(logoutSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

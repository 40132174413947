import React, { useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import noavatar from '../../src/assets/profile.png';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Sidebar from '../Sidebar'
import Button from '../UI-kit/Buttons/MainBtn'


const Profile = () => {

    const [loading, setLoading] = useState(false);
    const [avatar, setDocuments] = useState('');
    const adminID = localStorage.getItem("id");
    const avatarImg =  window.localStorage.getItem('avatar');
    const email = window.localStorage.getItem('email');

    const handleFileChange = (e) => {
        setDocuments(e.target.files[0])
    };

    const handleSubmit = async (e) => {

        e.preventDefault()

        const body = { documents: avatar }
        if(avatar != ''){

            setLoading(true)
            
            const formData = new FormData()

            for (const property in body) {
                formData.append(
                    property, body[property]
                )
            }
    
            try {
                const resp = await axios.post(process.env.REACT_APP_API_URL + '/sessions/new_attachment/'+ adminID, formData,{
                    headers: {
                        "api-key": process.env.REACT_APP_API_KEY 
                    }
                });
        
                console.log(resp.data);
                window.location.reload();
                setLoading(false);
            } catch (err) {
                // Handle Error Here
                console.log(err);
                setLoading(false);

            }
    
        }else{
            alert('Choose a file first')
        }

    };

    return (
    <>
        <Sidebar />

        <div className='frame'>
            <div className='row'>


                <div className='col text-center'>

                    <div className='d-flex justify-content-center mt-3'>
                        <Stack direction="row" spacing={2} style={{justifyContent: 'center'}}>
                            <Avatar alt="user avatar" src={avatarImg != null? avatarImg.replace('http://127.0.0.1:3001/', process.env.REACT_APP_DOWNLOAD_URL ) : noavatar} />               
                        </Stack>
                        <h4 className="text-black d-flex align-items-center" style={{ paddingLeft: '10px', marginBottom: '0px' }}>
                            <span className="text-secondary">{email}</span> 
                        </h4>
                    </div>

                    <div className="mt-3 text-center">
                        <input 
                            className="form-control form-control-sm" 
                            id="formFileSm" 
                            type="file"
                            accept=".png,.jpg"
                            onChange={handleFileChange}
                        />

                        <Button
                            onClick={handleSubmit}
                            className="mt-3 mb-3" 

                        >
                            Upload
                        </Button>

                    </div>

                </div>
            </div>
       

        </div>


    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
    </>
    );
}


export default Profile;
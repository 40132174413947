import React, { useState } from "react";
import axios from "axios";

import TextField from "@mui/material/TextField";

import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import UploadIcon from "../assets/icons/zertiassist_iconos_upload_24px.svg";
import DropFileZone from "../Points/components/DropFileZone";
import RichText from "./Richtext";
import RichTextTwo from "./RichtextTwo";
import RichTextThree from "./RichtextThree";
import RichTextFour from "./RichtextFour";
import MainBtn from "../UI-kit/Buttons/MainBtn";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import Radio from "@mui/material/Radio";

const Builder = () => {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [documents, setDocuments] = useState("");

  const [content, setContent] = useState("");

  const [inputList, setInputList] = useState([{ id: 0, question: "" }]);

  const [content_two, setContentTwo] = useState("");
  const [content_three, setContentThree] = useState("");
  const [content_four, setContentFour] = useState("");

  const [image, setImage] = useState("");
  const [color, setColor] = useState("");

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      title,
      subtitle,
      image,
      color,
      content,
      //content_two,
      //content_three,
      //content_four,
      documents,
    };

    const formData = new FormData();

    for (const property in body) {
      formData.append(property, body[property]);
    }

    try {
      const resp = await axios.post(
        process.env.REACT_APP_API_URL + "/builders/create",
        formData,
        {
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      console.log(resp.data);
      window.location.href = "/templates";
    } catch (err) {
      // Handle Error Here
      console.log(err);
    }
  };

  //console.log('first',content)

  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className="frame">
        <br />

        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title">{t("BuilderTable.5")}</h2>
          <div className="text-end">
            <Link style={{ textDecoration: "none" }} to="/templates">
              <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
            </Link>
            <MainBtn disabled={subtitle === ""} type="submit">
              {t("Buttons.2")}
            </MainBtn>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-7">
            <div className="white-box">
              <p className="subtitle fw-bold">1. {t("NewTemplate.0")}</p>
              <p className="subtitle fw-bold">{t("NewTemplate.1")}</p>

              <TextField
                required
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                label={t("NewTemplate.1")}
                style={{ width: "100%" }}
              />
            </div>

            <div className="white-box mt-2">
              <p className="subtitle fw-bold">2. {t("NewTemplate.2")}</p>
              <p className="subtitle fw-bold">{t("NewTemplate.3")}</p>

              <TextField
                required
                onChange={(e) => setSubtitle(e.target.value)}
                type="text"
                label={t("NewTemplate.3")}
                style={{ width: "100%" }}
              />

              <hr />

              <p className="subtitle fw-bold">
                {t("NewTemplate.4")}{" "}
                <span className="fw-light"> ({t("NewTemplate.5")})</span>
              </p>

              <p className="fw-normal">
                <span>%first_name%</span> &nbsp;&nbsp; <span>%last_name%</span>
              </p>
              <p className="fw-normal">
                <span>%date_of_issue%</span> &nbsp;&nbsp;{" "}
                <span>%due_date%</span>
              </p>

              <RichText content={content} setContent={setContent} />

              {/*  <div className='row'>

                            <div className='row'>
                                <div className='col'>

                                    UNO
                                    <RichText inputList={inputList} setInputList={setInputList}/>

                                </div>
                                <div className='col'>

                                    DOS 
                                    <RichTextTwo content_two={content_two} setContentTwo={setContentTwo}/>

                                </div>
    
                            </div>

                            <hr className='mt-3' />

                            <div className='row'>
                                    
                                <div className='col'>

                                    TRES
                                    <RichTextThree content_three={content_three} setContentThree={setContentThree}/>

                                </div>

                                <div className='col'>

                                    CUATRO
                                    <RichTextFour content_four={content_four} setContentFour={setContentFour}/>

                                </div>
    

                            </div>

                    
                        </div> */}
            </div>

            <div className="white-box mt-2">
              <div className="p-2 w-50">
                <p className="subtitle fw-bold">3. {t("NewTemplate.6")}</p>

                <div className="d-flex">
                  <Radio
                    checked={selectedValue === "a"}
                    onChange={handleChange}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                    style={{ color: "#40C9D5" }}
                  />

                  <p className="subtitle fw-bold mt-2 mb-0">
                    {t("NewTemplate.7")}:
                  </p>
                </div>

                <input
                  disabled={selectedValue === "b"}
                  onChange={(e) => setColor(e.target.value)}
                  type="color"
                  style={{ width: "100%" }}
                />

                <div className="d-flex">
                  <Radio
                    checked={selectedValue === "b"}
                    onChange={handleChange}
                    value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                    style={{ color: "#40C9D5" }}
                  />

                  <p className="subtitle fw-bold mt-2 mb-0">
                    {t("NewTemplate.8")}:
                  </p>
                </div>
                <div
                  className="wrapper"
                  style={{
                    height: "auto",
                    opacity: selectedValue === "b" ? "1" : "0.2",
                  }}
                >
                  <DropFileZone setDocuments={setDocuments} />

                  <p className="standard-text mt-3">{t("AddPoints.2")} </p>
                </div>
              </div>
            </div>

            <div className="white-box mt-2">
              <p className="subtitle fw-bold">4. {t("NewTemplate.9")}</p>

              <p className="subtitle fw-bold">{t("NewTemplate.10")}</p>

              <TextField
                required
                onChange={(e) => setImage(e.target.value)}
                type="text"
                label={t("NewTemplate.1")}
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div className="col-5 white-box" style={{ height: "fit-content" }}>
            <h4 className="subtitle fw-bold">{t("NewTemplate.11")}</h4>

            <div className="d-flex justify-content-center flex-column align-items-center text-center template-preview-box">
              <h4 className="subtitle fw-bold mt-4 mb-2">
                {t("NewTemplate.12")}
              </h4>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("NewTemplate.13")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("NewTemplate.4")}</p>
              </div>

              {/*                         <div className='template-preview-inner-box mt-2 w-75'>
                            <p className='mt-2'>Student Name</p>
                        </div>
*/}
              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("NewTemplate.14")}</p>
              </div>

              <div className="template-preview-inner-box mt-3 mb-4 w-50">
                <p className="mt-2">{t("NewTemplate.9")}</p>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Builder;

import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import COLORS from '../Constants';

const CheckboxMain = styled(Checkbox)({
  color: `${COLORS.checkbox.color}`,
  '&.Mui-checked': {
    color: `${COLORS.hoverBackground}`,
  },
  
});


export default CheckboxMain;
 import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
} from "@react-pdf/renderer";

import Background from './page1.png'
// Create styles

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFF',
        position: 'relative'
    },
    section: {
        margin: 10,
        padding: 10,
    },
    viewer: {
        width: window.innerWidth, //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
    image: {
        top: 0,
        left: 0,
        zIndex: 1,
        objectPositionX: 0,
        objectPositionY: 0
    },
    text: {
        position: 'absolute',
        zIndex: 999,
        top: 10,
        left: 10,
        objectPositionX: 10,
        objectPositionY: 10
    }
});

// Create Document Component
function BasicDocument() {
    return (
        <PDFViewer style={styles.viewer}>
            {/* Start of the document*/}
            <Document>
                {/*render a single page*/}
                <Page
                    size="A4"
                    style={styles.page}
                >
                    <View>
                        <View>
                            <Text style={styles.text}>
                                Holis
                            </Text>
                        </View>
                        <View>
                            <Image style={styles.image} src={Background} />
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
}
export default BasicDocument; 
const fetchApi = async (path, method = 'GET', body = "") => {
  let options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "api-key": process.env.REACT_APP_API_KEY,
    },
    redirect: "follow",
  };

  if (body !== "") {
    options.body = body;
  }

  const res = await fetch(`${process.env.REACT_APP_API_URL}${path}`, options);
  const data = await res.json();

  return {
    data,
    ok: res.ok,
    status: res.status,
  };
};

export default fetchApi;

import React, { useEffect } from "react";
import MuiAlert from "@mui/material/Alert";

const AlertV2 = ({ isAlert, setIsAlert, severity, message }) => {
  useEffect(() => {
    setTimeout(() => {
      setIsAlert({
        open: false,
      });
    }, 5000);
  }, []);

  return (
    <>
      {isAlert && (
        <MuiAlert
          variant="filled"
          severity={severity}
          className="fixed-bottom"
          sx={{
            width: "40%",
            bottom: "60px",
            left: "200px",
          }}
        >
          {message}
        </MuiAlert>
      )}
    </>
  );
};

export default AlertV2;

import React, { useMemo, useState, useEffect } from "react";
import ShowData from "../Users/UserData";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ShowUserPoints from "./components/ShowUserPoints";
import PointsTable from "./PointsTable";
import EditPoint from "./EditsPoints";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar";
import fetchApi from "../hooks/fetchApi";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewPoints = () => {
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;

  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setPoints] = useState([]);
  const [data2, setPoints2] = useState([]);
  const [archive, setArchive] = useState(true);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const [current, setCurrent] = useState(null);
  const [current1, setCurrent1] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { ok, data } = await fetchApi("/points/index");

    if (ok) {
      //console.log(data);
      let normalData = data.filter((el) => el.archived == 0);
      setPoints(normalData);
      let filteredData = data.filter((el) => el.archived != 0);
      setPoints2(filteredData);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
            Cell: ({ row }) => <>{row.original.id}</>,
          },
          /* {
                        Header: "User ID ",
                        accessor: "user_id",
                        Cell: e => e.value != 0 ? <><span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleShow(e.value)}>{e.value}</span></> : <>No data</>
                    }, */
          {
            Header: <>{t("PointsTable.0")}</>,
            accessor: "user_id",
          },
          {
            Header: <>{t("PointsTable.1")}</>,
            accessor: "title",
          },
          {
            Header: <>{t("PointsTable.2")}</>,
            accessor: "first_name",
          },
          {
            Header: <>{t("PointsTable.3")}</>,
            accessor: "last_name",
          },
          {
            Header: <>{t("PointsTable.4")}</>,
            accessor: "created_at",
            Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>,
          },
          {
            Header: <>{t("PointsTable.5")}</>,
            accessor: "archived",
            Cell: (e) =>
              e.value == true ? <>{t("Tables.No")}</> : <>{t("Tables.Yes")}</>,
          },
          {
            Header: <> </>,
            accessor: "attr_2",
            Cell: ({ row }) => (
              <Link
                className="simple-link"
                style={{ cursor: "pointer" }}
                to={{ pathname: "/points/edit/" + `${row.original.id}` }}
                title="edit"
              >
                <BorderColorOutlinedIcon className="table-icons" />
              </Link>
            ),
          },
          {
            Header: <> </>,
            accessor: "attr_1",
            Cell: ({ row }) => (
              <span
                className="simple-link"
                style={{ cursor: "pointer" }}
                onClick={() => handleArchive(row.original)}
                title={archive ? "archive" : "unarchive"}
              >
                {archive ? (
                  <FolderOpenIcon className="table-icons" />
                ) : (
                  <DriveFolderUploadIcon className="table-icons" />
                )}
              </span>
            ),
          },
          /*                     {
                        Header: "Summary",
                        Cell: ({ row }) => <span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleShowInfo(row.original.id)}>🔍</span>
                    }, */
          /*                  {
                        Header: <>{t('PointsTable.8')}</>,
                        accessor: "attr_3",
                        Cell: ({ row }) => <><span className="simple-link" style={{ cursor: 'pointer' }} onClick={() => handleDestroy(row.original)}>🗑</span></>
                    }, */
        ],
      },
    ],
    [archive, currentLanguage]
  );

  const handleArchive = (item) => {
    setLoading(true);
    console.log(item.id, item.archived);
    const body = { archived: !item.archived };

    fetch(process.env.REACT_APP_API_URL + "/points/update/" + item.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.ok) {
        console.log(response);
        fetchData();
      } else {
        console.log("response was not ok");
      }
    });
  };

  const handleDestroy = async (item) => {
    setLoading(true);
    console.log(item.id, item.archived);
    fetch(process.env.REACT_APP_API_URL + "/points/destroy/" + item.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    }).then((response) => {
      if (response.ok) {
        console.log(response);
        fetchData();
      } else {
        console.log("response was not ok");
      }
    });
  };

  const handleShow = (user) => {
    setShow(true);
    setCurrent(user);
  };

  const handleShowInfo = (user) => {
    setShow1(true);
    setCurrent1(user);
  };

  const handleEdit = (user) => {
    setShow2(true);
    setCurrent1(user);
  };

  return (
    <>
      <Sidebar />

      <div className="frame">
        <br />

        <div className="d-flex align-items-baseline">
          <h2 className="title">{t("PointsTable.9")}</h2>
          <div>
            <button
              className={
                archive == false
                  ? "button-gray mx-1 px-2 py-1"
                  : "button-gray-active mx-1 px-2 py-1"
              }
              onClick={() => setArchive(true)}
            >
              {t("Buttons.3")}
            </button>
            <button
              className={
                archive == false
                  ? "button-gray-active mx-1 px-2 py-1"
                  : "button-gray mx-1 px-2 py-1"
              }
              onClick={() => setArchive(false)}
            >
              {t("Buttons.4")}
            </button>
          </div>
        </div>

        <hr />

        {loading ? (
          <></>
        ) : (
          <>
            <PointsTable
              columns={columns}
              data={archive == true ? data : data2}
              route="/points/add-points"
            />
          </>
        )}
      </div>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ShowData current={current} />
        </Box>
      </Modal>

      <Modal
        open={show1}
        onClose={() => setShow1(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ShowUserPoints current={current1} />
        </Box>
      </Modal>

      <Modal
        open={show2}
        onClose={() => setShow2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EditPoint current={current1} />
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ViewPoints;

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from 'react-bootstrap/Button'
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import fetchApi from '../hooks/fetchApi';


const UserData = ({ current, setShow }) => {

    const { t, i18n } = useTranslation();

    let navigate = useNavigate();

    const [data, setData] = useState([]);
    const [user, setUser] = useState([]);
    const [levels, setLevels] = useState([]);
    const [levelID, setLevelID] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userLevel, setUserLevel] = useState([]);


    useEffect(() => {
        fetchUserInfo();
        fetchLevels();
    }, [current])

    const fetchUserInfo = async () => {

        const { ok, data } = await fetchApi('/users/show/' + current);

        if(ok){
            setUser(data);
            let levels = JSON.parse(data.current)
            if (levels !== null){
            setUserLevel(levels)
            };
            setLoading(false);
        }else{
            console.log('response was not ok');
            setLoading(false);
        }    

     
    }

    const fetchLevels = async () => {

        const { ok, data } = await fetchApi('/levels/index');

        if(ok){
            setLevels(data);
            setLoading(false);
        }else{
            console.log('response was not ok');
            setLoading(false);

        }    

     
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
    
        setLoading(true)
    
        const body = { 
          current: JSON.stringify(userLevel),
        };    
    
        var requestOptions = {
          method: 'POST',
          headers: { 
            "Content-Type": "application/json", 
            "api-key": process.env.REACT_APP_API_KEY 
            },
          body: JSON.stringify(body)
        };
    
        let response = await fetch(process.env.REACT_APP_API_URL + '/users/update/' + current, requestOptions)
          if (response.ok) {
            let details = await response.json()
            console.log(details)
            setLoading(false)
            navigate('/users')
            setShow(false)
            window.location.reload();
          } else {
            console.log('error');
            setLoading(false);
            navigate('/users');
            setShow(false);
            window.location.reload();
        }
                
    };
    //console.log(userLevel)
    return (
        <div>
            {loading ? <>Loading...</> : <>
                <span className='subtitle'>
                {t('UserData.0')}&nbsp;&gt;&nbsp;
                </span>
                <Link className='simple-link' to={{ pathname: '/users/edit/' + `${user.id}`}}>
                    {user.email}
                </Link>

                <hr />

                <div className='row'>
                    


                    <div className='col-4 border-end'>
                        <h2 className='subtitle'>Info:</h2>
                        <h5 className='info-text-small'>{t('UserData.1')}:</h5>
                        <h5 className='info-text-small' style={{marginLeft: '15px'}}>
                            {user.length != 0 ? <>
                                {user.updated_at.split('T')[0]} at {user.updated_at.split('T')[1]}</> : <>
                                Getting data...</>}
                        </h5>

                        {user.ev_id == 0?  <></> : <h5 className='info-text-small' style={{ marginBottom: '15px'}}>easyVerein ID: {user.ev_id}</h5>}

                        {user.url_profile_easyverein == ""? <></> :           
                        <a  className='simple-link'  href={`${user.url_profile_easyverein}`.replace('https//', 'wwww')} target="_blank">Link to easyVerein profile</a>
                        }             
                        <br />
                        {user.url_profile_wordpress == ""? <></> :     
                        <a  className='simple-link'  href={`${user.url_profile_wordpress}`.replace('https//', 'wwww')} target="_blank">Link to WordPress profile</a>
                        }
                    </div>



                    <form onSubmit={handleSubmit} className='col-8'>

                        <h2 className='subtitle'>{t('UserData.2')}:</h2>

                        <br />

                        <Autocomplete
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            required
                            multiple
                            disablePortal
                            id="multiple-limit-tags"
                            value={userLevel}
                            options={levels}
                            getOptionLabel={(option) => option.title}
                            onChange={(e,value) => setUserLevel(value)}
                            renderInput={(params) => <TextField  {...params} label="Choose levels" />}
                        />

                        <div className='text-center mt-4'>
                            <Button variant="success" type="submit">{t('Buttons.2')}</Button>
                        </div>

                    </form>

                </div>

            </>}
        </div>
    )
}

export default UserData;

import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Backdrop, Box, CircularProgress, Chip, Modal } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableUsers from "../Points/PointsTable";
import Sidebar from "../Sidebar";
import fetchApi from "../hooks/fetchApi";
import SwitchRole from "../UI-kit/Switch/SwitchRole";
import AlertV2 from "../UI-kit/Alert/AlertV2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ViewUsers = () => {
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;
  // redux
  const { user } = useSelector((state) => state.user);
  // utils
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  // data
  const [users, setUsers] = useState([]);
  const [selectedUserId, setCurrentUserId] = useState("");
  const [switchRole, setSwitchRole] = useState({
    owner: false,
    admin: false,
    secretary: false,
    member: false,
  });

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("UsersTable.1")}</>,
            accessor: "source",
          },
          {
            Header: <>{t("UsersTable.2")}</>,
            accessor: "first_name",
            Cell: ({ row }) => (
              <Link
                className="simple-link"
                style={{ cursor: "pointer", textDecoration: "none" }}
                to={{ pathname: `/users/profile/${row.original.id}` }}
              >
                {row.original.first_name}
              </Link>
            ),
          },
          {
            Header: <>{t("UsersTable.3")}</>,
            accessor: "last_name",
            Cell: ({ row }) => (
              <Link
                className="simple-link"
                style={{ cursor: "pointer", textDecoration: "none" }}
                to={{ pathname: `/users/profile/${row.original.id}` }}
              >
                {row.original.last_name}
              </Link>
            ),
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: <>{t("UsersTable.4")}</>,
            accessor: "street_address",
            Cell: ({ row }) => (
              <>
                {row.original.street_address} {row.original.city}{" "}
                {row.original.state} - {row.original.country} (
                {row.original.post_code})
              </>
            ),
          },
          {
            Header: <>{t("UsersTable.5")}</>,
            accessor: "mode",
          },
          {
            Header: <>{t("UsersTable.6")}</>,
            accessor: "created_at",
            Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>,
          },
          {
            Header: <>{t("UsersTable.7")}</>,
            accessor: "current",
            Cell: ({ row }) => (
              <>
                {row.original.current != null ? (
                  <>
                    {JSON.parse(row.original.current).map((item) => (
                      <div key={item.id}>
                        <Chip
                          size="small"
                          label={item.title}
                          style={{ marginBottom: "3px" }}
                        />{" "}
                        <br />{" "}
                      </div>
                    ))}
                  </>
                ) : (
                  <>{t("UsersTable.11")}</>
                )}
              </>
            ),
          },
          {
            Header: <>{t("UsersTable.13")}</>,
            accessor: " ",
            Cell: ({ row }) => (
              <Link
                className="simple-link"
                style={{ cursor: "pointer" }}
                to={{ pathname: `/users/assign/${row.original.id}` }}
              >
                <StarBorderIcon className="table-icons" />
              </Link>
            ),
          },
          {
            Header: <>{t("UsersTable.12")}</>,
            accessor: "role",
            Cell: ({ row }) => (
              <div
                onClick={() => handleShowRole(row.original.id)}
                style={{ cursor: "pointer" }}
              >
                <VisibilityIcon className="table-icons" />
              </div>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const fetchData = async () => {
    const { ok, data } = await fetchApi("/users/index");
    if (ok) {
      // console.log(data);
      setUsers(data.users);
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const fetchRoleData = async (id_user) => {
    try {
      setLoading(true);
      setCurrentUserId(id_user);
      const { ok, data } = await fetchApi(`/users/show_profile/${id_user}`);
      if (ok) {
        const { admin: roleData } = data;
        if (roleData.owner) {
          setSwitchRole({
            ...switchRole,
            owner: true,
          });
        } else if (roleData.admin) {
          setSwitchRole({
            ...switchRole,
            admin: true,
          });
        } else if (roleData.secretary) {
          setSwitchRole({
            ...switchRole,
            secretary: true,
          });
        } else if (roleData.member) {
          setSwitchRole({
            ...switchRole,
            member: true,
          });
        }
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowRole = (id_user) => {
    fetchRoleData(id_user);
    setShow(!show);
  };

  const handleEditRole = async () => {
    try {
      setLoading(true);
      const { owner, admin, secretary, member } = switchRole;
      const updateUser = {
        owner,
        admin,
        secretary,
        member,
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/update/${selectedUserId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify(updateUser),
        }
      );
      if (response.ok) {
        setIsAlert({
          open: true,
          severity: "success",
          message: t("Messages.The operation was successful"),
        });
      } else {
        setIsAlert({
          open: true,
          severity: "error",
          message: t("Messages.Something went wrong, please try again"),
        });
      }
    } catch (error) {
      console.log(error);
      setIsAlert({
        open: true,
        severity: "error",
        message: t("Messages.Something went wrong, please try again"),
      });
    } finally {
      setLoading(false);
      setShow(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Sidebar />

      <div className="frame">
        <br />
        <h2 className="title" style={{ marginBottom: "40px" }}>
          {t("UsersTable.0")}:
        </h2>

        <TableUsers columns={columns} data={users} route="/users/new" />
      </div>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SwitchRole
            administratorUser={user}
            title={t("UsersTable.12")}
            roles={switchRole}
            setRoles={setSwitchRole}
            isLoading={loading}
            setShow={setShow}
            handleEditRole={handleEditRole}
          />
        </Box>
      </Modal>

      {isAlert.open && (
        <AlertV2
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          severity={isAlert.severity}
          message={isAlert.message}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ViewUsers;

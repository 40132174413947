import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/icons/new_level_arrow.svg";
import Checkbox from "../Checkbox/Checkbox";

const ZertiHelpComponent = ({
  infoMessage,
  title,
  description,
  arrowWidth,
  arrowHeight,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col">
      <div>
        <p className="subtitle" style={{ fontSize: "12px", marginBottom: "0" }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            style={{ paddingTop: "10px" }}
          >
            <InfoOutlinedIcon />
          </IconButton>
          <span>{infoMessage}</span>
        </p>
        <h2 className='title' style={{ paddingLeft: "15px", fontSize: "34px" }}>
          {title}
        </h2>
        <h6
          style={{ paddingLeft: "15px", fontSize: "16px" }}
          className="subtitle"
        >
          {description}
        </h6>
        <div>
          <Checkbox
            defaultChecked
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <span className="subtitle">{t("ZertiHelp.Don't show this message again")}</span>
        </div>
        <img src={Arrow} width={arrowWidth} height={arrowHeight} />
      </div>
    </div>
  );
};

export default ZertiHelpComponent;

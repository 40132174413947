import React, { useState, useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function Alert({ severity, message }) {

    const [alert, setAlert] = React.useState(true);

    useEffect(() => {
        setTimeout(() => {
            setAlert(false);
        }, 5000);
    }, []);

    return (<>
        {alert?  

        <MuiAlert variant="filled" severity={severity}
        className='fixed-bottom'
        sx={{
            width: '40%',
            bottom: '60px',
            left: '200px'
          }}   
        >
        {message}
        </MuiAlert> 
        :<></>} 
    
    </>);
  }
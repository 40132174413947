import React from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { useTranslation } from "react-i18next";
import TableB from "react-bootstrap/Table";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Arrow from "../assets/icons/zertiassist_iconos_page_24px.svg";
import FloatingActionButtons from "../UI-kit/Buttons/FloatingActionButtons";

export default function Table({
  columns,
  data,
  route,
  state,
  isCertificateTable,
}) {
  const { t } = useTranslation();

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter, // The useFilter Hook provides a way to set the filter
    setGlobalFilter,
    page, // rows OR PAGE for the table based on the data passed
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { globalFilter, pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      initialState: {
        sortBy: [{ id: "id", desc: false }],
      },
    },
    useFilters, // Adding the useFilters Hook to the table
    useGlobalFilter,
    useSortBy,
    usePagination
    // You can add as many Hooks as you want. Hooks for react-table here
  );

  React.useEffect(() => {}, [globalFilter]);

  return (
    <>
      <div>
        <div className="d-flex justify-content-end" style={{ width: "93%" }}>
          <TextField
            className="search-field"
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            label={t("Homepage.4")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {!isCertificateTable && (
            <FloatingActionButtons
              route={route && route}
              state={state && state}
            />
          )}
        </div>

        <TableB
          {...getTableProps()}
          responsive
          borderless
          className="main-table"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "self-end",
                      }}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              src={Arrow}
                              className="sidebar-icons"
                              style={{ transform: "rotate(180deg)" }}
                            />
                          ) : (
                            <img src={Arrow} className="sidebar-icons" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row); // This line is necessary to prepare the rows and get the row props from react-table dynamically
              // Each row can be rendered directly as a string using the react-table render method
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="td">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </TableB>
      </div>
      <div
        className="pagination d-flex justify-content-end pb-5"
        style={{ width: "95%" }}
      >
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="pagination-btn pagination-buttons"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="pagination-btn pagination-buttons"
        >
          {"<"}
        </button>{" "}
        &nbsp;&nbsp;
        <span className="pagination-numbers ">{pageIndex + 1}</span>
        <span>&nbsp;of&nbsp;</span>
        <span className="pagination-numbers ">{pageOptions.length}</span>
        &nbsp;&nbsp;
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="pagination-btn pagination-buttons "
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="pagination-btn pagination-buttons "
        >
          {">>"}
        </button>{" "}
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import Avatar from "../assets/images/users_image.jpg";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import FormIcon from "../assets/icons/zertiassist_iconos_form_24px.svg";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Chip from "@mui/material/Chip";

const ShowCategory = () => {
  const { t, i18n } = useTranslation();

  const params = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [levelName, setLevelName] = useState("");
  const [expiry_day, setDay] = useState();
  const [expiry_month, setMonth] = useState();
  const [expiry_year, setYear] = useState();
  const [category_id, setCategoryID] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryDate, setCategoryDate] = useState("");
  const [categoryArchived, setCategoryArchived] = useState(false);

  useEffect(() => {
    fetchData();
    fetchData2();
  }, [category_id]);

  const fetchData = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/categories/show/" + params.id,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      console.log(json);
      setLevelName(json.name);
      setDay(json.expiry_day);
      setMonth(json.expiry_month);
      setYear(json.expiry_year);
      setCategoryID(json.id);
      setData(JSON.parse(json.subcategories));
      setCategoryDate(json.created_at.split("T")[0]);
      json.archive ? setCategoryArchived(false) : setCategoryArchived(false);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const fetchData2 = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/categories/show/" + category_id,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      //console.log(json);
      setCategoryName(json.name);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="frame">
        <br />
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title mb-0">{levelName}</h2>
        </div>

        <hr />

        <div className="row px-2">
          <div
            className="col-6 col-lg-4 white-box"
            style={{
              backgroundImage: "url(" + Avatar + ")",
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              backgroundSize: 'contain'
            }}
          >
            <div className="px-5" style={{ marginTop: "60%" }}>
              {categoryArchived ? (
                <Chip
                  size="small"
                  label={t('Buttons.4')}
                  style={{
                    marginBottom: "3px",
                    fontSize: "15px",
                    borderRadius: "5px",
                    fontWeight: "bolder",
                  }}
                />
              ) : (
                <Chip
                  size="small"
                  label={t('Buttons.3')}
                  style={{
                    marginBottom: "3px",
                    fontSize: "15px",
                    borderRadius: "5px",
                    backgroundColor: "#C6F1F5",
                    color: "#40C9D5",
                    fontWeight: "bolder",
                  }}
                />
              )}
              <p
                className="subtitle fw-bold"
                style={{ display: "flex", marginBottom: "5px" }}
              >
                <img
                  className="sidebar-icons"
                  src={FormIcon}
                  style={{ verticalAlign: "bottom", paddingRight: "10px" }}
                />{" "}
                <div style={{ paddingTop: "5px" }}>
                  <span>ID {category_id}</span>
                </div>
              </p>
              <p className="subtitle fw-bold" style={{ display: "flex" }}>
                <CalendarTodayOutlinedIcon
                  className="table-icons"
                  style={{ verticalAlign: "bottom" }}
                />{" "}
                <div style={{ paddingTop: "2px", paddingLeft: "10px" }}>
                  <span>{t('ShowCategory.0')} {categoryDate}</span>
                </div>
              </p>
            </div>
          </div>

          <div className="col-6 col-lg-8">
            <Table responsive borderless className="main-table">
              <thead>
                <tr>
                  <td className="subtitles fw-bold">{t('ShowCategory.1')}</td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.belongs != 0 ? (
                        <span
                          style={{
                            paddingLeft: String(item.nesting * 10) + "px",
                          }}
                        >
                          {item.name}
                        </span>
                      ) : (
                        <span>{item.name}</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowCategory;

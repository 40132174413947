import React, { useState, useEffect } from 'react'
import AttachFiles from './components/AttachFiles';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import UserFiles from '../Points/components/ShowUserFiles';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import fetchApi from '../hooks/fetchApi';

const ShowUserPoints = ({ current }) => {

    const { t, i18n } = useTranslation();

    let navigate = useNavigate();
 
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [documents, setDocuments] = useState('');
    const [lock, setLock] = useState(true);

    useEffect(() => {
        fetchData();
    }, [current])

    const fetchData = async () => {

        const { ok, data } = await fetchApi('/points/show/' + current);

        if(ok){
            //console.log(json);
            let subCategoriesArray = JSON.parse(data.subcategories)
            setData(subCategoriesArray);
            setTitle(data.title)
            setLoading(false);
        }else{
            console.log('response was not ok');

        }    

     
    };

    const setUnlock = () =>{
        setLock(false)
    }

    const setLocking = () =>{
        setLock(true)
    }

    const handlePointsChange = (e, i) => {
        let currentData = [...data];
        //console.log(currentData[i]);
        currentData[i].user_points = parseInt(e.target.value);
        setData(currentData);
    };
    
    const handleFileChange = (e) => {
        setDocuments(e.target.files[0])
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
    
        setLoading(true);
    
        const body = { 
          title,
          subcategories: JSON.stringify(data), 
          documents,
        };
    
        const formData = new FormData()
    
        for (const property in body) {
          formData.append(
            property, body[property]
          )
        }
    

        try {
            const resp = await axios.post(process.env.REACT_APP_API_URL + '/points/update/' + current, formData, {
                headers: {
                    "api-key": process.env.REACT_APP_API_KEY 
                }
            });
    
            console.log(resp.data);
            window.location.reload();

        } catch (err) {
            // Handle Error Here
            console.log(err);
        }
    
    
    };

    return (
        <form onSubmit={handleSubmit}>

            {loading ? <>Loading...</> : <>
                <div className='row'> 
                    <div className='col'>
                        <span className='subtitle'>
                            {t('EditPoints.0')}&nbsp;&gt;&nbsp;
                        </span>
                        <span className='subtitle-light'>
                            {title} - {current}                         
                        </span>

                        <hr />

                        <br />

                        <div className='text-center mt-2'>
                            <h5> {lock == true? 
                                <span onClick={setUnlock} style={{cursor: 'pointer'}}>🔐</span>:
                                <span onClick={setLocking} style={{cursor: 'pointer'}}>🔓</span>}
                                &nbsp;
                            </h5>
                            <TextField
                                disabled={lock}
                                required
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                label={'New Title'}
                            /> 
        
                        </div>
                    </div>
                </div> 
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Points">
                        <div className='row'>
                            <div className='col'>

                                <div className='text-center'>
                                    <AttachFiles handleFileChange={handleFileChange} />
                                </div>

                                <h2 className='subtitle text-left'>{t('EditPoints.1')}:</h2>
                                <br />   

                                {data.map((item, i) => 
                                    
                                    <>
                                        {item.belongs != 0 ? 
                                        <div>
                                            <p style={{ marginLeft: (String(item.nesting * 25) + 'px') }}>
                                                    
                                                <b>  {item.name}  </b>
                                                &nbsp;-&gt; {t('EditPoints.2')}:&nbsp;

                                                <input
                                                    disabled={lock}
                                                    required
                                                    type="number"
                                                    min="0" 
                                                    value={item.user_points}
                                                    onChange={(e) => handlePointsChange(e, i)} 
                                                    style={{width: '50px'}}
                                                />

                            
                                            </p>
                                            </div> : <div >
                                            <p>
                                                
                                                <b> {item.name}</b>
                                                &nbsp;-&gt; {t('EditPoints.2')}:&nbsp;

                                                <input
                                                    disabled={lock}
                                                    required
                                                    type="number"
                                                    min="0"
                                                    //max={item.points} 
                                                    value={item.user_points}
                                                    onChange={(e) => handlePointsChange(e, i)} 
                                                    style={{width: '50px'}}
                                                /> 

                            
                                            </p>
                                        </div>}
                                    </>
                                    
                                )} 

                            </div>

                        </div>        
                    </Tab>
                    
                    <Tab eventKey="other" title="Files">
                        <UserFiles current={current} />
                    </Tab>

                </Tabs>                


                
                <div className='text-center mb-3'>
                    <Button variant="success" type="submit">{t('Buttons.2')}</Button>
                </div>                    

            </>}
        </form>
    )
}

export default ShowUserPoints

import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Sidebar from '../Sidebar'
import MainBtn from '../UI-kit/Buttons/MainBtn';
import MainBtnCancel from '../UI-kit/Buttons/MainBtnCancel';
import PeopleAltIcon from '../assets/icons/zertiassist_iconos_user_24px.svg';
import LocationIcon from '../assets/icons/zertiassist_iconos_map_pin_24px.svg';
import SecurityIcon from '../assets/icons/zertiassist_iconos_security_24px.svg';
import PencilIcon from '../assets/icons/zertiassist_iconos_edit_24px.svg';
import Avatar from '../assets/images/users_image.jpg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Alert from '../../src/UI-kit/Alert/Alert';
import fetchApi from '../hooks/fetchApi';
import { stepConnectorClasses } from '@mui/material';


const EditUser = () => {

  const { t, i18n } = useTranslation();

  const params = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [source, setSource] = useState('');
  const [mode, setMode] = useState('');
  const [wordpress_user_id, setWPID] = useState('');
  const [ev_id, setEVID] = useState('');
  const [birth_date, setBirth] = useState('');
  const [url_profile_wordpress, setWP] = useState('');
  const [url_profile_easyverein, setEV] = useState('');
  const [street_address, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [post_code, setPost] = useState('');
  const [country, setCountry] = useState('');
  // User Roles
  const [role, setRole] = useState(0)
  const [owner, setOwner] = useState();
  const [admin, setAdmin] = useState();
  const [member, setMember] = useState();
  const [secretary, setSecretary] = useState();

  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {

    e.preventDefault();

    setLoading(true)

    const newUser = {
      email,
      password,
      password_confirmation,
      first_name,
      last_name,
      source: 'BVTQ APP',
      mode,
      birth_date,
      url_profile_wordpress: "",
      url_profile_easyverein: "",
      street_address,
      city,
      state,
      post_code,
      country,
      owner,
      admin,
      secretary,
      member
    };


    const response = await fetch(process.env.REACT_APP_API_URL + '/users/update/' + params.id, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY
      },
      body: JSON.stringify(newUser)
    });
    const data = await response.json();

    if (response.ok) {
      setSuccessful(true);
      window.location.href = '/users/edit/' + params.id;
      setLoading(false)


    } else {
      setError(true)
      setLoading(false)
    }



  }

  const fetchData = async () => {

    const { ok, data } = await fetchApi('/users/show_profile/' + params.id);
    //console.log('USERDATA: ', data.admin.admin)
    if (ok) {
      
      setEmail(data.user.email);
      setFirstName(data.user.first_name);
      setLastName(data.user.last_name);
      setSource(data.user.source);
      setBirth(data.user.birth_date);
      setStreet(data.user.street_address);
      setCity(data.user.city);
      setState(data.user.state);
      setPost(data.user.post_code);
      setCountry(data.user.country);
      setMode(data.user.mode);
      setOwner(data.admin.owner);
      setAdmin(data.admin.admin);
      setSecretary(data.admin.secretary);
      setMember(data.admin.member);

      if (data.admin.owner && !data.admin.admin && !data.admin.secretary && !data.admin.member) {
        setRole(3)
      } else if (!data.admin.owner && data.admin.admin && !data.admin.secretary && !data.admin.member) {
        setRole(2)
      } else if (!data.admin.owner && !data.admin.admin && data.admin.secretary && !data.admin.member) {
        setRole(1)
      } else if (!data.admin.owner && !data.admin.admin && !data.admin.secretary && data.admin.member) {
        setRole(0)
      } 

    } else {
      console.log('response was not ok')
      //throw new Error("response was not ok.");
      //setLoading(false);
    }

  };

  const handleRoleChange = (e) => {
    console.log(e.target.value)
    setRole(e.target.value);
    switch (e.target.value) {
      case 0:
        setOwner(false);
        setAdmin(false);
        setSecretary(false);
        setMember(true);
        break;
      case 1:
        setOwner(false);
        setAdmin(false);
        setSecretary(true);
        setMember(false);
        break;
      case 2:
        setOwner(false);
        setAdmin(true);
        setSecretary(false);
        setMember(false);
        break;
      case 3:
        setOwner(true);
        setAdmin(false);
        setSecretary(false);
        setMember(false);
        break;
      default:
        setOwner(false);
        setAdmin(false);
        setSecretary(false);
        setMember(true);
    }
    
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className='frame' >

        <br />

        <h2 className='title'>{t('UsersTable.10')}</h2>

        <div className='row h-100 px-2'>

          <div className='col-4 white-box' style={{ backgroundImage: 'url(' + Avatar + ')', objectFit: 'cover', backgroundRepeat: 'no-repeat', height: '736px' }}>

            <div className='px-3' style={{ marginTop: '230px' }}>

              <p className='subtitle'><img className='sidebar-icons' src={PeopleAltIcon} /> 1. {t('UserProfile.Personal Information')}</p>

              <TextField
                required
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                style={{ width: '100%' }}
                label={'First Name'}
              />

              <br />
              <br />

              <TextField
                required
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
                style={{ width: '100%' }}
                label={'Last Name'}
              />

              <br />
              <br />

              <TextField
                type='date'
                required
                value={birth_date}
                onChange={(e) => setBirth(e.target.value)}
                style={{ width: '100%' }}
                label={'Birth'}

              />

              <br />
              <br />

              <InputLabel className='mt-1' id="demo-simple-select-label">{t('UserProfile.Type of User')}</InputLabel>
              <br />
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => setMode(e.target.value)}
                value={mode}
                style={{ width: '100%' }}
                label=" "
              >
                <MenuItem value={''}>Choose</MenuItem>
                <MenuItem value={'person'}>Person</MenuItem>
                <MenuItem value={'organization'}>Organization</MenuItem>
              </Select>

            </div>
          </div>


          <div className='col-8 ps-4'>

            <div className='row white-box'>

              <p className='subtitle ps-4'><img className='sidebar-icons' src={LocationIcon} /> 2. {t('UserProfile.Address')}</p>

              <div className='col px-4'>


                <TextField
                  required
                  value={street_address}
                  onChange={(e) => setStreet(e.target.value)}
                  style={{ width: '100%' }}
                  label={'Street'}
                />


                <br />
                <br />

                <TextField
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  style={{ width: '100%' }}
                  label={'State'}
                />

                <br />
                <br />

                <TextField
                  required
                  value={post_code}
                  onChange={(e) => setPost(e.target.value)}
                  style={{ width: '100%' }}
                  label={'Post Code'}

                />

                <br />
                <br />


              </div>

              <div className='col px-3'>

                <TextField
                  required
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  style={{ width: '100%' }}
                  label={'Country'}
                />

                <br />
                <br />



                <TextField
                  required
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  style={{ width: '100%' }}
                  label={'City'}

                />

                <br />
                <br />

              </div>

            </div>


            <div className='row white-box mt-3'>

              <p className='subtitle ps-4'><img className='sidebar-icons' src={SecurityIcon} />3. {t('UserProfile.Security & login')}</p>

              <div className='col px-4'>


                <TextField
                  disabled
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@domain.com"
                  style={{ width: '100%' }}
                  label={'email'}
                />

                <br />
                <br />

                <TextField
                  disabled
                  type={showPassword ? "text" : "password"}
                  value={password_confirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  placeholder="At least 6 characters"
                  style={{ width: '100%' }}
                  label={'Password Confirmation'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

              </div>

              <div className='col px-3'>

                <TextField
                  disabled
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="At least 6 characters"
                  style={{ width: '100%' }}
                  label={'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <br />
                <br />

              </div>

            </div>

            <div className='row white-box mt-3'>

              <p className='subtitle'><img className='sidebar-icons' src={PencilIcon} />4. Additional Data</p>

              <div className='col px-4'>


                {/* <TextField 
                disabled
                type="text"
                //value={email}
                //onChange={(e) => setEmail(e.target.value)}    
                placeholder="text" 
                style={{width: '100%'}}
                label={'text'}
              /> */}

                <InputLabel className='mt-1' id="demo-simple-select-label">Role</InputLabel>
                <br />
                <Select
                  defaultValue=" "
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => handleRoleChange(e)}
                  value={role}
                  style={{ width: '100%' }}
                  label=" "
                >
                  <MenuItem value={0}>Member</MenuItem>
                  <MenuItem value={1}>Secretary</MenuItem>
                  <MenuItem value={2}>Admin</MenuItem>
                  <MenuItem value={3}>Owner</MenuItem>
                 
                </Select>

                <br />
                <br />

              </div>

              <div className='col px-3'>

              </div>

            </div>


            {/* <div className='row'>

              <div className='col-6'>

                <div className='mb-2'>

                  <TextField
                    required
                    type='number'
                    value={wordpress_user_id}
                    onChange={(e) => setWPID(e.target.value)}
                    label={'ID WP'}

                  />

                  <br />
                  <br />

                  <TextField
                    required
                    type='number'
                    value={ev_id}
                    onChange={(e) => setEVID(e.target.value)}
                    label={'ID EV'}
                  />


                </div>
              </div>


              <div className='col-6'>

                <div className='mb-2'>


                  <TextField
                    required
                    value={url_profile_wordpress}
                    placeholder='https://taijiquan-qigong.de/user/...'
                    onChange={(e) => setWP(e.target.value)}
                    label={'WP Link:'}
                  />

                  <br />
                  <br />


                  <TextField
                    required  
                    value={url_profile_easyverein}
                    placeholder='https://easyverein.com/app/profile/...'
                    onChange={(e) => setEV(e.target.value)}
                    label={'EV Link'}

                  />


                </div>

              </div>

            </div> */}
            <div className='text-end mt-3 mb-4'>
              <Link style={{ textDecoration: 'none' }} to={'/users/profile/' + params.id}>
                  <MainBtnCancel>{t('Buttons.1')}</MainBtnCancel>
                </Link>
              <MainBtn type='submit'>{t('Buttons.2')}</MainBtn>
            </div>
          </div>
        </div>


        {successful ?
          <Alert message={'success'} severity={'success'} />
          : <></>}
        {error ?
          <Alert message={'error'} severity={'error'} />
          : <></>}
      </form>



      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default EditUser
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Backdrop, Box, Chip, CircularProgress, Modal } from "@mui/material";
import Table from "../Points/PointsTable";
import ShowTemplate from "./ShowTemplate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Sidebar from "../Sidebar";

const Certificates = () => {
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/builders/index",
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      setData(json);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("BuilderTable.0")}</>,
            accessor: "title",
          },
          {
            Header: <>{t("BuilderTable.1")}</>,
            accessor: "color",
            Cell: ({ row }) => (
              <>
                {row.original.color != "" ? (
                  <Chip
                    size="small"
                    label={row.original.color}
                    style={{ background: row.original.color, color: "white" }}
                  />
                ) : (
                  "With image"
                )}
              </>
            ),
          },
          {
            Header: <>{t("BuilderTable.2")}</>,
            accessor: "created_at",
            Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>,
          },
          {
            Header: <>{t("BuilderTable.8")}</>,
            accessor: "subtitle",
            Cell: ({ row }) => (
              <span
                className="simple-link"
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(row.original.id)}
              >
                <VisibilityIcon className="table-icons" />
              </span>
            ),
          },
          {
            Header: t("BuilderTable.6"),
            accessor: "",
            Cell: ({ row }) => (
              <Link
                className="simple-link"
                style={{ cursor: "pointer" }}
                to={{ pathname: `/templates/edit/${row.original.id}` }}
                title="edit"
              >
                <BorderColorOutlinedIcon className="table-icons" />
              </Link>
            ),
          },
          {
            Header: t("BuilderTable.7"),
            accessor: "",
            Cell: ({ row }) => (
              <Link
                className="simple-link"
                style={{ cursor: "pointer" }}
                to={{ pathname: `/templates/clone/${row.original.id}` }}
                title="clone"
              >
                <ContentCopyOutlinedIcon className="table-icons" />
              </Link>
            ),
          },
          {
            Header: <>{t("BuilderTable.3")}</>,
            accessor: " ",
            Cell: ({ row }) => (
              <span
                className="simple-link"
                style={{ cursor: "pointer" }}
                onClick={() => handleDestroy(row.original)}
              >
                <DeleteOutlineIcon className="table-icons" />
              </span>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const handleShow = (user) => {
    setShow(true);
    setCurrent(user);
  };

  const handleDestroy = async (item) => {
    setLoading(true);
    console.log(item.id, item.archived);
    fetch(process.env.REACT_APP_API_URL + "/builders/destroy/" + item.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    }).then((response) => {
      if (response.ok) {
        console.log(response);
        fetchData();
      } else {
        console.log("response was not ok");
      }
    });
  };

  return (
    <>
      <Sidebar />
      <div className="frame">
        <br />
        <h2 className="title" style={{ marginBottom: "40px" }}>
          {t("BuilderTable.4")}:
        </h2>

        <Table columns={columns} data={data} route="/templates/options" />
      </div>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <ShowTemplate current={current} />
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Certificates;

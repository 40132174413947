import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

function Dropzone({ open, image, setDocuments, setImageUrl }) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    if (setImageUrl) {
      acceptedFiles.map((newFile) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageUrl(e.target.result);
        };
        reader.readAsDataURL(newFile);
        return newFile;
      });
    }
    setDocuments(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    if (image) {
      fetch(image)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = image.substring(image.lastIndexOf("/") + 1);
          const file = new File([blob], fileName, {
            type: blob.type,
          });
          onDrop([file]);
        })
        .catch((error) => console.log("Error fetching image:", error));
    }
  }, [image]);

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop });

  const files = acceptedFiles.map((file) => (
    <li key={file.path} className="standard-text">
      {file.path.slice(0, 15)}... - {file.size} {t("DropFileZone.0")}
    </li>
  ));

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input className="input-zone" {...getInputProps()} />
      <div className="text-center mt-4">
        {isDragActive ? (
          <p className="dropzone-content standard-text mb-0">
            {t("DropFileZone.1")}
          </p>
        ) : (
          <p className="dropzone-content standard-text mb-0">
            {t("DropFileZone.2")} <br /> {t("DropFileZone.3")}
          </p>
        )}
        <button
          type="button"
          onClick={open}
          className="simple-link"
          style={{ border: "none", background: "#FFFFFF" }}
        >
          {t("DropFileZone.4")}
        </button>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </div>
  );
}
export default Dropzone;

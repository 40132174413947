import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import MainBtn from "../UI-kit/Buttons/MainBtn";
import Checkbox from "../UI-kit/Checkbox/Checkbox";
import LoginHeader from "../UI-kit/Text/LoginHeader";
import LoginFooter from "../UI-kit/Text/LoginFooter";
import LoginIMG from "../../src/assets/images/login_bg_desktop.jpg";
import Alert from "../UI-kit/Alert/Alert";

const Login = ({}) => {
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [id, setID] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const password_confirmation = password;

    const logUser = { email, password, password_confirmation };

    const response = await fetch(
      process.env.REACT_APP_API_URL + "/sessions/create_session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(logUser),
      }
    );
    const data = await response.json();

    if (data.code == "200") {
      window.localStorage.setItem("token", data.token);
      window.localStorage.setItem("id", data.id);
      window.localStorage.setItem("email", data.email);
      setID(data.id);
      await fetchAvatar(data.id);
      window.location.href = "/home";
      setLoading(false);
    } else if (data.code == "400") {
      setError(true);

      setLoading(false);
      window.location.reload();
    } else {
      setError(true);

      setLoading(false);
      window.location.reload();
    }
  };

  const fetchAvatar = async (id) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/sessions/get_attachments/` + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    const data = await response.json();

    console.log(data);
    if (response.ok) {
      window.localStorage.setItem("avatar", data.url);
      window.location.href = "/home";
      setLoading(false);
    } else {
      //window.location.href='/home';
      setLoading(false);
    }
  };

  return (
    <>
      <section
        className="vh-100"
        style={{
          backgroundImage: "url(" + LoginIMG + ")",
          objectFit: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 d-flex justify-content-end mt-5">
              <div className="d-flex align-items-center px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5 login-frame">
                <form onSubmit={handleSubmit} style={{ width: "25rem" }}>
                  <LoginHeader />

                  <div className="mt-2">
                    <br />
                    <TextField
                      required
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="name@domain.com"
                      style={{ width: "100%" }}
                      label={"Email"}
                    />
                  </div>

                  <div className="mt-2">
                    <br />
                    <TextField
                      required
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="At least 6 characters"
                      style={{ width: "100%" }}
                      label={"Password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className="mt-4">
                    <Checkbox defaultChecked />{" "}
                    <span className="standard-text">Remember me</span>
                  </div>

                  <MainBtn
                    type="submit"
                    onClick={handleSubmit}
                    className="mt-3 mb-3"
                    style={{ width: "100%", marginLeft: "0" }}
                  >
                    Log in
                  </MainBtn>

                  <div className="d-flex justify-content-between mt-3">
                    <p
                      onClick={() =>
                        (window.location.href = "/forgot-password")
                      }
                      className="simple-link"
                      style={{ cursor: "pointer" }}
                    >
                      Forgot password?
                    </p>

                    <p className="standard-text mb-5 pb-lg-2">
                      New to BVTQ?{" "}
                      <span
                        onClick={() => (window.location.href = "/register")}
                        className="simple-link"
                        style={{ cursor: "pointer" }}
                      >
                        Sign up
                      </span>
                    </p>
                  </div>

                  {successful ? (
                    <Alert message={"success"} severity={"success"} />
                  ) : (
                    <></>
                  )}
                  {error ? (
                    <Alert
                      message={
                        "The email address or password you entered is invalid"
                      }
                      severity={"error"}
                    />
                  ) : (
                    <></>
                  )}
                </form>
              </div>
            </div>
          </div>
          <LoginFooter />
        </div>
      </section>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Login;

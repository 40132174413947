const COLORS = {
    text: '#FFFFFF',
    background: '#69C7D3',
    hoverBackground: '#55b8c5',
    navBar: '#0000001A',
    floatingBTN: '#417EF0',
    disabled: '#E6E7EA',
    fontWeight: '500',
    active:{
        text: '#50597B',
        background: '#40C9D5',
        hoverBackground: '#40C9D5'
    },
    archived:{
        text: '#50597B',
        background: '#E6E7EA',
        hoverBackground: '#E6E7EA66'
    },
    cancel: {
        text: '#40C9D5',
        background: '#FFFFFF',
        hoverBackground: '#55b8c5'
    },
    footer: {
        background: '#F7F7F7',
        opacity: '0.5'
    },
    checkbox: {
        color: '#515978'
    }
};

export default COLORS;
import React, { useMemo, useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Sidebar from '../Sidebar'
import Button from 'react-bootstrap/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const Deepl = () => {

    const translate = require("deepl");


    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataJ, setDataJ] = useState([]);

    const [language, setLanguage] = useState('');
    const [text, setText] = useState('');
    const [result, setResult] = useState('');
    const [send, setSend] = useState('');

    useEffect(() => {
        fetchData();
        fetchData1();
    }, [])

    const fetchData = async () => {

        const res = await fetch(
            'https://api-free.deepl.com/v2/languages?auth_key=bea08f17-77f5-99ad-579f-3ad80489b105:fx'
        );
        if(res.ok){
            const json = await res.json();
            setData(json);
            setLoading(false);
        }else{
            console.log('response was not ok')
            setLoading(false);
        }
  
    };

    const fetchData1 = async () => {

        const res = await fetch(
           'locales/de/translation.json'
        );
        if(res.ok){
            const json = await res.json();
            const entries = Object.entries(json);

            console.log(entries);
            setDataJ(entries);
            setLoading(false);
        }else{
            console.log('response was not ok')
            setLoading(false);
        }
  
    };

    //console.log(dataJ)


/*     translate({
        free_api: true,
        text: 'I am a text',
        target_lang: 'FR',
        auth_key: 'bea08f17-77f5-99ad-579f-3ad80489b105:fx',
    })
    .then(result => {
        console.log(result.data);
    })
    .catch(error => {
        console.error(error)
    });
 */



    const handleSubmit = async (e) => {

        e.preventDefault();
    
        setLoading(true)

        translate({
            free_api: true,
            text: text,
            target_lang: language,
            auth_key: 'bea08f17-77f5-99ad-579f-3ad80489b105:fx',
        })
        .then(result => {
            console.log(result.data);

            setResult(result.data.translations);
            console.log(result.data.translations)

        })
        .catch(error => {
            console.error(error)
        });
    
      }; 

    return (
        <>
        <Sidebar />
        <div className="frame">
            <br />
            <h2 className='title'>Translate:</h2>
            <Link  className='simple-link' style={{ textDecoration: 'none' }} to="/home">&lt;&nbsp;Homepage</Link>
            <br />
            <br />
            <div className='row' style={{width: '40%', padding: '20px', margin: 'auto'}}>
                <div className='col'>

                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dataJ}
                        getOptionLabel={(option) => option[0]}
                        onChange={(e, value ) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                                fieldValue = null;
                            } else
                            setText(value[1])

                        }} 
                        renderInput={(params) => <TextField required {...params} label="language" />}
                    />
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={data}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value ) => {
                            var fieldValue = value;
                            if (!fieldValue) {
                                fieldValue = null;
                            } else
                            setLanguage(value.language)

                        }} 
                        renderInput={(params) => <TextField required {...params} label="language" />}
                    />

                    <form onSubmit={handleSubmit} className='mt-5'>

        
                        <TextareaAutosize
                            minRows={3}
                            placeholder="Minimum 3 rows"
                            style={{width: '100%'}}
                            value={text}
                            //onChange={(e) => setText(e.target.value)}  
                        />
  

                        <div className='text-center mb-2 mt-2'>
                            <Button variant="success" type="submit">Translate</Button>
                        </div>


                    </form>

                    {result.length? result.map((item, i) =><>
                        <div className='text-center'>
                            <p>
                                Detected language:&nbsp;
                                <b>{item.detected_source_language}</b>  
                            </p>
                            <h4>translation:</h4>
                            <p>
                                    
                                {item.text}  
                            
                            </p>

                            <TextareaAutosize
                            minRows={3}
                            placeholder="Minimum 3 rows"

                            style={{width: '100%'}}
                            defaultValue={item.text}
                            value={send}
                            onChange={(e) => setSend(e.target.value)}  
                            />

                            <Button onClick={(e) => { setText(''); setResult([]);}}>New Translation</Button>

                            
                        </div> 
                    </>): <div className='text-center'>waiting for text to translate...</div>}
                </div>
            </div>    



        </div>

        </>
    )
}

export default Deepl;

import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from 'react-bootstrap/Button';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const Register = () => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordConfirmation] = useState("");
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [source, setSource] = useState('');
    const [mode, setMode] = useState('');
    const [wordpress_user_id, setWPID] = useState('');
    const [ev_id, setEVID] = useState('');
    const [birth_date, setBirth] = useState('1990-01-01');
    const [url_profile_wordpress, setWP] = useState('');
    const [url_profile_easyverein, setEV] = useState('');
    const [street_address, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [post_code, setPost] = useState('');
    const [country, setCountry] = useState('');

    const handleSubmit = async (e) => {

        e.preventDefault();

        setLoading(true)

        const newUser = { 
            email,
            password,
            password_confirmation,
            page: "0",
            synced: 0,
            first_name,
            last_name,
            email,
            source: 'BVTQ APP',
            mode,
            wordpress_user_id: 0,
            ev_id: 0,
            birth_date,
            url_profile_wordpress: "",
            url_profile_easyverein: "",
            street_address,
            city,
            state,
            post_code,
            country
        };

        if(password !== password_confirmation){
            alert("Passwords doesn't match")
            setLoading(false)

        }else{

            const response = await fetch(process.env.REACT_APP_API_URL + '/sessions/register_member', {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json", 
                    "api-key": process.env.REACT_APP_API_KEY 
                    },
                body: JSON.stringify(newUser)
                });
                const data = await response.json();
        
            if(data.code == '200'){
                window.location.href='/';
                setLoading(false)
        
                
            } else if (data.code == '400') {
        
                alert('"The email address or password you entered is invalid"')
                setLoading(false)
        
            } else {
                alert('"The email address or password you entered is invalid"')
                setLoading(false)
        
            }

        }



    
    }  





    return (
        <>
        <section className="vh-100">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">

                        <div className="px-5 ms-xl-4">
                            <span className="h1 fw-bold mb-0">BVTQ ☯</span>
                        </div>

                        <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">

                            <form onSubmit={handleSubmit}>

                                <h3 className="fw-normal mb-3 pb-3" style={{letterSpacing: '1px'}}>Register</h3>

                                <div className='row'>
                                    <div className='col-6'>

                       
                                        <TextField 
                                            required
                                            type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}    
                                            placeholder="name@domain.com" 
                                            style={{width: '100%'}}
                                            label={'email'}
                                        />

                                        <br />
                                        <br />


                                        <TextField
                                            required
                                            type={showPassword ? "text" : "password"} 
                                            onChange={(e) => setPassword(e.target.value)}  
                                            placeholder="At least 6 characters"
                                            style={{width: '100%'}}
                                            label={'password'}
                                            InputProps={{ 
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton
                                                      aria-label="toggle password visibility"
                                                      onClick={handleClickShowPassword}
                                                      onMouseDown={handleMouseDownPassword}
                                                    >
                                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                  </InputAdornment>
                                                )
                                            }}
                                        />

                                        <br />
                                        <br />

                        
                                        <TextField 
                                            required
                                            type={showPassword ? "text" : "password"} 
                                            value={password_confirmation}
                                            onChange={(e) => setPasswordConfirmation(e.target.value)}  
                                            placeholder="At least 6 characters" 
                                            style={{width: '100%'}}
                                            label={'Password Confirmation'}
                                            InputProps={{ 
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <IconButton
                                                      aria-label="toggle password visibility"
                                                      onClick={handleClickShowPassword}
                                                      onMouseDown={handleMouseDownPassword}
                                                    >
                                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                  </InputAdornment>
                                                )
                                            }}

                                        />

                                        <br />
                                        <br />

                                        <TextField
                                            required
                                            value={first_name}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            style={{width: '100%'}}
                                            label={'First Name'}
                                        />

                                        <br />
                                        <br />

                                        <TextField
                                            required
                                            value={last_name}
                                            onChange={(e) => setLastName(e.target.value)}
                                            style={{width: '100%'}}
                                            label={'Last Name'}
                                        />

                                        <br />
                                        <br />

                                        <TextField
                                            type='date'
                                            required
                                            value={birth_date}
                                            onChange={(e) => setBirth(e.target.value)}
                                            style={{width: '100%'}}
                                            label={'Birth'}

                                        />

                                    </div>


                                    <div className='col-6'>

                                        <TextField
                                            required
                                            value={street_address}
                                            onChange={(e) => setStreet(e.target.value)}
                                            style={{width: '100%'}}
                                            label={'Street'}
                                        />
                            

                                        <br />
                                        <br />

                                        <TextField
                                            required
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            style={{width: '100%'}}
                                            label={'Country'}
                                        />

                                        <br />
                                        <br />

                                        <TextField
                                            required
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                            style={{width: '100%'}}
                                            label={'State'}
                                        />

                                        <br />
                                        <br />


                                        <TextField
                                            required
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            style={{width: '100%'}}
                                            label={'City'}

                                        />

                                        <br />
                                        <br />


                                        <TextField
                                            required
                                            value={post_code}
                                            onChange={(e) => setPost(e.target.value)}
                                            style={{width: '100%'}}
                                            label={'Post Code'}

                                        />

                                        <br />
                                        <br />

                                        <h5>Type of user:</h5> 

                                        <select onChange={(e) => setMode(e.target.value)} value={mode} style={{width: '100%'}}>
                                            <option value="">Select</option>
                                            <option value="person">Person</option>
                                            <option value="organization">Organization</option>
                                        </select>


                                        {/* <div className='row'>

                                            <div className='col-6'>

                                                <div className='mb-2'>

                                                    <TextField
                                                        required
                                                        type='number'
                                                        value={wordpress_user_id}
                                                        onChange={(e) => setWPID(e.target.value)}
                                                        label={'ID WP'}

                                                    />

                                                    <br />
                                                    <br />

                                                    <TextField
                                                        required
                                                        type='number'
                                                        value={ev_id}
                                                        onChange={(e) => setEVID(e.target.value)}
                                                        label={'ID EV'}
                                                    />


                                                </div>
                                            </div>


                                            <div className='col-6'>

                                                <div className='mb-2'>


                                                    <TextField
                                                        required
                                                        value={url_profile_wordpress}
                                                        placeholder='https://taijiquan-qigong.de/user/...'
                                                        onChange={(e) => setWP(e.target.value)}
                                                        label={'WP Link:'}
                                                    />

                                                    <br />
                                                    <br />


                                                    <TextField
                                                        required  
                                                        value={url_profile_easyverein}
                                                        placeholder='https://easyverein.com/app/profile/...'
                                                        onChange={(e) => setEV(e.target.value)}
                                                        label={'EV Link'}

                                                    />


                                                </div>

                                            </div>

                                        </div> */}
                                    </div>
                                </div>


                                <div className="mt-2">


                                    <p className="small">Already have an account? <span onClick={() => window.location.href = "/"} className="simple-link" style={{cursor: 'pointer'}}>Log in</span></p>

                                    <Checkbox
                                        defaultChecked
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        style={{ color: '#38baff' }}
                                    />
                                    <span className="small ">I agree to all <a target="_blank" href='https://www.taijiquan-qigong.de/impressum/' className="blue-anchors">Terms</a> and <a target="_blank" href='https://www.taijiquan-qigong.de/datenschutzerklarung/' className="blue-anchors">Privacy Policy</a></span>
                                </div>

                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="mt-3 mb-3" 
                                    style={{width: '100%'}}

                                >
                                    Sign up
                                </Button>
                            </form>
                        </div>

                    </div>

                    <div className="col-sm-6 px-0 d-none d-sm-block">
                        <img src="https://www.taijiquan-qigong.de/wp-content/uploads/2021/12/China_2015_Wuhan_001_P7110145-1-e1644707559803.jpg" alt="Login image" className="w-100 vh-100" style={{objectFit: 'cover'}}/>
                    </div>

                </div>
            </div>
        </section>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            >
            <CircularProgress color="inherit" />
        </Backdrop>
        </>
    )
}

export default Register
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material";
import Sidebar from "../Sidebar";
import DropFileZone from "../Points/components/DropFileZone";
import DropFileZoneHeaderIamge from "../Points/components/DropFileZoneHeaderImage";
import MainBtn from "../UI-kit/Buttons/MainBtn";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import fetchApi from "../hooks/fetchApi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowTemplate from "./ShowTemplate";
import Alert from "../UI-kit/Alert/Alert";

const OptionTwo = () => {
  const { t } = useTranslation();

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [templatePreview, setTemplatePreview] = useState("");
  const [levels, setLevels] = useState([]);
  const [levelID, setLevelID] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [documents, setDocuments] = useState("");
  const [header_images, setHeaderImages] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");

  const [content_two, setContentTwo] = useState("");
  const [content_three, setContentThree] = useState("");
  const [content_four, setContentFour] = useState("");

  //text colors
  const [text_color, setTextColor] = useState("");
  const [color_description, setColorDescription] = useState("");
  const [color_alt_text, setColorAltText] = useState("");
  const [color_lvl_name, setColorLvlName] = useState("");
  const [color_lvl_subtitle, setColorLvlSubtitle] = useState("");
  const [color_alt_lvl_description, setColorLvlDescription] = useState("");

  const [image, setImage] = useState("");
  const [color, setColor] = useState("");

  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  const fetchLevels = async () => {
    setLoading(true);

    const { ok, data } = await fetchApi("/levels/index");

    if (ok) {
      setLevels(data);
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      level_id: levelID,
      title,
      subtitle,
      image,
      color,
      content: "option 2",
      content_two,
      content_three,
      content_four,
      text_color,
      color_description,
      color_alt_text,
      color_lvl_name,
      color_lvl_subtitle,
      color_alt_lvl_description,
      documents,
      header_images,
    };

    const formData = new FormData();

    for (const property in body) {
      formData.append(property, body[property]);
    }

    try {
      const resp = await axios.post(
        process.env.REACT_APP_API_URL + "/builders/create",
        formData,
        {
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      console.log(resp.data);
      setSuccessful(true);
      setTimeout(() => {
        navigate("/templates");
      }, 1000);
    } catch (err) {
      // Handle Error Here
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = () => {
    setTemplatePreview({
      dataLevel: levelID,
      typeTemplate: "option 2",
      title: subtitle,
      description: image,
      alternativeText: content_two,
      levelSubtitle: content_four,
      levelDescription: content_three,
      textColor: text_color,
      color_description,
      color_alt_text,
      color_lvl_name,
      color_lvl_subtitle,
      color_alt_lvl_description,
      headerImage: headerImageUrl,
      backgroundImage: backgroundImageUrl,
      createdDate: new Date(),
    });
    setShow(true);
  };

  useEffect(() => {
    fetchLevels();
  }, []);
  //console.log(header_images);
  //console.log(documents);
  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className="frame">
        <br />

        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title">
            {t("BuilderTable.5")}: {t("NewTemplate.15")} + Logo +{" "}
            {t("NewTemplate.21")}
          </h2>
          <div className="d-flex text-end">
            <Link style={{ textDecoration: "none" }} to="/templates">
              <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
            </Link>
            <MainBtn disabled={header_images === ""} type="submit">
              {t("Buttons.2")}
            </MainBtn>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-7">
            <div className="white-box">
              <p className="subtitle fw-bold">{t("NewTemplate.0")}</p>

              <p className="subtitle fw-bold">{t("NewTemplate.23")}</p>

              <Autocomplete
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                required
                disablePortal
                id="multiple-limit-tags"
                //value={userLevel}
                options={levels}
                getOptionLabel={(option) => option.title}
                onChange={(e, value) => {
                  var fieldValue = value;
                  if (!fieldValue) {
                    fieldValue = null;
                  } else setLevelID(value.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t("NewTemplate.20")} />
                )}
              />

              <br />

              <p className="subtitle fw-bold">{t("NewTemplate.1")}</p>

              <TextField
                required
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                label={t("NewTemplate.1")}
                style={{ width: "100%" }}
              />
            </div>

            <div className="white-box mt-2">
              <p className="subtitle fw-bold">{t("NewTemplate.2")}</p>

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">1. {t("NewTemplate.3")}</p>
                <div className="d-flex">
                  <input
                    required
                    onChange={(e) => setTextColor(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                onChange={(e) => setSubtitle(e.target.value)}
                type="text"
                label={t("NewTemplate.3")}
                style={{ width: "100%" }}
              />

              <br />
              <br />

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">2. {t("NewTemplate.15")}</p>
                <div className="d-flex">
                  <input
                    required
                    onChange={(e) => setColorDescription(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                onChange={(e) => setImage(e.target.value)}
                type="text"
                label={t("NewTemplate.15")}
                style={{ width: "100%" }}
              />

              <br />
              <br />

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">3. {t("NewTemplate.25")}</p>
                <div className="d-flex">
                  <input
                    required
                    onChange={(e) => setColorAltText(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                onChange={(e) => setContentTwo(e.target.value)}
                type="text"
                label={t("NewTemplate.25")}
                style={{ width: "100%" }}
              />

              <br />
              <br />

              <div className="d-flex justify-content-between">
                <div>
                  <p className="subtitle fw-bold mb-1">{t("NewTemplate.17")}</p>
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Automatically generated")})`}
                  </p>
                </div>
                <div className="d-flex">
                  <input
                    required
                    onChange={(e) => setColorLvlName(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">4. {t("NewTemplate.27")}</p>
                <div className="d-flex">
                  <input
                    required
                    onChange={(e) => setColorLvlSubtitle(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                onChange={(e) => setContentFour(e.target.value)}
                type="text"
                label={t("NewTemplate.27")}
                style={{ width: "100%" }}
              />

              <br />
              <br />

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">5. {t("NewTemplate.26")}</p>
                <div className="d-flex">
                  <input
                    required
                    onChange={(e) => setColorLvlDescription(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                onChange={(e) => setContentThree(e.target.value)}
                type="text"
                label={t("NewTemplate.26")}
                style={{ width: "100%" }}
              />
            </div>

            <div className="white-box mt-2">
              <div className="p-2 w-50">
                <p className="subtitle fw-bold">Logo & {t("NewTemplate.6")}</p>

                <p className="subtitle fw-bold">Logo</p>
                <div
                  className="wrapper"
                  style={{
                    height: "auto",
                  }}
                >
                  <DropFileZoneHeaderIamge
                    setHeaderImages={setHeaderImages}
                    setImageUrl={setHeaderImageUrl}
                  />

                  <p className="standard-text mt-3">{t("AddPoints.2")} </p>
                </div>

                <p className="subtitle fw-bold mt-2"> {t("NewTemplate.6")}</p>

                <div
                  className="wrapper"
                  style={{
                    height: "auto",
                  }}
                >
                  <DropFileZone
                    setDocuments={setDocuments}
                    setImageUrl={setBackgroundImageUrl}
                  />

                  <p className="standard-text mt-3">{t("AddPoints.2")} </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-5 white-box" style={{ height: "fit-content" }}>
            <div className="d-flex align-items-center">
              <h4 className="subtitle fw-bold">{t("NewTemplate.11")}</h4>
              <span
                className="simple-link"
                style={{ cursor: "pointer", marginLeft: "20px" }}
                onClick={() => handleShow()}
              >
                <VisibilityIcon className="table-icons" />
              </span>
            </div>

            <div className="d-flex justify-content-center flex-column align-items-center text-center template-preview-box">
              <div className="template-preview-inner-box mt-4 w-75">
                <p className="mt-2">1. {t("NewTemplate.12")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">2. {t("NewTemplate.15")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">LOGO</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("NewTemplate.16")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">3. {t("NewTemplate.25")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("NewTemplate.17")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">4. {t("NewTemplate.27")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("NewTemplate.18")}</p>
              </div>

              <div className="d-flex  justify-content-between w-60">
                <div className="template-preview-inner-box mt-3 mb-4 me-1">
                  <p className="m-2">{t("NewTemplate.19")}</p>
                </div>
                <div className="template-preview-inner-box mt-3 mb-4 ms-1">
                  <p className="m-2">{t("NewTemplate.9")}</p>
                </div>
              </div>
            </div>
            {/* SECOND PAGE */}
            <hr />
            <div className="d-flex justify-content-center flex-column align-items-center text-center template-preview-box">
              <div className="template-preview-inner-box mt-4 w-75">
                <p className="mt-2">5. {t("NewTemplate.26")}</p>
              </div>

              <div className="template-preview-inner-box mt-4 w-75">
                <p className="mt-2">{t("NewTemplate.14")}</p>
              </div>

              <div className="template-preview-inner-box mt-3 mb-4 w-50">
                <p className="mt-2">{t("NewTemplate.24")}</p>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <ShowTemplate templateData={templatePreview} />
        </Box>
      </Modal>

      {successful && (
        <Alert
          message={t("Messages.The operation was successful")}
          severity={"success"}
        />
      )}
      {error && (
        <Alert
          message={t("Messages.Something went wrong, please try again")}
          severity={"error"}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default OptionTwo;

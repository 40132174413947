import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Pdf from "react-to-pdf";
import Button from 'react-bootstrap/Button';
import hero from './page1.png'

const ref = React.createRef();

const QigongC = ({ current, firstName, lastName, template}) => {

  console.log(template)
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState([]);
  const [levelName, setLevelName] = useState('');
  const [created, setCreated] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [loading, setLoading] = useState(true);


  useEffect(() => {
      fetchData();
  }, [])

  const fetchData = async () => {

    var requestOptions = { headers: { "api-key": process.env.REACT_APP_API_KEY } }

    const res = await fetch(
        process.env.REACT_APP_API_URL + '/certificates/show/' + current, requestOptions
    );
    if(res.ok){
        const json = await res.json();
        console.log(json)
        let subCategoriesArray = JSON.parse(json.content);
        setLevelName(json.level);
        setCreated(json.created_at);
        setDueDate(json.due);
        setData(subCategoriesArray);
        setLoading(false);
    }else{
        console.log('response was not ok')
        setLoading(false);
    }

  };

  const backgroundColorPDF = template.color.toString();

  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [6,9]
  };

  return (<>
    <>
    
        <div className='row' style={{ width: '100%', margin: 'auto'}} >
             <div className='col'>
                <div className='pdf-certificate' ref={ref}  /*style={{backgroundColor: `${backgroundColorPDF}` }}*/ >
                    {loading ? <></> : <>

                        {/* <img id="background" src={hero} style={{position: 'absolute', left: '0px', top: '0px', width: "595px", height: "841px"}}/>*/}                
                        <img id="background" src={hero} style={{ left: '0px', top: '0px', width: "595px", height: "841px"}}/>

                        <div className="txt" style={{position: 'absolute' , left:'107px', top: '39px'}}><span id="f1" style={{fontSize:'100px',verticalAlign:'baseline',color:'rgba(250,184,31,1)'}}>Urkunde</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'72px' , top: '170px'}}><span id="f2" style={{fontSize:'15px',verticalAlign:'baseline',color:'rgba(55,38,37,1)'}}>der Bundesvereinigung für Taijiquan und Qigong Deutschland e.V. –</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'251px', top:'150px'}}><span id="f2" style={{fontSize:'15px',verticalAlign:'baseline',color:'rgba(55,38,37,1)'}}>Das Netzwerk</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'200px', top:'360px'}}><span id="f5" style={{fontSize:'23px',verticalAlign:'baseline',color:'rgba(68,45,38,1)'}}>{firstName} {lastName}</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'131px', top:'396px'}}><span id="f1" style={{fontSize:'12px',verticalAlign:'baseline',color:'rgba(55,38,37,1)'}}>erfüllt die Anforderungen der Ausbildungsleitlinien der BVTQ e.V.</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'216px', top:'410px'}}><span id="f1" style={{fontSize:'12px',verticalAlign:'baseline',color:'rgba(55,38,37,1)'}}>und erhält die Anerkennung für</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'230px', top:'439px'}}><span id="f2" style={{fontSize:'34px',verticalAlign:'baseline',color:'rgba(250,184,31,1)'}}>LIZENZ C</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'144px', top:'477px'}}><span id="f5" style={{fontSize:'23px',verticalAlign:'baseline',color:'rgba(242,191,40,1)'}}>– Qigong Kursleiterin Praktiker –</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'268px', top:'532px'}}><span id="f2" style={{fontSize:'10px',verticalAlign:'baseline',color:'rgba(55,38,37,0)'}}>-QUALITÄTSSI</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'261px', top:'614px'}}><span id="f2" style={{fontSize:'9px',verticalAlign:'super',color:'rgba(55,38,37,0)'}}>IJIQUAN </span><span id="f2" style={{fontSize: '9px',verticalAlign:'baseline',color:'rgba(55,38,37,0)'}}>· </span><span id="f2" style={{fontSize:'9px',verticalAlign:'baseline',color:'rgba(55,38,37,0)'}}>LIZENZ</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'108px', top:'682px'}}><span id="f6" style={{fontSize:'11px',verticalAlign:'baseline',color:'rgba(68,45,38,1)'}}>Geisenheim, 00.00.2021</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'145px', top:'704px'}}><span id="f1" style={{fontSize:'12px',verticalAlign:'baseline',color:'rgba(55,38,37,1)'}}>Ort, Datum</span></div>
                        <div className="txt" style={{position: 'absolute' , left:'398px', top:'704px'}}><span id="f1" style={{fontSize:'12px',verticalAlign:'baseline',color:'rgba(55,38,37,1)'}}>Vorstand</span></div>

                    </>} 
                </div>
            </div>              
        </div>

        <div className='row'>
            <div className='text-center'>
            <Pdf targetRef={ref} filename='Certificate.pdf' options={options}>
            {({ toPdf }) => <Button variant="success"  onClick={toPdf}>Download</Button>}
            </Pdf>
            </div>
        </div>

    </>

</>)
}
export default QigongC;
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";

const LoadLevels = ({ levels, level, setLevel, setData, setCategoryId }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-2">
        <p className="subtitle fw-bold">{t("LoadLevels.0")}*</p>
        <Autocomplete
          required
          disablePortal
          id="multiple-limit-tags"
          options={levels}
          value={level}
          getOptionLabel={(option) => option.title}
          onChange={(e, value) => {
            let fieldValue = value;
            if (!fieldValue) {
              fieldValue = null;
              setLevel(fieldValue);
              setData([]);
            } else {
              setLevel(value);
              setData(JSON.parse(value.subcategories));
              setCategoryId(value.category_id);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Choose levels" />
          )}
        />
      </div>
    </>
  );
};

export default LoadLevels;

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Pdf from "react-to-pdf";
import Button from 'react-bootstrap/Button';
import { RichTextEditor } from '@mantine/rte';

const ref = React.createRef();

const CompleteCertificate = ({ current, firstName, lastName, createdAt, due, template}) => {

  //console.log(template)
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState([]);
  const [levelName, setLevelName] = useState('');
  const [created, setCreated] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [modifiedRichText, setModifiedRichText] = useState();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
      fetchData();
      handleReplaceVariables();
  }, [])

  const fetchData = async () => {

    var requestOptions = { headers: { "api-key": process.env.REACT_APP_API_KEY } }

    const res = await fetch(
        process.env.REACT_APP_API_URL + '/certificates/show/' + current, requestOptions
    );
    if(res.ok){
        const json = await res.json();
        //console.log(json)
        let subCategoriesArray = JSON.parse(json.content);
        setLevelName(json.level);
        setCreated(json.created_at);
        setDueDate(json.due);
        setData(subCategoriesArray);
        setLoading(false);
    }else{
        console.log('response was not ok')
        setLoading(false);
    }

  };

  const backgroundColorPDF = template.color.toString();

  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [4,2]
  };


    const certificateMetaData = [
        {"label": "Firstname", "shortcode": "%first_name%", "parameter": "first_name"},
        {"label": "Lastname", "shortcode": "%last_name%", "parameter": "last_name"},
        {"label": "Date of issue", "shortcode": "%date_of_issue%", "parameter": "created_at"},
        {"label": "Due date", "shortcode": "%due_date%", "parameter": "due"}
    ];


    const backendResponse = {
        "first_name": firstName,
        "last_name": lastName,
        "due": due,
        "created_at": createdAt
    }

    const handleReplaceVariables = async () => {

        setLoading(true);
    
        let modifiedText = await  template.content;

        certificateMetaData.map((item, i) => {
            modifiedText = modifiedText.replaceAll(item.shortcode, backendResponse[item.parameter])
        })

        console.log(modifiedText)
        setModifiedRichText(modifiedText);

        setLoading(false);

    };

  return (<>
    <>
    
        <div className='row' style={{ width: '100%', margin: 'auto'}} >
            <div className='col'>
                <div className='pdf-certificate' ref={ref}  /*style={{backgroundColor: `${backgroundColorPDF}` }}*/ style={{background: 'linear-gradient('+`${backgroundColorPDF}`+', #FFFFFF)' }} >
                    {loading ? <></> : <>
                        
                    <div className='text-center'> 

                        <h2>{template.subtitle}</h2>

                    </div>

                    <div className='text-center' style={{marginTop: '10px'}}> 
                        <h4> {levelName} </h4> 
                    </div>

                    <div className='text-center' style={{marginTop: '10px'}}> 
                        <RichTextEditor 
                        value={modifiedRichText} 
                        readOnly
                        style={{background:'inherit', border: 'none' }}
                        />  


                    </div>

                    <div className='text-center' style={{marginTop: '10px'}}>
                        {data.map((item, i) => 
                            <p key={i}>
                                <span>{item.name}</span>: <span>{item.user_points} points</span>
                            </p>
                        )}
                    </div>


                    <div className='text-center' style={{marginTop: '10px'}}>
                    <h5>{template.image}</h5>
                    </div>


                    </>} 
                </div>
            </div>              
        </div>

        <div className='row'>
            <div className='text-center'>
            <Pdf targetRef={ref} filename='Certificate.pdf' options={options}>
            {({ toPdf }) => <Button variant="success"  onClick={toPdf}>Download</Button>}
            </Pdf>
            </div>
        </div>

    </>

</>)
}
export default CompleteCertificate;
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import MainBtnCancel from "../Buttons/MainBtnCancel";
import MainBtn from "../Buttons/MainBtn";

const SwitchRole = ({
  administratorUser,
  title,
  roles,
  setRoles,
  isLoading,
  setShow,
  handleEditRole,
}) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    if (!isEdit) {
      setIsEdit(true);
    }
  };

  const handleChangeSwitch = (role) => {
    if (isEdit && !roles[role]) {
      let newRoles = {
        owner: false,
        admin: false,
        secretary: false,
        member: false,
      };
      newRoles[role] = !roles[role];
      setRoles(newRoles);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            minWidth: "410px",
            minHeight: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "DM Sans, sans-serif",
                color: "#303857",
                mr: 1,
              }}
            >
              {title}
            </Typography>
            {(administratorUser.owner || administratorUser.admin) && (
              <BorderColorOutlinedIcon
                className="table-icons"
                onClick={() => handleEdit()}
              />
            )}
          </Box>
          <FormGroup
            aria-label="position"
            row
            sx={{ backgroundColor: "#F7F7F7", p: "20px" }}
          >
            {Object.keys(roles).map((role) => (
              <FormControlLabel
                key={role}
                value="top"
                control={
                  <Switch
                    checked={roles[role]}
                    onChange={() => handleChangeSwitch(role)}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#69C7D3",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "#69C7D3",
                      },
                    }}
                  />
                }
                label={role.replace(/\b\w/g, (letter) => letter.toUpperCase())}
                labelPlacement="top"
              />
            ))}
          </FormGroup>
          {isEdit && (
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "38px" }}
            >
              <MainBtnCancel
                onClick={() => setShow(false)}
                style={{ maxWidth: "100px" }}
              >
                {t("Buttons.1")}
              </MainBtnCancel>
              <MainBtn
                onClick={() => handleEditRole()}
                style={{ maxWidth: "100px" }}
              >
                {t("Buttons.2")}
              </MainBtn>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default SwitchRole;

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

const PreviewCategory = ({ current }) => {

    const { t, i18n } = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoryName, setCategoryName] = useState('');


    useEffect(() => {
        fetchData();
    }, [current])

    const fetchData = async () => {

        var requestOptions = { headers: { "api-key": process.env.REACT_APP_API_KEY } }

        const res = await fetch(
            process.env.REACT_APP_API_URL + '/categories/show/' + current, requestOptions
        );
        if(res.ok){
            const json = await res.json();
            console.log(json);
            let subCategoriesArray = JSON.parse(json.subcategories)
            setCategoryName(json.name)
            setData(subCategoriesArray);
            setLoading(false);

        }else{
            console.log('response was not ok')
        }    

     
    }



    return (
        <div>
            {loading ? <>...</> : <>
                <span className='subtitle'>
                    {t('PreviewCategory.0')} Nº {current}&nbsp;&gt;&nbsp;
                </span>
                <span className='subtitle-light'>
                    {categoryName}
                </span>

                <hr />

                <div className='row'>
                    <div className='col'>

                        {data.map((item, i) => 
                            
                            <>
                                {item.belongs != 0 ? 
                                <div>
                                    <p style={{ marginLeft: (String(item.nesting * 25) + 'px') }}>
                                            
                                        <b>  {item.name}  </b>
                    
                                    </p>
                                    </div> : <div>
                                    <p>
                                        
                                        <b> {item.name}</b>
                    
                                    </p>
                                </div>}
                            </>
                            
                        )}

                    </div>

                </div>

            </>}
        </div>
    )
}

export default PreviewCategory;

import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Backdrop, Box, CircularProgress, Chip, Modal } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import TableUsers from "../../Points/PointsTable";
import ShowCertificate from "../../Certificates/ShowCertificates";
import fetchApi from "../../hooks/fetchApi";
import Avatar from "../../assets/images/users_image.jpg";
import PeopleAltIcon from "../../assets/icons/zertiassist_iconos_user_24px.svg";
import LevelTable from "../../Points/PointsTable";

const CertificateData = ({ id, firstname, lastname, mode }) => {
  // redux
  const { user: userRedux } = useSelector((state) => state.user);
  // translation
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;
  // utils
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  // data
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(null);
  const [activeLevels, setActiveLevels] = useState([]);

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("CertificatesTable.1")}</>,
            accessor: "updated_at",
            Cell: ({ row }) => (
              <>
                {" "}
                <Chip
                  size="small"
                  label={row.original.level}
                  style={{ marginBottom: "3px" }}
                />{" "}
                {row.original.first_name} {row.original.last_name}{" "}
                {row.original.created_at.slice(0, 10)} / {row.original.due}{" "}
              </>
            ),
          },
          {
            Header: <>{t("CertificatesTable.2")}</>,
            accessor: "level",
            Cell: ({ row }) => (
              <>
                <Chip
                  size="small"
                  label={row.original.level}
                  style={{ marginBottom: "3px" }}
                />{" "}
                <br />{" "}
              </>
            ),
          },
          {
            Header: <>{t("CertificatesTable.3")}</>,
            accessor: "due",
          },
          {
            Header: <>{t("CertificatesTable.4")}</>,
            accessor: "created_at",
            Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>,
          },
          {
            Header: "Info",
            Cell: ({ row }) => (
              <span
                className="simple-link"
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(row.original.id)}
              >
                <VisibilityIcon className="table-icons" />
              </span>
            ),
          },
          {
            Header: <>{t("CertificatesTable.5")}</>,
            accessor: "level_id",
            Cell: ({ row }) => (
              <span
                className="simple-link"
                style={{ cursor: "pointer" }}
                onClick={() => handlePDF(row.original)}
              >
                <SimCardDownloadIcon className="table-icons" />
              </span>
            ),
          },
          {
            Header: (
              <>{userRedux?.admin ? <>{t("CertificatesTable.6")}</> : <></>}</>
            ),
            accessor: "name",
            Cell: ({ row }) => (
              <>
                {userRedux?.admin ? (
                  <span
                    className="simple-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDestroy(row.original)}
                  >
                    <DeleteOutlineIcon className="table-icons" />
                  </span>
                ) : (
                  <></>
                )}
              </>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const columnsLevels = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
            Cell: ({ row }) => <>{row.original.id}</>,
          },
          {
            Header: <>{t("LevelsTable.0")}</>,
            accessor: "title",
          },
          {
            Header: <>{t("LevelsTable.1")}</>,
            accessor: "expiry_year",
            Cell: ({ row }) => (
              <>
                {row.original.expiry_year}-{row.original.expiry_month}-
                {row.original.expiry_day}{" "}
              </>
            ),
          },
          {
            Header: <>{t("LevelsTable.2")}</>,
            accessor: "created_at",
            Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>,
          },
          {
            Header: <>{t("LevelsTable.3")}</>,
            accessor: "archive",
            Cell: (e) =>
              e.value == true ? <>{t("Tables.No")}</> : <>{t("Tables.Yes")}</>,
          },
          {
            Header: <></>,
            accessor: "expiry_month",
            Cell: ({ row }) => (
              <Link
                className="simple-link"
                style={{ cursor: "pointer" }}
                to={`/levels/show/${row.original.id}`}
                state={{ userID: id }}
                title="show"
              >
                <VisibilityIcon className="table-icons" />
              </Link>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const fetchData = async () => {
    const { ok, data } = await fetchApi("/certificates/index");
    if (ok) {
      let certificates = data.filter((user) => user.user == id);
      setData(certificates);
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const fetchLevelsData = async () => {
    const { ok, data } = await fetchApi(`/users/show/${id}`);
    if (ok) {
      if (data.current !== null) {
        let levels = JSON.parse(data.current);
        let activeLevels = levels.filter((level) => !level.archive);
        setActiveLevels(activeLevels);
      }
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const handleShow = (user) => {
    setShow(true);
    setCurrent(user);
  };

  const handlePDF = async (item) => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL +
        "/certificates/generate_certificate/" +
        item.id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    ).then((response) => {
      if (response.ok) {
        //console.log(response);
        setLoading(false);
        window.location.href = response.url;
      } else {
        console.log("response was not ok");
        alert("something went wrong, try again...");
        window.location.reload();
      }
    });
  };

  const handleDestroy = async (item) => {
    setLoading(true);
    console.log(item.id, item.archived);
    fetch(process.env.REACT_APP_API_URL + "/certificates/destroy/" + item.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    }).then((response) => {
      if (response.ok) {
        console.log(response);
        fetchData();
      } else {
        console.log("response was not ok");
      }
    });
  };

  useEffect(() => {
    fetchData();
    fetchLevelsData();
  }, []);

  return (
    <>
      <div className="row h-100 px-2">
        <div
          className="col-4 white-box"
          style={{
            backgroundImage: "url(" + Avatar + ")",
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div className="px-3" style={{ marginTop: "230px" }}>
            <div className="col">
              <p className="subtitle fw-bold">
                <img
                  className="sidebar-icons"
                  src={PeopleAltIcon}
                  alt="PeopleAltIcon"
                />{" "}
                {firstname} {lastname}
              </p>
              <p className="subtitle fw-bold">
                <img
                  className="sidebar-icons"
                  src={PeopleAltIcon}
                  alt="PeopleAltIcon"
                />{" "}
                {mode}
              </p>
            </div>
          </div>
        </div>

        <div className="col-8">
          <TableUsers columns={columns} data={data} />
        </div>
      </div>

      <div className="mt-5">
        <div className="d-flex justify-content-between">
          <h4>{t("UserProfile.Your current certifications")}</h4>
          <div className="d-flex">
            <p className="subtitle fw-bold">{t("UserProfile.Assign level")}</p>
            <Link
              className="simple-link"
              style={{ cursor: "pointer" }}
              to={{ pathname: `/users/assign/${id}` }}
              state={{ userID: id }}
            >
              <StarBorderIcon className="table-icons" />
            </Link>
          </div>
        </div>
        <hr />
        {!loading && <LevelTable columns={columnsLevels} data={activeLevels} />}
      </div>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <ShowCertificate current={current} />
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CertificateData;

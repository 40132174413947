import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Sidebar from "./../Sidebar";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import OptionOne from "../../src/assets/images/templateOptionOne.png";
import OptionTwo from "../../src/assets/images/templateOptionTwo.png";
import "../App_template.css";
const Home = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Sidebar />
      <div className="frame vh-100">
        <br />

        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title">{t("BuilderTable.5")}</h2>
        </div>

        <hr />

        <div className="d-flex flex-column flex-lg-row">
          <Link
            to="/templates/options/1"
            style={{ textDecoration: "none", color: "#303857" }}
          >
            <div
              className="rounded px-3 me-2 pt-3 pb-2 template-options"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <img
                src={OptionOne}
                style={{ height: "136px", width: "257px" }}
              />
              <p>
                Description + Stamp
                <br />
                background image
              </p>
            </div>
          </Link>
          <Link
            to="/templates/options/2"
            style={{ textDecoration: "none", color: "#303857" }}
          >
            <div
              className="rounded px-3 me-2 pt-3 pb-2 template-options"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <img
                src={OptionTwo}
                style={{ height: "136px", width: "257px" }}
              />
              <p>
                Description + logo
                <br /> stamp + background image
              </p>
            </div>
          </Link>
          <Link
            to="/templates/options/3"
            style={{ textDecoration: "none", color: "#303857" }}
          >
            <div
              className="rounded px-3 me-2 pt-3 pb-2 template-options"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <img
                src={OptionTwo}
                style={{ height: "136px", width: "257px" }}
              />
              <p>
                Description + logo
                <br />
                stamp + background color
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;

import React from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Users from "./Home/Users";
import Certificates from "./Home/Certificates";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Sidebar />

      <br />

      <div className="frame">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col md={12} lg={4}>
              <h2 className="title">{t("Homepage.0")}</h2>
            </Col>

            <Col className="d-flex">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="first" className="button-gray">
                    {t("Home.Members")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className="button-gray">
                    {t("Home.Certification")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Users />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Certificates />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Mapper from "./MapperCategories";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Sidebar from "../Sidebar";
import MainBtn from "../UI-kit/Buttons/MainBtn";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import Avatar from "../assets/images/users_image.jpg";
import ZertiHelpComponent from "../UI-kit/ZertiHelpComponent/ZertiHelpComponent";
import Alert from "../UI-kit/Alert/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditCategories = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  let navigate = useNavigate();

  const [data, setData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(true);
  const [pointer, setPointer] = useState();
  const [nameHelper, setNameHelper] = useState("");

  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchData();
  }, [nameHelper]);

  const fetchData = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/categories/show/" + params.id,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      let subCategoriesArray = JSON.parse(json.subcategories);
      setData(subCategoriesArray);
      setPointer(data.length + 1);
      setCategoryName(json.name);
      setNameHelper(json.name);
      setLoading(false);
    } else {
      console.log("response was not ok");
      setLoading(false);
    }
  };

  const nuevo = {
    name: "Subcategory" + pointer,
    key: pointer,
    belongs: 0,
    nesting: 0,
    sub: 0,
    visible: true,
    points: 0,
    user_points: 0,
  };

  const handleAdd = (belongs, nesting) => {
    // let myData = [...data];
    let myData = data.filter((e) => e.visible);
    if (belongs !== null) {
      let nuevo_with_belongs = nuevo;
      nuevo_with_belongs.belongs = belongs;
      nuevo_with_belongs.nesting = nesting + 1;
      myData.push(nuevo_with_belongs);
    } else {
      myData.push(nuevo);
    }

    handleIncrementPointer();

    Array.prototype.insert = function (index, item) {
      this.splice(index, 0, item);
    };

    let arraySubs = myData.filter((sub) => sub.belongs == 0);

    myData.forEach((sub) => {
      let subsubcategories = myData.filter(
        (subsub) => subsub.belongs == sub.key
      );

      if (subsubcategories.length != 0) {
        let indexArraySubs = arraySubs.findIndex(
          (indexSub) => indexSub.key == sub.key
        );

        if (indexArraySubs >= 0) {
          subsubcategories.forEach((subsub, index) => {
            let position = index + 1;
            let finalPosition = indexArraySubs + position;

            arraySubs.insert(finalPosition, subsub);
          });
        }
      }
    });

    let groups = myData.filter((item) => item.belongs == belongs);
    let groupNumber = groups.length;
    myData[arraySubs.length - 1].sub = groupNumber;

    setData(arraySubs);
  };

  const handleIncrementPointer = () => {
    var currentPointer = pointer;
    currentPointer = currentPointer + 1;
    setPointer(currentPointer);
    //console.log(currentPointer);
  };

  const handleRename = (e, i) => {
    let currentData = [...data];
    //console.log(currentData[i]);
    currentData[i].name = e.target.value;
    setData(currentData);
  };

  const handleShow = (i) => {
    let currentData = [...data];
    const subcategoryToHide = currentData[i].key;
    const nestedCategories = currentData.filter(
      (e, index) => index > i && e.belongs === currentData[i].belongs
    );

    currentData.forEach((sub) => {
      if (nestedCategories.length !== 0) {
        nestedCategories.forEach((nestedCat) => {
          console.log(sub.key);
          console.log(nestedCat.key);
          if (sub.key === nestedCat.key && sub.belongs !== 0) {
            console.log("entre");
            console.log("sub sub", sub.sub);
            sub.sub = sub.sub - 1;
          }
        });
      }

      if (sub.key === subcategoryToHide) {
        sub.visible = false;
        return;
      }

      if (sub.belongs === subcategoryToHide) {
        sub.visible = false;
        currentData.forEach((nestedSub) => {
          if (nestedSub.belongs === sub.key) {
            nestedSub.visible = false;
          }
        });
      }
    });
    setData(currentData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let filterData = data.filter((item) => {
      return item.visible === true;
    });

    setLoading(true);

    const body = {
      name: categoryName,
      subcategories: JSON.stringify(filterData),
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(body),
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + "/categories/update/" + params.id,
        requestOptions
      );
      if (response.ok) {
        let details = await response.json();
        console.log(details);
        setSuccessful(true);
        setTimeout(() => {
          navigate("/categories");
        }, 1000);
      } else {
        console.log("error");
        setError(true);
      }
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  console.log("final", data);
  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className="frame">
        <br />
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title">{t("EditCategories.0")}:</h2>
          <div className="text-end">
            <Link style={{ textDecoration: "none" }} to="/categories">
              <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
            </Link>
            <MainBtn type="submit">{t("Buttons.2")}</MainBtn>
          </div>
        </div>

        <hr />

        {loading ? (
          <></>
        ) : (
          <>
            <div className="row px-2">
              <div
                className="col-lg-4 white-box"
                style={{
                  backgroundImage: "url(" + Avatar + ")",
                  objectFit: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              >
                <div className="px-3 py-2" style={{ marginTop: "60%" }}>
                  <p
                    className="subtitle fw-bold"
                    style={{ marginBottom: "5px" }}
                  >
                    {t("EditCategories.3")} *
                  </p>
                  <TextField
                    required
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    label={t("EditCategories.4")}
                    style={{ width: "100%" }}
                  />
                  <Button
                    style={{ marginTop: "32px", borderRadius: "20px" }}
                    disabled={categoryName.length === 0}
                    onClick={() => handleAdd(null, null)}
                  >
                    + {t("Buttons.0")}
                  </Button>
                </div>
              </div>

              <div className="col-lg-8 mb-2 mb-lg-0">
                <Mapper
                  data={data}
                  handleRename={handleRename}
                  handleShow={handleShow}
                  handleAdd={handleAdd}
                />
              </div>
            </div>
          </>
        )}
      </form>

      {successful && (
        <Alert
          message={t("Messages.The operation was successful")}
          severity={"success"}
        />
      )}
      {error && (
        <Alert
          message={"Messages.Something went wrong, please try again"}
          severity={"error"}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default EditCategories;

import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import COLORS from './UI-kit/Constants';

export default function App() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-left fixed-bottom'>
        <div className='text-center p-3' style={{ backgroundColor: `${COLORS.footer.background}`, opacity:  `${COLORS.footer.opacity}`}}>
          <div className='text-start' style={{paddingLeft: '195px'}}>
            {new Date().getFullYear()} Copyright &copy;{' '} Accentoris AG | Gebaut durch 
            <a className='text-dark simple-link' href='http://www.digitaleszeugs.de' target="_blank" style={{textDecoration: 'none'}}>
                &nbsp;Digitales Zeugs
            </a>
          </div>
        </div>
    </MDBFooter>
  );
}


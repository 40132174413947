import * as React from "react";
import { Link } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import COLORS from "../Constants";

export default function FloatingActionButtons({ route, state }) {
  return (
    <>
      {route && (
        <Link to={route} state={state && state}>
          <Fab
            aria-label="add"
            sx={{
              background: `${COLORS.floatingBTN}`,
              color: "#FFFFFF",
              marginLeft: "10px",
            }}
          >
            <AddIcon />
          </Fab>
        </Link>
      )}
    </>
  );
}

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import COLORS from '../Constants';

const MainBtn = styled(Button)({
  background: `${COLORS.background}`,
  border: 0,
  borderRadius: '4px',
  color: `${COLORS.text}`,
  height: '42px',
  padding: '0 30px',
  textTransform: 'uppercase',
  marginLeft: '5px',
  '&:hover': {
    background: `${COLORS.hoverBackground}`,
  },
  ":disabled": {
    background: `${COLORS.disabled}`,
    color: '#FFFFFF'
  }
});


export default MainBtn;
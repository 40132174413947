import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import fetchApi from "../../hooks/fetchApi";
import MainBtn from "../../UI-kit/Buttons/MainBtn";
import Avatar from "../../assets/images/users_image.jpg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PeopleAltIcon from "../../assets/icons/zertiassist_iconos_user_24px.svg";
import LocationIcon from "../../assets/icons/zertiassist_iconos_map_pin_24px.svg";
import SecurityIcon from "../../assets/icons/zertiassist_iconos_security_24px.svg";
import PencilIcon from "../../assets/icons/zertiassist_iconos_edit_24px.svg";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const ProfileData = ({ id }) => {
  const { t } = useTranslation();

  // Redux
  const { user: userRedux } = useSelector((state) => state.user);

  // If user is an member, check if it's the same ID or redirect to home
  if (userRedux?.member) {
    if (userRedux?.user_id != id) {
      window.location.href = "/home";
    }
  }

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);

  // User Roles
  const [role, setRole] = useState(0);
  const [owner, setOwner] = useState();
  const [admin, setAdmin] = useState();
  const [member, setMember] = useState();
  const [secretary, setSecretary] = useState();

  useEffect(() => {
    fetchData();
    fetchDataRole();
  }, []);

  const fetchData = async () => {
    const { ok, data } = await fetchApi("/users/show/" + id);

    if (ok) {
      //console.log(data);
      setUser(data);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const fetchDataRole = async () => {
    const { ok, data } = await fetchApi("/users/show_profile/" + id);

    if (ok) {
      if (
        data.admin.owner &&
        !data.admin.admin &&
        !data.admin.secretary &&
        !data.admin.member
      ) {
        setRole(3);
      } else if (
        !data.admin.owner &&
        data.admin.admin &&
        !data.admin.secretary &&
        !data.admin.member
      ) {
        setRole(2);
      } else if (
        !data.admin.owner &&
        !data.admin.admin &&
        data.admin.secretary &&
        !data.admin.member
      ) {
        setRole(1);
      } else if (
        !data.admin.owner &&
        !data.admin.admin &&
        !data.admin.secretary &&
        data.admin.member
      ) {
        setRole(0);
      }
    } else {
      console.log("response was not ok");
      //setLoading(false);
    }
  };

  return (
    <>
      <div className="row h-100 px-2">
        <div
          className="col-6 col-lg-4 white-box"
          style={{
            backgroundImage: "url(" + Avatar + ")",
            objectFit: "cover",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div className="px-3" style={{ marginTop: "230px" }}>
            <p className="subtitle fw-bold">
              <img
                className="sidebar-icons"
                src={PeopleAltIcon}
                alt="PeopleAltIcon"
              />{" "}
              1. {t("UserProfile.Personal Information")}
            </p>

            <div className="col">
              <p className="subtitle fw-bold">
                {t("UserProfile.First Name")}:{" "}
                <span className="subtitle fw-normal">{user.first_name}</span>
              </p>
              <p className="subtitle fw-bold">
                {t("UserProfile.Last Name")}:{" "}
                <span className="subtitle fw-normal">{user.last_name}</span>
              </p>
              <p className="subtitle fw-bold">
                {t("UserProfile.Date of birth")}:{" "}
                <span className="subtitle fw-normal">
                  {new Date(user.birth_date).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    timeZone: "UTC",
                  })}
                </span>
              </p>
              <p className="subtitle fw-bold">
                {t("UserProfile.Type of User")}:{" "}
                <span className="subtitle fw-normal">{user.mode}</span>
              </p>
              {user.ev_id == 0 ? (
                <></>
              ) : (
                <p
                  className="subtitle fw-bold"
                  style={{ marginBottom: "15px" }}
                >
                  easyVerein ID:{" "}
                  <span className="subtitle fw-normal">{user.ev_id}</span>
                </p>
              )}

              {user.url_profile_easyverein == "" ? (
                <></>
              ) : (
                <a
                  className="simple-link"
                  href={`${user.url_profile_easyverein}`.replace(
                    "https//",
                    "wwww"
                  )}
                  target="_blank"
                >
                  Link to easyVerein profile
                </a>
              )}
              <br />
              {user.url_profile_wordpress == "" ? (
                <></>
              ) : (
                <a
                  className="simple-link"
                  href={`${user.url_profile_wordpress}`.replace(
                    "https//",
                    "wwww"
                  )}
                  target="_blank"
                >
                  Link to WordPress profile
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="col-6 col-lg-8 ps-4">
          <div className="row white-box">
            <p className="subtitle fw-bold">
              <img
                className="sidebar-icons"
                src={LocationIcon}
                alt="LocationIcon"
              />{" "}
              2. {t("UserProfile.Address")}
            </p>

            <div className="col ps-3">
              <p className="subtitle">
                {user.street_address} {user.state} {user.city} {user.country}
              </p>
              <p className="subtitle fw-bold">
                {t("UserProfile.Post Code")}:{" "}
                <span className="subtitle fw-normal">{user.post_code}</span>
              </p>
            </div>
          </div>

          <div className="row white-box mt-3">
            <p className="subtitle fw-bold">
              <img
                className="sidebar-icons"
                src={SecurityIcon}
                alt="SecurityIcon"
              />
              3. {t("UserProfile.Security & login")}
            </p>

            <div className="col ps-3">
              <p className="subtitle fw-bold">
                Email: <span className="subtitle fw-normal">{user.email}</span>
              </p>
            </div>
          </div>

          <div className="row white-box mt-3">
            <p className="subtitle fw-bold">
              <img
                className="sidebar-icons"
                src={PencilIcon}
                alt="PencilIcon"
              />
              4. {t("UserProfile.Additional Data")}
            </p>

            <div className="col px-4">
              <InputLabel className="mt-1" id="demo-simple-select-label">
                {t("UserProfile.Role")}
              </InputLabel>
              <br />
              <Select
                defaultValue=" "
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //onChange={(e) => handleRoleChange(e)}
                value={role}
                style={{ width: "200px" }}
                label=" "
                disabled
              >
                <MenuItem value={0}>Member</MenuItem>
                <MenuItem value={1}>Secretary</MenuItem>
                <MenuItem value={2}>Admin</MenuItem>
                <MenuItem value={3}>Owner</MenuItem>
              </Select>
            </div>
          </div>

          {userRedux?.admin && (
            <div className="text-end mt-3 mb-1">
              <Link
                style={{ textDecoration: "none" }}
                to={{ pathname: "/users/edit/" + `${user.id}` }}
              >
                <MainBtn>{t("UserProfile.Edit Profile")}</MainBtn>
              </Link>
            </div>
          )}
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ProfileData;

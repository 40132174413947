import React, { useMemo, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TableUsers from "../Points/PointsTable";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ShowCertificate from "../Certificates/ShowCertificates";
import CompleteCertificate from "../Certificates/CompleteCertificate";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Certificates = () => {
  const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [template, setTemplate] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [due, setDue] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [builder, setBuilder] = useState("");
  var todayDate = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/certificates/index",
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      const filteredCertificates = json.filter((item) => item.due <= todayDate);
      //console.log(filteredCertificates);
      setData(filteredCertificates);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        // first group
        Header: " ",
        // First group columns
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: <>{t("CertificatesTable.0")}</>,
            accessor: " ",
            Cell: ({ row }) => (
              <>
                {row.original.first_name} {row.original.last_name}{" "}
              </>
            ),
          },
          {
            Header: <>{t("CertificatesTable.1")}</>,
            accessor: "updated_at",
            Cell: ({ row }) => (
              <>
                {" "}
                <Chip
                  size="small"
                  label={row.original.level}
                  style={{ marginBottom: "3px" }}
                />{" "}
                {row.original.first_name} {row.original.last_name}{" "}
                {row.original.created_at.slice(0, 10)} / {row.original.due}{" "}
              </>
            ),
          },
          {
            Header: <>{t("CertificatesTable.2")}</>,
            accessor: "level",
            Cell: ({ row }) => (
              <>
                <Chip
                  size="small"
                  label={row.original.level}
                  style={{ marginBottom: "3px" }}
                />{" "}
                <br />{" "}
              </>
            ),
          },
          {
            Header: <>{t("CertificatesTable.3")}</>,
            accessor: "due",
            Cell: ({ row }) => (
              <span className="fw-bold" style={{ color: "red" }}>
                {row.original.due}
              </span>
            ),
          },
          {
            Header: <>{t("CertificatesTable.4")}</>,
            accessor: "created_at",
            Cell: ({ row }) => <>{row.original.created_at.slice(0, 10)}</>,
          },
          {
            Header: <>{t("CertificatesTable.8")}</>,
            accessor: "Info",
            Cell: ({ row }) => (
              <span
                className="simple-link"
                style={{ cursor: "pointer" }}
                onClick={() => handleShow(row.original.id)}
              >
                <VisibilityIcon className="table-icons" />
              </span>
            ),
          },
          {
            Header: <>{t("CertificatesTable.5")}</>,
            accessor: "level_id",
            Cell: ({ row }) => (
              <span
                className="simple-link"
                style={{ cursor: "pointer" }}
                onClick={() => handlePDF(row.original)}
              >
                <SimCardDownloadIcon className="table-icons" />
              </span>
            ),
          },
          {
            Header: <>{t("CertificatesTable.6")}</>,
            accessor: "name",
            Cell: ({ row }) => (
              <>
                <span
                  className="simple-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDestroy(row.original)}
                >
                  <DeleteOutlineIcon className="table-icons" />
                </span>
              </>
            ),
          },
        ],
      },
    ],
    [currentLanguage]
  );

  const handleShow = (user) => {
    setShow(true);
    setCurrent(user);
  };

  const handleDestroy = async (item) => {
    setLoading(true);
    console.log(item.id, item.archived);
    fetch(process.env.REACT_APP_API_URL + "/certificates/destroy/" + item.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    }).then((response) => {
      if (response.ok) {
        console.log(response);
        fetchData();
      } else {
        console.log("response was not ok");
      }
    });
  };

  const handlePDF = async (item) => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL +
        "/certificates/generate_certificate/" +
        item.id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    ).then((response) => {
      if (response.ok) {
        //console.log(response);
        setLoading(false);
        window.location.href = response.url;
      } else {
        console.log("response was not ok");
        alert("something went wrong, try again...");
        window.location.reload();
      }
    });
  };

  const handleTest = async (item) => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/builders/show/" + item.name,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      setTemplate(json);
      //console.log('MAIN =>',item)
      setCurrent(item.id);
      setFirstName(item.first_name);
      setLastName(item.last_name);
      setDue(item.due);
      let created = item.created_at;
      setCreatedAt(created.slice(0, 10));
      setBuilder(item.name);
      setShow1(true);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className="title">{t("Home.Members with expired certifications")}</h2>

      <TableUsers isCertificateTable columns={columns} data={data} />

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <ShowCertificate current={current} />
        </Box>
      </Modal>

      <Modal
        open={show1}
        onClose={() => setShow1(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "scroll" }}
      >
        <Box sx={style}>
          <CompleteCertificate
            current={current}
            firstName={firstName}
            lastName={lastName}
            template={template}
            createdAt={createdAt}
            due={due}
          />
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Certificates;

import { RichTextEditor } from '@mantine/rte';

function RichText({content_four, setContentFour}) {
  
  return <RichTextEditor 
    value={content_four} 
    onChange={setContentFour}
    controls={[['bold', 'italic', 'underline'],['alignLeft', 'alignCenter', 'alignRight'],  ]}
    />;
}

export default RichText;
import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Sidebar from '../Sidebar'
import MainBtn from '../UI-kit/Buttons/MainBtn';
import MainBtnCancel from '../UI-kit/Buttons/MainBtnCancel';
import PeopleAltIcon from '../assets/icons/zertiassist_iconos_user_24px.svg';
import LocationIcon from '../assets/icons/zertiassist_iconos_map_pin_24px.svg';
import SecurityIcon from '../assets/icons/zertiassist_iconos_security_24px.svg';
import PencilIcon from '../assets/icons/zertiassist_iconos_edit_24px.svg';
import Avatar from '../assets/images/users_image.jpg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Alert from '../../src/UI-kit/Alert/Alert';

const Register = () => {

  const { t, i18n } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [source, setSource] = useState('');
  const [mode, setMode] = useState('');
  const [wordpress_user_id, setWPID] = useState('');
  const [ev_id, setEVID] = useState('');
  const [birth_date, setBirth] = useState('1990-12-30');
  const [url_profile_wordpress, setWP] = useState('');
  const [url_profile_easyverein, setEV] = useState('');
  const [street_address, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [post_code, setPost] = useState('');
  const [country, setCountry] = useState('');

  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {

    e.preventDefault();

    setLoading(true)

    const newUser = { 
      email,
      password,
      password_confirmation,
      first_name,
      last_name,
      source: 'BVTQ APP',
      mode,
      birth_date,
      url_profile_wordpress: "",
      url_profile_easyverein: "",
      street_address,
      city,
      state,
      post_code,
      country,
      //FIXME: Remove when solved on backend
      page: 0,
      synced: true,
      ev_id: 0,
      wordpress_user_id: 0
    };

    if(password !== password_confirmation){
      alert("Passwords doesn't match")
      setLoading(false)

    }else{

      const response = await fetch(process.env.REACT_APP_API_URL + '/sessions/register_member', {
      method: 'POST',
      headers: { 
        "Content-Type": "application/json", 
        "api-key": process.env.REACT_APP_API_KEY 
      },
      body: JSON.stringify(newUser)
      });
      const data = await response.json();
  
      if(data.code == '200'){
        setSuccessful(true);
        window.location.href='/users';
        setLoading(false)
  
          
      } else if (data.code == '400') {
  
        //alert('"The email address or password you entered is invalid"')
        setError(true)
        setLoading(false)

      } else {
        //alert('"The email address or password you entered is invalid"')
        setError(true)
        setLoading(false)

      }

    };
  
  }  
  console.log(birth_date)

  return (
  <>
    <Sidebar />
    <form onSubmit={handleSubmit} className='frame' >

      <br />

      <h2 className='title'>{t('UsersTable.9')}</h2>

      <div className='row h-100 px-2'>

        <div className='col-4 white-box' style={{backgroundImage: 'url(' + Avatar + ')', objectFit: 'cover', backgroundRepeat: 'no-repeat', height: '736px', backgroundSize: 'contain' }}>

          <div className='px-3' style={{marginTop: '60%'}}>
            
            <p className='subtitle'><img className='sidebar-icons' src={PeopleAltIcon} /> 1. {t('UserProfile.Personal Information')}</p> 

            <TextField
              required
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              style={{width: '100%'}}
              label={'First Name'}
            />

            <br />
            <br />

            <TextField
              required
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              style={{width: '100%'}}
              label={'Last Name'}
            />

            <br />
            <br />

            <TextField
              type='date'
              required
              value={birth_date}
              onChange={(e) => setBirth(e.target.value)}
              style={{width: '100%'}}
              label={'Birth'}

            />

            <br />
            <br />

            <InputLabel className='mt-1' id="demo-simple-select-label">{t('UserProfile.Type of User')}</InputLabel>
            <br />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => setMode(e.target.value)} 
              value={mode}
              style={{width: '100%'}}
              label=" "
              >
              <MenuItem value={''}>Choose</MenuItem>
              <MenuItem value={'person'}>Person</MenuItem>
              <MenuItem value={'organization'}>Organization</MenuItem>
            </Select>

          </div>
        </div>


        <div className='col-8 ps-4'>
            
          <div className='row white-box'>

            <p className='subtitle ps-4'><img className='sidebar-icons' src={LocationIcon} /> 2. {t('UserProfile.Address')}</p> 

            <div className='col px-4'>


              <TextField
                required
                value={street_address}
                onChange={(e) => setStreet(e.target.value)}
                style={{width: '100%'}}
                label={'Street'}
              />


              <br />
              <br />

              <TextField
                required
                value={state}
                onChange={(e) => setState(e.target.value)}
                style={{width: '100%'}}
                label={'State'}
              />

              <br />
              <br />

              <TextField
                required
                value={post_code}
                onChange={(e) => setPost(e.target.value)}
                style={{width: '100%'}}
                label={'Post Code'}

              />

              <br />
              <br />

                  
            </div>

            <div className='col px-3'>

              <TextField
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                style={{width: '100%'}}
                label={'Country'}
              />

              <br />
              <br />

    

              <TextField
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
                style={{width: '100%'}}
                label={'City'}

              />

              <br />
              <br />

            </div>

          </div>


          <div className='row white-box mt-3'>

            <p className='subtitle ps-4'><img className='sidebar-icons' src={SecurityIcon} />3. {t('UserProfile.Security & login')}</p> 

            <div className='col px-4'>


              <TextField 
                required
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}    
                placeholder="name@domain.com" 
                style={{width: '100%'}}
                label={'email'}
              />

              <br />
              <br />

              <TextField 
                required
                type={showPassword ? "text" : "password"} 
                value={password_confirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}  
                placeholder="At least 6 characters" 
                style={{width: '100%'}}
                label={'Password Confirmation'}
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
                  
            </div>

            <div className='col px-3'>

              <TextField
                required
                type={showPassword ? "text" : "password"} 
                value={password}
                onChange={(e) => setPassword(e.target.value)}  
                placeholder="At least 6 characters"
                style={{width: '100%'}}
                label={'password'}
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <br />
              <br />

            </div>

          </div>

{           /*  <div className='row white-box mt-3'>

            <p className='subtitle'><img className='sidebar-icons' src={PencilIcon} />4. Additional Data</p> 

            <div className='col px-4'>


              <TextField 
                disabled
                type="text"
                //value={email}
                //onChange={(e) => setEmail(e.target.value)}    
                placeholder="text" 
                style={{width: '100%'}}
                label={'text'}
              />

              <br />
              <br />
                  
            </div>

            <div className='col px-3'>

              <TextField 
                disabled
                type="text"
                //value={email}
                //onChange={(e) => setEmail(e.target.value)}    
                placeholder="text" 
                style={{width: '100%'}}
                label={'text'}
              />

              <br />
              <br />

            </div>

          </div> */}


            {/* <div className='row'>

              <div className='col-6'>

                <div className='mb-2'>

                  <TextField
                    required
                    type='number'
                    value={wordpress_user_id}
                    onChange={(e) => setWPID(e.target.value)}
                    label={'ID WP'}

                  />

                  <br />
                  <br />

                  <TextField
                    required
                    type='number'
                    value={ev_id}
                    onChange={(e) => setEVID(e.target.value)}
                    label={'ID EV'}
                  />


                </div>
              </div>


              <div className='col-6'>

                <div className='mb-2'>


                  <TextField
                    required
                    value={url_profile_wordpress}
                    placeholder='https://taijiquan-qigong.de/user/...'
                    onChange={(e) => setWP(e.target.value)}
                    label={'WP Link:'}
                  />

                  <br />
                  <br />


                  <TextField
                    required  
                    value={url_profile_easyverein}
                    placeholder='https://easyverein.com/app/profile/...'
                    onChange={(e) => setEV(e.target.value)}
                    label={'EV Link'}

                  />


                </div>

              </div>

            </div> */}
          <div className='text-end mt-3 mb-1'>
            <Link style={{ textDecoration: 'none' }} to='/users'>
              <MainBtnCancel>{t('Buttons.1')}</MainBtnCancel>
            </Link>
            <MainBtn type='submit'>{t('Buttons.2')}</MainBtn>
          </div>
        </div>
      </div>

         

      {successful?      
      <Alert message={'success'} severity={'success'}/> 
      :<></>}
      {error?    
      <Alert message={'error'} severity={'error'}/> 
      :<></>}  
          
    </form>



    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        >
        <CircularProgress color="inherit" />
    </Backdrop>
  </>
  )
}

export default Register
import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Backdrop, Box, CircularProgress, Modal } from "@mui/material";
import Sidebar from "../Sidebar";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import Avatar from "../assets/images/users_image.jpg";
import Table from "react-bootstrap/Table";
import StampForLevel from "./StampForLevel";
import FormIcon from "../assets/icons/zertiassist_iconos_form_24px.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShowTemplate from "../BuilderPDF/ShowTemplate";

const ShowLevel = () => {
  const { user } = useSelector((state) => state.user);

  const { t } = useTranslation();

  const params = useParams();

  const location = useLocation();
  const userID = location.state !== null && location.state.userID;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [levelName, setLevelName] = useState("");
  const [expiry_day, setDay] = useState();
  const [expiry_month, setMonth] = useState();
  const [expiry_year, setYear] = useState();
  const [category_id, setCategoryID] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [templates, setTemplates] = useState([]);
  const [current, setCurrent] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    fetchData();
    fetchData2();
    fetchData3();
  }, [category_id]);

  const fetchData = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/levels/show/" + params.id,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      //console.log(json);
      setLevelName(json.title);
      setDay(json.expiry_day);
      setMonth(json.expiry_month);
      setYear(json.expiry_year);
      setCategoryID(json.category_id);
      setData(JSON.parse(json.subcategories));
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const fetchData2 = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/categories/show/" + category_id,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      //console.log(json);
      setCategoryName(json.name);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const fetchData3 = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL +
        "/levels/get_template_for_level/" +
        params.id,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      console.log(json);
      setTemplates(json);
      setLoading(false);
    } else {
      console.log("response was not ok");
      //throw new Error("response was not ok.");
      setLoading(false);
    }
  };

  const handleShow = (user) => {
    setShow(true);
    setCurrent(user);
  };

  return (
    <>
      <Sidebar />
      <div className="frame">
        <br />
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title mb-0">{levelName}</h2>
          <div className="text-end">
            <Link
              to={userID ? `/users/profile/${userID}` : "/levels"}
              style={{ textDecoration: "none" }}
            >
              <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
            </Link>
          </div>
        </div>

        <hr />

        <div className="row px-2">
          <div
            className="col-6 col-lg-4 white-box"
            style={{
              backgroundImage: "url(" + Avatar + ")",
              objectFit: "cover",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            <div className="px-5" style={{ marginTop: "60%" }}>
              <p className="subtitle fw-bold">
                <StarBorderIcon
                  className="table-icons"
                  style={{ verticalAlign: "bottom" }}
                />{" "}
                {t("ShowLevel.Validate for")}{" "}
                <span className="fw-normal">
                  {" "}
                  {expiry_day} {expiry_month} {expiry_year}
                </span>
              </p>
              <p className="subtitle fw-bold">
                <img
                  className="sidebar-icons"
                  src={FormIcon}
                  style={{ verticalAlign: "bottom" }}
                />{" "}
                {t("ShowLevel.Category name")}
                <span className="fw-normal"> {categoryName}</span>
              </p>

              <StampForLevel current={params.id} />
            </div>

            {user?.admin &&
              (templates.length ? (
                <div className="px-5">
                  <p className="subtitles fw-bold">{t("BuilderTable.4")}:</p>

                  <Table responsive borderless className="main-table">
                    <thead>
                      <tr>
                        <td className="subtitles fw-bold">
                          {t("ShowLevel.Name")}
                        </td>
                        <td className="subtitles fw-bold">
                          {t("ShowLevel.Info")}
                        </td>
                        <td className="subtitles fw-bold">
                          {t("ShowLevel.Edit")}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {templates.map((item, i) => (
                        <tr key={i}>
                          <td>{item.title}</td>
                          <td>
                            <span
                              className="simple-link"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleShow(item.id)}
                            >
                              <VisibilityIcon className="table-icons" />
                            </span>
                          </td>
                          <td>
                            <Link
                              className="simple-link"
                              style={{ cursor: "pointer" }}
                              to={{ pathname: `/templates/edit/${item.id}` }}
                              title="edit"
                            >
                              <BorderColorOutlinedIcon className="table-icons" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <p className="subtitles text-center">
                  {t("ShowLevel.No templates found")}
                </p>
              ))}
          </div>

          <div className="col-6 col-lg-8">
            <Table responsive borderless className="main-table">
              <thead>
                <tr>
                  <td className="subtitles fw-bold">{t("ShowLevel.Name")}</td>
                  <td className="subtitles fw-bold">{t("UserPoints.1")}</td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.belongs != 0 ? (
                        <span
                          style={{
                            paddingLeft: String(item.nesting * 10) + "px",
                          }}
                        >
                          {item.name}
                        </span>
                      ) : (
                        <span>{item.name}</span>
                      )}
                    </td>
                    <td>{item.points}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <ShowTemplate current={current} />
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ShowLevel;

import React from 'react'
import { useTranslation } from 'react-i18next';
import Sidebar from './Sidebar'

const Home = () => {

    const { t, i18n } = useTranslation();

    return (
    <>
        <Sidebar />
        <div className='frame'>
            <br />
            <div className='text-center'>
                <h1>404 Not Found</h1>
            </div>
        </div>
    </>
    )
}

export default Home

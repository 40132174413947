import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ProSidebar, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import { Typography, Toolbar, Box } from "@mui/material";
import homeIcon from "../src/assets/icons/zertiassist_iconos_home_24px.svg";
import userIcon from "../src/assets/icons/zertiassist_iconos_user_24px.svg";
import categoriesIcon from "../src/assets/icons/zertiassist_iconos_categories_24px.svg";
import levelIcon from "../src/assets/icons/zertiassist_iconos_levels_24px.svg";
import pointIcon from "../src/assets/icons/zertiassist_iconos_points_24px.svg";
import certificateIcon from "../src/assets/icons/zertiassist_iconos_certificates_24px.svg";
import templateIcon from "../src/assets/icons/zertiassist_iconos_templates_24px.svg";
import logo from "./assets/Logo_BVTQ_1200.png";
import "./assets/stylesheets/sidebar.css";
import "react-pro-sidebar/dist/css/styles.css";

const Sidebar = () => {
  // Configuration User State with Redux
  const { user } = useSelector((state) => state.user);
  //translations
  const { t } = useTranslation();
  //utils
  const [menuCollapse, setMenuCollapse] = useState(true);

  const handleCollapsedMenu = () => {
    setMenuCollapse(!menuCollapse);
  };

  const handleSelectedItem = () => {
    if (!menuCollapse) {
      setMenuCollapse(!menuCollapse);
    }
  };

  return (
    <div id="header">
      <ProSidebar collapsed={menuCollapse}>
        <SidebarHeader>
          <div className="logo-container" onClick={handleCollapsedMenu}>
            <Typography textAlign="center">
              <img
                style={{
                  height: menuCollapse ? "40px" : "20px",
                  marginRight: !menuCollapse && "15px",
                }}
                src={logo}
                alt="logo"
              />
              {!menuCollapse && "BVTQ"}
            </Typography>
          </div>
        </SidebarHeader>
        <SidebarContent>
          {!user?.member && (
            <NavLink
              to={"/home"}
              onClick={() => handleSelectedItem()}
              className={(navData) =>
                navData.isActive
                  ? "custom-nav-links-active"
                  : "custom-nav-links"
              }
            >
              <Box className="link-container">
                <img src={homeIcon} alt="logo" className="sidebar-icons" />
                &nbsp;&nbsp;
                {!menuCollapse && <Typography>{t("Homepage.0")}</Typography>}
              </Box>
            </NavLink>
          )}

          <NavLink
            to={user?.admin ? "/users" : `/users/profile/${user?.user_id}`}
            className={(navData) =>
              navData.isActive ? "custom-nav-links-active" : "custom-nav-links"
            }
          >
            <Box className="link-container">
              <img src={userIcon} alt="logo" className="sidebar-icons" />
              &nbsp;&nbsp;
              {!menuCollapse && <Typography>{t("Homepage.5")}</Typography>}
            </Box>
          </NavLink>

          {user?.admin && (
            <NavLink
              to={"/categories"}
              className={(navData) =>
                navData.isActive
                  ? "custom-nav-links-active"
                  : "custom-nav-links"
              }
            >
              <Box className="link-container">
                <img
                  src={categoriesIcon}
                  alt="logo"
                  className="sidebar-icons"
                />
                &nbsp;&nbsp;
                {!menuCollapse && <Typography>{t("Homepage.6")}</Typography>}
              </Box>
            </NavLink>
          )}

          {user?.admin && (
            <NavLink
              to={"/levels"}
              className={(navData) =>
                navData.isActive
                  ? "custom-nav-links-active"
                  : "custom-nav-links"
              }
            >
              <Box className="link-container">
                <img src={levelIcon} alt="logo" className="sidebar-icons" />
                &nbsp;&nbsp;
                {!menuCollapse && <Typography>{t("Homepage.7")}</Typography>}
              </Box>
            </NavLink>
          )}

          {user?.admin && (
            <NavLink
              to={"/points"}
              className={(navData) =>
                navData.isActive
                  ? "custom-nav-links-active"
                  : "custom-nav-links"
              }
            >
              <Box className="link-container">
                <img src={pointIcon} alt="logo" className="sidebar-icons" />
                &nbsp;&nbsp;
                {!menuCollapse && <Typography>{t("Homepage.8")}</Typography>}
              </Box>
            </NavLink>
          )}

          {user?.admin && (
            <>
              {!menuCollapse && (
                <Toolbar
                  style={{
                    minHeight: "20px",
                    paddingTop: "10px",
                    paddingLeft: "15px",
                  }}
                >
                  <Typography
                    textAlign="center"
                    style={{ color: "#50597B", opacity: "0.4" }}
                  >
                    {t("Homepage.9")}
                  </Typography>
                </Toolbar>
              )}

              <NavLink
                to={"/certificates"}
                className={(navData) =>
                  navData.isActive
                    ? "custom-nav-links-active"
                    : "custom-nav-links"
                }
              >
                <Box className="link-container">
                  <img
                    src={certificateIcon}
                    alt="logo"
                    className="sidebar-icons"
                  />
                  &nbsp;&nbsp;
                  {!menuCollapse && <Typography>{t("Homepage.9")}</Typography>}
                </Box>
              </NavLink>
            </>
          )}

          {user?.admin && (
            <>
              {!menuCollapse && (
                <Toolbar
                  style={{
                    minHeight: "20px",
                    paddingTop: "10px",
                    paddingLeft: "15px",
                  }}
                >
                  <Typography
                    textAlign="center"
                    style={{ color: "#50597B", opacity: "0.4" }}
                  >
                    {t("Homepage.10")}
                  </Typography>
                </Toolbar>
              )}

              <NavLink
                to={"/templates"}
                className={(navData) =>
                  navData.isActive
                    ? "custom-nav-links-active"
                    : "custom-nav-links"
                }
              >
                <Box className="link-container">
                  <img
                    src={templateIcon}
                    alt="logo"
                    className="sidebar-icons"
                  />
                  &nbsp;&nbsp;
                  {!menuCollapse && <Typography>{t("Homepage.10")}</Typography>}
                </Box>
              </NavLink>
            </>
          )}
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DropFileZone from "../Points/components/DropFileZone";
import DropFileZoneHeaderIamge from "../Points/components/DropFileZoneHeaderImage";
import MainBtn from "../UI-kit/Buttons/MainBtn";
import MainBtnCancel from "../UI-kit/Buttons/MainBtnCancel";
import Alert from "../UI-kit/Alert/Alert";
import Sidebar from "../Sidebar";
import ShowTemplate from "./ShowTemplate";
import fetchApi from "../hooks/fetchApi";
import axios from "axios";

const CloneTemplate = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  const [typeTemplate, setTypeTemplate] = useState("");
  const [titleTemplate, setTitleTemplate] = useState("");
  const [templatePreview, setTemplatePreview] = useState("");
  const [levels, setLevels] = useState([]);
  const [dataLevel, setDataLevel] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [alternativeText, setAlternativeText] = useState("");
  const [levelSubtitle, setLevelSubtitle] = useState("");
  const [levelDescription, setLevelDescription] = useState("");
  const [textColor, setTextColor] = useState("");
  const [color_description, setColorDescription] = useState("");
  const [color_alt_text, setColorAltText] = useState("");
  const [color_lvl_name, setColorLvlName] = useState("");
  const [color_lvl_subtitle, setColorLvlSubtitle] = useState("");
  const [color_alt_lvl_description, setColorLvlDescription] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundImageResponse, setBackgroundImageResponse] = useState("");
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [headerImage, setHeaderImage] = useState("");
  const [headerImageResponse, setHeaderImageResponse] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [createdDate, setCreatedDate] = useState("");

  const formatImage = (image) => {
    let url = image.replace(
      image.split("rails")[0],
      process.env.REACT_APP_DOWNLOAD_URL
    );
    return url;
  };

  const getTemplateData = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        headers: { "api-key": process.env.REACT_APP_API_KEY },
      };
      const res = await fetch(
        process.env.REACT_APP_API_URL + "/builders/show/" + params.id,
        requestOptions
      );
      if (res.ok) {
        const json = await res.json();
        console.log("template data", json);

        setTypeTemplate(json.content);
        setTitleTemplate(`${json.title} copy`);
        setTitle(json.subtitle);
        setDescription(json.image);
        setAlternativeText(json.content_two);
        setLevelSubtitle(json.content_four);
        setLevelDescription(json.content_three);
        setTextColor(json.text_color);
        setColorDescription(json.color_description);
        setColorAltText(json.color_alt_text);
        setColorLvlName(json.color_lvl_name);
        setColorLvlSubtitle(json.color_lvl_subtitle);
        setColorLvlDescription(json.color_alt_lvl_description);
        setBackgroundColor(json.color.toString());
        setCreatedDate(json.created_at);
        getLevels(json.level_id);
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getLevels = async (id) => {
    try {
      setLoading(true);
      const { ok, data } = await fetchApi("/levels/index");
      if (ok) {
        setLevels(data);
        getLevelData(id, data);
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getLevelData = (id, data) => {
    const currentLevel = data.find((e) => e.id === id);
    setDataLevel(currentLevel);
  };

  const getHeaderImage = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        headers: { "api-key": process.env.REACT_APP_API_KEY },
      };
      const res = await fetch(
        process.env.REACT_APP_API_URL +
          "/builders/get_header_image/" +
          params.id,
        requestOptions
      );
      if (res.ok) {
        const json = await res.json();
        setHeaderImageResponse(json.url);
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAttachments = async () => {
    try {
      setLoading(true);
      var requestOptions = {
        headers: { "api-key": process.env.REACT_APP_API_KEY },
      };
      const res = await fetch(
        process.env.REACT_APP_API_URL +
          "/builders/get_attachments/" +
          params.id,
        requestOptions
      );
      if (res.ok) {
        const json = await res.json();
        setBackgroundImageResponse(json.url);
      } else {
        console.log("response was not ok");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      level_id: dataLevel.id,
      title: titleTemplate,
      subtitle: title,
      image: description,
      content_two: alternativeText,
      content_four: levelSubtitle,
      content_three: levelDescription,
      text_color: textColor,
      color_description,
      color_alt_text,
      color_lvl_name,
      color_lvl_subtitle,
      color_alt_lvl_description,
      color: backgroundColor,
      content: typeTemplate,
      header_images: headerImage,
      documents: backgroundImage,
    };
    console.log(body);
    const formData = new FormData();
    for (const property in body) {
      formData.append(property, body[property]);
    }
    try {
      const resp = await axios.post(
        process.env.REACT_APP_API_URL + "/builders/create",
        formData,
        {
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      console.log(resp.data);
      setSuccessful(true);
      setTimeout(() => {
        navigate("/templates");
      }, 1000);
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = () => {
    setTemplatePreview({
      dataLevel,
      typeTemplate,
      title,
      description,
      headerImage: headerImageUrl
        ? headerImageUrl
        : formatImage(headerImageResponse),
      alternativeText,
      levelSubtitle,
      levelDescription,
      textColor,
      color_description,
      color_alt_text,
      color_lvl_name,
      color_lvl_subtitle,
      color_alt_lvl_description,
      backgroundImage: backgroundImageUrl
        ? backgroundImageUrl
        : formatImage(backgroundImageResponse),
      backgroundColor,
      createdDate,
    });
    setShow(true);
  };

  useEffect(() => {
    getTemplateData();
    getLevels();
    getHeaderImage();
    getAttachments();
  }, []);

  return (
    <>
      <Sidebar />
      <form onSubmit={handleSubmit} className="frame">
        <br />

        <div className="d-flex justify-content-between align-items-center">
          <h2 className="title">
            {`${t("EditTemplate.0")} ${t("EditTemplate.1")}:`}
          </h2>
          <div className="text-end">
            <Link style={{ textDecoration: "none" }} to="/templates">
              <MainBtnCancel>{t("Buttons.1")}</MainBtnCancel>
            </Link>
            <MainBtn disabled={title === ""} type="submit">
              {t("Buttons.2")}
            </MainBtn>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-7">
            <div className="white-box">
              <p className="subtitle fw-bold">{t("EditTemplate.1")}</p>

              <p className="subtitle fw-bold">{t("EditTemplate.2")}</p>

              <Autocomplete
                required
                id="multiple-limit-tags"
                value={dataLevel}
                options={levels}
                filterSelectedOptions
                getOptionLabel={(option) => option.title || ""}
                onChange={(e, value) => {
                  var fieldValue = value;
                  if (!fieldValue) {
                    fieldValue = null;
                  } else setDataLevel(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t("EditTemplate.17")} />
                )}
              />

              <br />

              <p className="subtitle fw-bold">{t("EditTemplate.3")}</p>

              <TextField
                required
                value={titleTemplate}
                onChange={(e) => setTitleTemplate(e.target.value)}
                type="text"
                label={t("EditTemplate.3")}
                style={{ width: "100%" }}
              />
            </div>

            <div className="white-box mt-2">
              <p className="subtitle fw-bold">{t("EditTemplate.4")}</p>

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">1. {t("EditTemplate.5")}</p>
                <div className="d-flex">
                  <input
                    required
                    value={textColor}
                    onChange={(e) => setTextColor(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                type="text"
                label={t("EditTemplate.5")}
                style={{ width: "100%" }}
              />

              <br />
              <br />

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">2. {t("EditTemplate.6")}</p>
                <div className="d-flex">
                  <input
                    required
                    value={color_description}
                    onChange={(e) => setColorDescription(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                label={t("EditTemplate.6")}
                style={{ width: "100%" }}
              />

              <br />
              <br />

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">3. {t("EditTemplate.19")}</p>
                <div className="d-flex">
                  <input
                    required
                    value={color_alt_text}
                    onChange={(e) => setColorAltText(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                value={alternativeText}
                onChange={(e) => setAlternativeText(e.target.value)}
                type="text"
                label={t("EditTemplate.19")}
                style={{ width: "100%" }}
              />

              <br />
              <br />

              <div className="d-flex justify-content-between">
                <div>
                  <p className="subtitle fw-bold mb-1">
                    {t("EditTemplate.12")}
                  </p>
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Automatically generated")})`}
                  </p>
                </div>
                <div className="d-flex">
                  <input
                    required
                    value={color_lvl_name}
                    onChange={(e) => setColorLvlName(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <br />

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">4. {t("EditTemplate.21")}</p>
                <div className="d-flex">
                  <input
                    required
                    value={color_lvl_subtitle}
                    onChange={(e) => setColorLvlSubtitle(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                value={levelSubtitle}
                onChange={(e) => setLevelSubtitle(e.target.value)}
                type="text"
                label={t("EditTemplate.21")}
                style={{ width: "100%" }}
              />

              <br />
              <br />

              <div className="d-flex justify-content-between">
                <p className="subtitle fw-bold">5. {t("EditTemplate.20")}</p>
                <div className="d-flex">
                  <input
                    required
                    value={color_alt_lvl_description}
                    onChange={(e) => setColorLvlDescription(e.target.value)}
                    type="color"
                    style={{ width: "30px", marginRight: "8px" }}
                  />
                  <p className="subtitle">
                    {`(${t("TemplatesOptions.Color for text")})`}
                  </p>
                </div>
              </div>

              <TextField
                required
                value={levelDescription}
                onChange={(e) => setLevelDescription(e.target.value)}
                type="text"
                label={t("EditTemplate.20")}
                style={{ width: "100%" }}
              />
            </div>

            <div className="white-box mt-2">
              <div className={`p-2 ${typeTemplate !== "option 2" && "w-50"}`}>
                <p className="subtitle fw-bold">
                  {typeTemplate !== "option 1" && "Logo & "}
                  {t("EditTemplate.7")}
                </p>

                {typeTemplate === "option 3" && (
                  <>
                    <p className="subtitle fw-bold">Color</p>
                    <input
                      value={backgroundColor}
                      onChange={(e) => setBackgroundColor(e.target.value)}
                      type="color"
                      style={{ width: "100%" }}
                    />
                  </>
                )}

                {typeTemplate !== "option 1" && (
                  <div className="row">
                    <div className={typeTemplate === "option 2" ? "col-6" : ""}>
                      <p className="subtitle fw-bold mt-2">
                        Logo {t("EditTemplate.8")}
                      </p>
                      <div
                        className="wrapper"
                        style={{
                          height: "auto",
                        }}
                      >
                        <img
                          src={
                            headerImageUrl
                              ? headerImageUrl
                              : formatImage(headerImageResponse)
                          }
                          alt="headerImage"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>

                    <div className={typeTemplate === "option 2" ? "col-6" : ""}>
                      <p className="subtitle fw-bold mt-2">
                        Logo {t("EditTemplate.9")}
                      </p>
                      <div
                        className="wrapper"
                        style={{
                          height: "auto",
                        }}
                      >
                        <DropFileZoneHeaderIamge
                          image={formatImage(headerImageResponse)}
                          setHeaderImages={setHeaderImage}
                          setImageUrl={setHeaderImageUrl}
                        />
                        <p className="standard-text mt-3">
                          {t("AddPoints.2")}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {typeTemplate !== "option 3" && (
                  <div className="row">
                    <div className={typeTemplate === "option 2" ? "col-6" : ""}>
                      <p className="subtitle fw-bold mt-2">
                        Fondo {t("EditTemplate.8")}
                      </p>
                      <div
                        className="wrapper"
                        style={{
                          height: "auto",
                        }}
                      >
                        <img
                          src={
                            backgroundImageUrl
                              ? backgroundImageUrl
                              : formatImage(backgroundImageResponse)
                          }
                          alt="backgroundImage"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>

                    <div className={typeTemplate === "option 2" ? "col-6" : ""}>
                      <p className="subtitle fw-bold mt-2">
                        Fondo {t("EditTemplate.9")}
                      </p>
                      <div
                        className="wrapper"
                        style={{
                          height: "auto",
                        }}
                      >
                        <DropFileZone
                          image={formatImage(backgroundImageResponse)}
                          setDocuments={setBackgroundImage}
                          setImageUrl={setBackgroundImageUrl}
                        />
                        <p className="standard-text mt-3">
                          {t("AddPoints.2")}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-5 white-box" style={{ height: "fit-content" }}>
            <div className="d-flex align-items-center">
              <h4 className="subtitle fw-bold">{t("EditTemplate.10")}</h4>
              <span
                className="simple-link"
                style={{ cursor: "pointer", marginLeft: "20px" }}
                onClick={() => handleShow()}
              >
                <VisibilityIcon className="table-icons" />
              </span>
            </div>

            <div className="d-flex justify-content-center flex-column align-items-center text-center template-preview-box">
              <div className="template-preview-inner-box mt-4 w-75">
                <p className="mt-2">1. {t("EditTemplate.5")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">2. {t("EditTemplate.6")}</p>
              </div>

              {typeTemplate !== "option 1" && (
                <div className="template-preview-inner-box mt-2 w-75">
                  <p className="mt-2">Logo</p>
                </div>
              )}

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("EditTemplate.11")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">3. {t("EditTemplate.19")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("EditTemplate.12")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">4. {t("EditTemplate.21")}</p>
              </div>

              <div className="template-preview-inner-box mt-2 w-75">
                <p className="mt-2">{t("EditTemplate.13")}</p>
              </div>

              <div className="d-flex  justify-content-between w-60">
                <div className="template-preview-inner-box mt-3 mb-4 me-1">
                  <p className="m-2">{t("EditTemplate.15")}</p>
                </div>
                <div className="template-preview-inner-box mt-3 mb-4 ms-1">
                  <p className="m-2">{t("EditTemplate.16")}</p>
                </div>
              </div>
            </div>
            {/* SECOND PAGE */}
            <hr />
            <div className="d-flex justify-content-center flex-column align-items-center text-center template-preview-box">
              <div className="template-preview-inner-box mt-4 w-75">
                <p className="mt-2">5. {t("EditTemplate.20")}</p>
              </div>

              <div className="template-preview-inner-box mt-4 w-75">
                <p className="mt-2">{t("EditTemplate.14")}</p>
              </div>

              <div className="template-preview-inner-box mt-3 mb-4 w-50">
                <p className="mt-2">{t("EditTemplate.18")}</p>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <ShowTemplate current={params.id} templateData={templatePreview} />
        </Box>
      </Modal>

      {successful && (
        <Alert
          message={t("Messages.The operation was successful")}
          severity={"success"}
        />
      )}
      {error && (
        <Alert
          message={t("Messages.Something went wrong, please try again")}
          severity={"error"}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || !dataLevel}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CloneTemplate;

import { Info } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";

const StampForLevel = ({ current }) => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [current]);

  const fetchData = async () => {
    var requestOptions = {
      headers: { "api-key": process.env.REACT_APP_API_KEY },
    };

    const res = await fetch(
      process.env.REACT_APP_API_URL + "/levels/get_attachments/" + current,
      requestOptions
    );
    if (res.ok) {
      const json = await res.json();
      // console.log(json);
      setData(json);
      setLoading(false);
    } else {
      console.log("response was not ok");
    }
  };
  //console.log(data)
  return (
    <div>
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <div className="row">
            <div className="col">
              <hr />
              <div className="text-left">
                <p className="subtitle mt-3">
                  {t("ShowLevel.Name")}:{" "}
                  <a
                    className="simple-link"
                    href={`${data.url.replace(
                      "http://127.0.0.1:3001/",
                      process.env.REACT_APP_DOWNLOAD_URL
                    )}`}
                    target="_blank"
                    style={{ fontSize: "16px" }}
                  >
                    {data.title.slice(0, 30)}...
                  </a>
                </p>

                <img
                  src={`${data.url.replace(
                    "http://127.0.0.1:3001/",
                    process.env.REACT_APP_DOWNLOAD_URL
                  )}`}
                  style={{ height: "150px", width: "200px" }}
                />
              </div>
              <hr />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StampForLevel;

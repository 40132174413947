import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';

export default function App() {
  return (
    <MDBFooter className='text-center text-lg-left fixed-bottom'>
        <div className='text-center text-white p-3'>
            {new Date().getFullYear()} Copyright &copy;{' '} Accentoris AG | Gebaut durch 
            <a className='text-white simple-link' href='http://www.digitaleszeugs.de' target="_blank" style={{textDecoration: 'none'}}>
                &nbsp;Digitales Zeugs
            </a>
        </div>
    </MDBFooter>
  );
}

